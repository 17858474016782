<div class="tags-select-create-container"
     [class.no-selected-options]="!selectedTags.length">

  <ssi-dropdown-select-2 #tagSelector
                         name="tags"
                         [size]="ddSize"
                         [required]="true"
                         [multiple]="true"
                         [filterable]="true"
                         [placeholdersKeyword]="'tags'"
                         [filterPlaceholder]="'Search / create new ' + tagType + ' tags...'"
                         [toggleAllEnabled]="false"
                         [options]="tags"
                         [(ngModel)]="selectedTags"
                         (ngModelChange)="selectedTagsChanged($event)"
                         (optionsFiltered)="onOptionsFiltered($event)"
                         (toggled)="onDropdownToggled($event)">

    <ng-template ssiTemplateSelector="headButtonLabel"
                 let-toggle="toggle"
                 let-expanded="expanded">
      <div class="select-placeholder"
           *ngIf="!selectedTags.length">
        <span>{{placeholder ? placeholder : 'Select ' + tagType + ' tags...'}}</span>
      </div>
      <div class="selected-options-container">
        <div #chipsContainer
             class="chips-container"
             [style.width]="chipsContainerWidth + 'px'"
             *ngIf="selectedTags.length">
          <div class="chip"
               *ngFor="let tag of selectedTags | slice:0:maxVisibleChipsCount;">
            <span>{{tag.name}}</span>
            <i class="ssi ssi-delete-close"
               (click)="$event.stopPropagation(); deselectTag(tag);"></i>
          </div>
        </div>
        <button *ngIf="selectedTags.length > maxVisibleChipsCount"
                type="button"
                class="g-btn text-only-button"
                (click)="$event.stopPropagation(); toggle();">{{selectedTags.length - maxVisibleChipsCount}} more</button>
      </div>
    </ng-template>

    <ng-template ssiTemplateSelector="emptyState"
                 let-toggle="toggle">
      <div class="empty-state"
           *ngIf="!tagSelector.filterTerm">
        <i class="ssi ssi-tags"></i>
        <p class="title">No tags yet!</p>
        <p class="desc">Just start typing then create your new tag</p>
      </div>
    </ng-template>

    <ng-template ssiTemplateSelector="bodyFooter"
                 let-toggle="toggle">
      <div class="empty-state"
           *ngIf="tagSelector.filterTerm && createTagButtonVisible && canCreateTags">
        <button type="button"
                class="g-btn"
                *ngIf="tagSelector.filterTerm"
                (click)="createTag(tagSelector.filterTerm)"><span>Create new {{tagType}} tag "{{tagSelector.filterTerm}}"</span><i class="ssi ssi-addsmall"></i></button>
      </div>
    </ng-template>

  </ssi-dropdown-select-2>
</div>