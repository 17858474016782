<div
  *ngIf="!hidden"
  class="message-box"
  [ngClass]="{
    'message-box-self-penned': selfPenned,
    'message-box-public': !isPrivate,
    'message-box-not-inbox': !isInbox,
    'message-box-right': !isLeft,
    'message-box-is-root': isRoot,
    'message-box-is-actionable': isActionable,
    'message-box-tier-1': tier === 1,
    'message-box-tier-2': tier >= 2,
    'split': showSplitPanel,
    'message-box-validation': isConvertedToActivity
  }">

  <ssi-message-split-panel
    [hidden]="!showSplitPanel"
    (cancel)="onCancel()"
    (ssiOffClick)="onCancel()"
    (splitConversationConfirmed)="onSplitConversation()"
  >
  </ssi-message-split-panel>

  <div class="message-box-avatar-wrap">
    <div
      class="message-box-avatar-container"
      [ngClass]="{
        'message-box-avatar-actionable' : canSplitConversation,
        'message-box-avatar-notes' : notes.amount > 0,
        'message-box-avatar-tier-1': tier === 1,
        'message-box-avatar-tier-2': tier >= 2
      }"
      (click)="toggleSplitPanel($event); avatarClicked.emit()"
    >
      <img
        *ngIf="!showSplitPanel"
        class="message-box-avatar"
        [ngClass]="{'message-box-avatar-container-self-penned': selfPenned}"
        [src]="avatar"
        [ssiFallbackImage]="selfPenned ? 'avatarOutgoing' : 'avatarIncoming'"
      >
      <div
        *ngIf="canSplitConversation"
        [ssiTooltip]="!showSplitPanel && 'Split conversation here?'"
        [tooltipWindowClass]="'tooltip-chunky'"
        [tooltipAppendToBody]="true"
        class="message-box-avatar-actionable-button"
        [ngClass]="{'message-box-avatar-actionable-button-show': showSplitPanel}"
      >
        <i class="message-box-avatar-actionable-icon ssi ssi-split-messages"></i>
      </div>
    </div>

    <ng-container *ngIf="notes.amount > 0">
      <div
        *ngIf="!notes.isActive"
        class="message-box-avatar-notes-wrap"
        (click)="showNotes.emit()">
        <i class="ssi ssi-message-notes message-box-avatar-notes-icon"></i>
        <span class="message-box-avatar-notes-badge">{{notes.amount}}</span>
      </div>

      <span *ngIf="notes.isActive" class="message-box-avatar-notes-guide"></span>
    </ng-container>
  </div>

  <div class="message-box-container">
    <div class="message-box-message-wrap">
      <ssi-message
        class="message-box-message"
        [ngClass]="{
          'message-box-message-deleted': deleted.deleted_at,
          'message-box-message-hidden': isHidden,
          'message-box-message-active': isActive,
          'message-box-message-selected': showActions
        }"
        [activity]="activity"
        [isInbox]="isInbox"
        [isPrivate]="isPrivate"
        [isActive]="isActive"
        [canReply]="canReply"
        [isRoot]="isRoot"
        [isActionable]="isActionable"
        [selfPenned]="selfPenned"
        [pinned]="pinned"
        [stats]="stats"
        [meta]="meta"
        [showConversationAssignTo]="showConversationAssignTo"
        [mediaOnlyMessage]="mediaOnlyMessage"
        [attachments]="mediaOnlyMessage && attachments"
        [retweetConfig]="retweetConfig"
        [hideCommentConfig]="actionHideCommentConfig"
        [deleted]="deleted"
        [redacted]="redacted"
        [likeConfig]="actionLikeConfig"
        [translatable]="translatable"
        [enableActions]="enableActions"
        [linkPreview]="linkPreview"
        [account]="account"
        [sentiment]="!selfPenned && sentiment"
        [sentimentOptions]="sentimentOptions"
        [activeTags]="activeTags"
        [showActions]="showActions"
        [isConvertedToActivity]="isConvertedToActivity"
        [assignedSuccess]="assignedSuccess"
        (showActionsChange)="showActionsChange($event)"
        [tagsTooltip]="tagsTooltip"
        [showViewNatively]="actionViewNatively"
        [showDelete]="actionDelete"
        [showRedact]="actionRedact"
        [notes]="notes"
        (translate)="translate.emit()"
        [retweetSuccess]="retweetSuccess"
        (retweet)="retweet.emit($event)"
        (quoteRetweet)="quoteRetweet.emit()"
        (untranslate)="untranslate.emit()"
        (pinToggle)="pinToggle.emit($event)"
        (setActive)="messageboxClicked.emit()"
        (viewNativelyClicked)="viewNativelyClicked.emit()"
        (toggleLikeClicked)="toggleLikeClicked.emit()"
        (toggleShowHideCommentClicked)="toggleShowHideCommentClicked.emit($event)"
        (conversationAssignToClicked)="conversationAssignToClicked.emit(); showActions = false"
        (deleteClicked)="deleteClicked.emit()"
        (redactConfirmed)="redactConfirmed.emit()"
        (hideActivity)="hidden = true"
        (click)="!isConvertedToActivity && messageboxClicked.emit()"
      >
        <em
          *ngIf="unsupportedMessage"
          [innerHTML]="'THE_CONTENTS_OF_THE_MESSAGE_IS_UNSUPPORTED_AND_CANNOT_BE_DISPLAYED_BRPLEASE_CHECK_THE_NATIVE_PLATFORM_TO_FULLY_VIEW_THIS_MESSAGE' | translate">
        </em>
        <ng-content *ngIf="!unsupportedMessage"></ng-content>
      </ssi-message>
    </div>

    <ng-container *ngIf="!mediaOnlyMessage && !redacted?.redacted_by">
      <div
        *ngIf="attachments?.image.length || attachments?.document.length || attachments?.video.length" class="message-box-gallery message-box-meta-position"
      >
        <div class="message-box-gallery-items message-box-meta-position-gallery">
          <div
            class="message-box-gallery-items-wrap"
            [ngClass]="{
              'message-box-gallery-items-wrap-active': isActive,
              'message-box-gallery-items-wrap-selected': showActions
            }">
            <ssi-message-attachments
              class="flex"
              [activity]="activity"
              [attachments]="attachments"
            >
            </ssi-message-attachments>
          </div>
        </div>
      </div>
    </ng-container>

    <div
      *ngIf="isPrivate"
      [ngClass]="{'message-box-meta-align-right': !isLeft && (attachments?.image.length || attachments?.document.length || attachments?.video.length)}"
      class="message-box-meta message-box-meta-position">
      <ul class="message-box-meta-items">
        <li class="message-box-meta-item">
          <p>{{meta.created_at | date:'HH:mm'}}</p>
        </li>
        <li class="message-box-meta-item">
          <p>{{meta.created_at | date:'d MMM yyyy'}}</p>
        </li>
        <li class="message-box-meta-item">
          <p [innerHTML]="'BY__NAME_' | translate: {name: meta.author_outbox || meta.author || 'Personal Profile'}"></p>
        </li>
        <li *ngIf="meta.response_time" class="message-box-meta-item">
          <p [innerHTML]="'RESPONDED_IN__TIME_' | translate: {time: meta.response_time}"></p>
        </li>
        <li class="message-box-meta-item">
          <i
            *ngIf="activity?.author?.verified"
            class="verified-badge message-box-meta-item-verified ssi ssi-verified-icon"
          ></i>
          <i [ngClass]="meta.network_icon"></i>
        </li>
      </ul>
    </div>

  </div>
</div>

<ng-template
  #actionsMenu="cdkConnectedOverlay"
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="actionsTriggerOrigin"
  [cdkConnectedOverlayOpen]="showActions"
  [cdkConnectedOverlayFlexibleDimensions]="false"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
  [cdkConnectedOverlayPush]="true"
  (backdropClick)="showActions = false"
>
  <ssi-message-actions-menu
    [activity]="activity"
    [isInbox]="isInbox"
    [selfPenned]="selfPenned"
    [activeItem]="actionsActiveItem"
    [sentiment]="sentiment"
    [allTags]="allTags"
    [activeTags]="activeTags"
    (changeSentiment)="updateSentiment($event); showActions = false"
    (changeActiveTags)="changeTags($event)"
    (addNote)="addNote.emit($event); showActions = false"
    (itemExpanded)="updateActionsMenu($event)"

    (close)="showActions = false"
  >
  </ssi-message-actions-menu>
</ng-template>
