import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { DropdownSelect2Module } from '../../../../common/components/dropdown-select-2/dropdown-select-2.module';
import { SocialNetworkIconModule } from '../../../../common/components/social-network-icon/social-network-icon.module';
import { ListSelect2Module } from '../../../../common/components/list-select2/list-select2.module';
import { TagsSelectCreateModule } from '../../../../common/components/tags-select-create/tags-select-create.module';
import { TooltipModule } from '../../../../common/directives/tooltip/tooltip.module';

import { ClDropdownSelectComponent } from './cl-dropdown-select.component';
import { ClDropdownSelectAComponent } from './cl-dropdown-select-a/cl-dropdown-select-a.component';
import { ClDropdownSelectBComponent } from './cl-dropdown-select-b/cl-dropdown-select-b.component';
import { ClDropdownSelectCComponent } from './cl-dropdown-select-c/cl-dropdown-select-c.component';
import { ClDropdownSelectDComponent } from './cl-dropdown-select-d/cl-dropdown-select-d.component';
import { ClDropdownSelectEComponent } from './cl-dropdown-select-e/cl-dropdown-select-e.component';
import { ClDropdownSelectFComponent } from './cl-dropdown-select-f/cl-dropdown-select-f.component';
import { ClDropdownSelectGComponent } from './cl-dropdown-select-g/cl-dropdown-select-g.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    DropdownSelect2Module,
    SocialNetworkIconModule,
    ListSelect2Module,
    TagsSelectCreateModule,
    TooltipModule
  ],
  exports: [ClDropdownSelectComponent],
  declarations: [
    ClDropdownSelectComponent,
    ClDropdownSelectAComponent,
    ClDropdownSelectBComponent,
    ClDropdownSelectCComponent,
    ClDropdownSelectDComponent,
    ClDropdownSelectEComponent,
    ClDropdownSelectFComponent,
    ClDropdownSelectGComponent
  ]
})
export class ClDropdownSelectModule {}
