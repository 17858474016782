import './alt-text-modal.component.scss';
import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ssi-alt-text-modal',
  templateUrl: './alt-text-modal.component.html'
})
export class AltTextModalComponent {
  charactersRemaining = 1000;
  maxCharacters = 1000;
  altText = '';

  constructor(public activeModal: NgbActiveModal) {}

  updateCharacters(event) {
    this.charactersRemaining = this.maxCharacters - event.length;
  }

  save() {
    this.activeModal.close(this.altText);
  }
}
