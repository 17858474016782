import './message.component.scss';

import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  AfterViewChecked
} from '@angular/core';
import { Team, Colleague } from '../../../services/api';
import {
  Account,
  Activity,
  ProfileModel,
  ShareType
} from '@ui-resources-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AssignMessageComponent } from '../../assign-message/assign-message.component';
import { ProfileHelperService } from '../../../services/profile-helper/profile-helper.service';

@Component({
  selector: 'ssi-message',
  templateUrl: './message.component.html'
})
export class MessageComponent implements OnInit, OnChanges, AfterViewChecked {
  @Input() activity: Activity;
  @Input() isInbox = false;
  @Input() selfPenned?: boolean;
  @Input() isPrivate?: boolean;
  @Input() isActive?: boolean;
  @Input() isRoot?: boolean;
  @Input() isActionable?: boolean;
  @Input() canReply?: boolean;
  @Input() pinned?: boolean;
  @Input() translatable?: boolean;
  @Input() enableActions?: boolean;
  @Input() mediaOnlyMessage?: boolean;
  @Input() showViewNatively: boolean;
  @Input() showActions?: boolean;
  @Input() showDelete: boolean;
  @Input() showRedact: boolean;
  @Input() account?: Account;
  @Input() sentiment?: number | boolean;
  @Input() sentimentOptions?: number;
  @Input() assignedSuccess?: Team | Colleague;
  @Input() tagsTooltip?: number;
  @Input() activeTags?: string[];
  @Input() stats?: any;
  @Input() meta?: any;
  @Input() retweetConfig?: any;
  @Input() likeConfig: any;
  @Input() replyToConfig?: any;
  @Input() hideCommentConfig: any;
  @Input() deleted?: any;
  @Input() redacted?: any;
  @Input() linkPreview?: any;
  @Input() attachments?;
  @Input() retweetSuccess = false;
  @Input() showConversationAssignTo: boolean;
  @Input() isConvertedToActivity = false;
  @Input() notes?: any;

  @Output() translate = new EventEmitter<any>();
  @Output() untranslate = new EventEmitter<any>();
  @Output() pinToggle = new EventEmitter<boolean>();
  @Output() showActionsChange = new EventEmitter<any>();
  @Output() retweet = new EventEmitter();
  @Output() quoteRetweet = new EventEmitter();
  @Output() setActive = new EventEmitter();
  @Output() viewNativelyClicked = new EventEmitter();
  @Output() toggleLikeClicked = new EventEmitter();
  @Output() toggleShowHideCommentClicked = new EventEmitter();
  @Output() conversationAssignToClicked = new EventEmitter();
  @Output() deleteClicked = new EventEmitter();
  @Output() redactConfirmed = new EventEmitter();
  @Output() hideActivity = new EventEmitter<void>();

  hasAttachments = false;
  translated = false;
  showStats = false;
  parsedStats;
  ShareType = ShareType;

  constructor(
    private modal: NgbModal,
    private profileHelper: ProfileHelperService
  ) {}

  ngOnInit() {
    if (this.stats) {
      this.parsedStats = JSON.parse(this.stats);
    }
  }

  ngAfterViewChecked(): void {
    this.hasAttachments =
      this.attachments &&
      (this.attachments.image.length ||
        this.attachments.video.length ||
        this.attachments.document.length);
  }

  ngOnChanges(changes) {
    if (changes.stats && changes.stats.currentValue) {
      this.parsedStats = JSON.parse(changes.stats.currentValue);
    }
  }

  translateText(translate) {
    this.translated = translate;
    translate ? this.translate.emit() : this.untranslate.emit();
  }

  openViewProfileModal() {
    this.profileHelper.viewProfile({
      accountId: this.activity.account_id,
      profileId: this.activity.author.id
    });
  }

  async openAssign() {
    const modal = this.modal.open(AssignMessageComponent, {
      windowClass: 'rounded-corners-40',
      centered: true
    });
    modal.componentInstance.activity = this.activity;
    modal.componentInstance.onAssigned.subscribe(
      (assignedUserOrTeam: Colleague | Team) => {
        this.assignedSuccess = assignedUserOrTeam;
      }
    );
  }
}
