import './media-nav.component.scss';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'ssi-media-nav',
  templateUrl: './media-nav.component.html'
})
export class MediaNavComponent implements OnInit, OnChanges {
  @Input() activeFileIndex: number;
  @Input() files: Array<{ url: string }> = [];
  @Input() fileKey = 'url';
  @Input() useBackgroundImage = false;
  @Input() activeFileIsVideo = false;

  @Output() activeFileIndexChange = new EventEmitter<number>();

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['files'] && changes['files'].currentValue) {
      if (!this.activeFile) {
        this.activeFileIndexChange.emit(Math.max(0, this.files.length - 1));
      }
    }
  }

  get activeFile() {
    return this.files[this.activeFileIndex];
  }

  get activeFileUrl(): string {
    return this.activeFile && this.activeFile[this.fileKey];
  }
}
