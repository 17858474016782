import './checkbox.component.scss';
import {
  Input,
  Component,
  ViewChild,
  Renderer2,
  forwardRef,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  AfterViewInit
} from '@angular/core';
import {
  NgForm,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ControlValueAccessor
} from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'ssi-checkbox',
  templateUrl: './checkbox.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
  ]
})
export class CheckboxComponent
  implements ControlValueAccessor, OnChanges, AfterViewInit {
  @Input() parentNgForm: NgForm;
  @Input() label: string;
  @Input() checked: boolean;
  @Input() workWithValue: any;
  @Input() checkboxText: string;
  @ViewChild('checkboxRef') _checkboxRef;

  @Output() onChecked = new EventEmitter();

  id = uuidv4();

  isDisabled: boolean;
  isChecked: boolean;

  constructor(private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.checked) {
      this.isChecked = changes.checked.currentValue;
    }
  }

  ngAfterViewInit(): void {
    // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    // Add 'implements AfterViewInit' to the class.
    this._checkboxRef.nativeElement.checked = this.checked;
  }

  // registers 'fn' that will be fired after changes are made
  // this is how we emit the changes back to the form
  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  checkboxChange(event) {
    this.onChecked.emit();
    this.isChecked = event.target.checked;
    if (this.workWithValue && this.isChecked) {
      this.propagateChange(String(this.workWithValue));
    } else {
      this.propagateChange(this.isChecked);
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
    this.renderer.setAttribute(
      this._checkboxRef.nativeElement,
      'disabled',
      String(isDisabled)
    );
  }

  writeValue(value: boolean) {
    this.isChecked = value;
    this.renderer.setAttribute(
      this._checkboxRef.nativeElement,
      'value',
      String(value)
    );
  }

  public registerOnTouched() {}

  private propagateChange = (_: any) => {};
}
