<div class="multi-select-chips">
  <div ngbDropdown #pickerDropdown="ngbDropdown" autoClose="outside">
    <button
      ngbDropdownToggle
      class="multi-select-chips-trigger"
    >
      <ng-container *ngIf="!selectedSources?.length">
        <span class="multi-select-chips-trigger-text">Select sources you want to gather messages from</span>
        <span class="multi-select-chips-trigger-icon ssi ssi-arrow-down-new"></span>
      </ng-container>
      <ng-container *ngIf="selectedSources?.length">
        <span class="multi-select-chips-trigger-chip" *ngFor="let source of selectedSources">
          <span class="multi-select-chips-trigger-chip-icon">
            <i [ngClass]="sources[source].icon"></i>
          </span>
          <span class="multi-select-chips-trigger-chip-label">{{sources[source].label}}</span>
          <button (click)="$event.stopPropagation(); toggleSelectedSource(source)">
            <i class="ssi ssi-delete-close"></i>
          </button>
        </span>
        <span class="multi-select-chips-trigger-icon ssi ssi-arrow-down-new"></span>
      </ng-container>
    </button>
    <div ngbDropdownMenu class="multi-select-chips-dropdown">
      <input
        class="multi-select-chips-dropdown-input"
        type="text" placeholder="Search sources..."
        [(ngModel)]="filterTerm"
        (ngModelChange)="onFilterTermChange()"
      >
      <div class="multi-select-chips-dropdown-actions">
        <button
          (click)="toggleSelectAllSource()"
          class="multi-select-chips-dropdown-action"
        >
        <span *ngIf="!this.allSelected">Select All</span>
        <span *ngIf="this.allSelected">Deselect All</span>
      </button>
      </div>
      <ul  class="multi-select-chips-dropdown-list">
        <li class="multi-select-chips-dropdown-list-item" *ngFor="let source of sourcesToRender">
          <button
            class="multi-select-chips-dropdown-list-action"
            [ngClass]="{'multi-select-chips-dropdown-list-action-checked': selectedSources?.includes(source.name)}"
            (click)="toggleSelectedSource(source.name)"
          >
            <span class="multi-select-chips-dropdown-list-action-label">{{source.label}}</span>
            <span
              class="multi-select-chips-dropdown-list-action-state"
            >
              <i *ngIf="selectedSources?.includes(source.name)" class="ssi ssi-tick-small"></i>
            </span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</div>
