import './accordion.component.scss';

import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter
} from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'ssi-accordion',
  templateUrl: './accordion.component.html',
  styles: []
})
export class AccordionComponent implements OnInit {
  @Input() label: string | TemplateRef<any>;
  @Input() isOpen: boolean;
  @Input() isValid: boolean;
  @Input() stayOpen = false;

  accordionId = uuidv4();
  labelHeight = 46;
  bodyHeight: number;

  @Output() onAccordionOpenClose = new EventEmitter<boolean>();

  @ViewChild('accordionBody') accordionBody: ElementRef;

  constructor() {}

  ngOnInit() {
    this.bodyHeight = this.accordionBody.nativeElement.clientHeight;
  }

  toggleOpenClose(isOpen) {
    this.isOpen = isOpen;
    this.onAccordionOpenClose.emit(isOpen);
  }
}
