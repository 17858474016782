<div *ngIf="deleted.deleted_at"
     class="message-box-message-prompt-delete"
     [ngClass]="{'message-box-message-prompt-delete-private': isPrivate}">
  <ul>
    <li>
      <p>
        <span class="message-box-message-prompt-delete-heading">Post Deleted: </span>
        <ng-container *ngIf="deleted.deleted_by">
          <span *ngIf="deleted.deleted_by.id === '14'">Natively {{account && 'via ' + account.accountTypeLabel}}</span>
          <span *ngIf="deleted.deleted_by.id !== '14'">Within Orlo by {{deleted.deleted_by.fullName}}</span>
        </ng-container>
        <ng-container *ngIf="!deleted.deleted_by">
          <span>Externally via Unknown</span>
        </ng-container>
      </p>
    </li>
    <li>
      <p [innerHTML]="deleted.deleted_at | date:'HH:mm'"></p>
    </li>
    <li>
      <p [innerHTML]="deleted.deleted_at | date:'d MMM y'"></p>
    </li>
  </ul>
</div>

<div class="message-box-message-header">
  <div *ngIf="meta && !isPrivate"
       class="message-box-message-header-meta-wrap">
    <ul class="message-box-message-header-meta">
      <li *ngIf="meta.author"
          class="message-box-message-header-meta-title-wrap"
          (click)="openViewProfileModal()">
        <p [ngClass]="{'message-box-message-header-meta-title-big': isRoot}"
           class="message-box-message-header-meta-title">
          {{meta.author}}
        </p>
      </li>
      <li *ngIf="!meta.author && account?.account_type_name === 'Facebook'"
          class="message-box-message-header-meta-title-wrap"
          (click)="openViewProfileModal()">
        <p [ngClass]="{'message-box-message-header-meta-title-big': isRoot}"
           class="message-box-message-header-meta-title">
          Personal Profile
        </p>
      </li>
    </ul>
    <ul class="message-box-message-header-meta">
      <li *ngIf="!meta.author && account?.account_type_name === 'Facebook'">
        <i class="message-box-message-header-meta-badge ssi ssi-information"
           ssiTooltip="Facebook prevents previewing author names if it's a personal account. Why not view this post natively to make your reply more personalised?"
           tooltipWindowClass="tooltip-chunky-big"
           tooltipPlacement="bottom"
           [tooltipAppendToBody]="true"></i>
      </li>
      <li>
        <i
          *ngIf="activity?.author?.verified"
          class="message-box-message-header-meta-verified verified-badge ssi ssi-verified-icon"
        ></i>
      </li>
      <li>
        <i class="message-box-message-header-meta-badge"
           [ngClass]="account?.socialNetwork.icon.web"></i>
      </li>
      <li *ngIf="meta.handle">
        <p class="message-box-message-header-meta-badge">@{{meta.handle}}</p>
      </li>
    </ul>

    <ul class="message-box-message-header-meta">
      <li *ngIf="!selfPenned && meta.response_time">
        <p class="message-box-message-header-meta-badge"><i class="ssi ssi-actioned-time"></i> {{meta.response_time}}</p>
      </li>
      <li>
        <p class="message-box-message-header-meta-item">{{meta.created_at | date:'HH:mm'}}</p>
      </li>
      <li>
        <p class="message-box-message-header-meta-item">{{meta.created_at | date:'d MMM yyyy'}}</p>
      </li>
      <!-- source is hidden because it's been hiden for ages and could be: Orlo, Unknown, DESKTOP, Twitter Web App-->
      <!-- <li *ngIf="meta.source">
        <p class="message-box-message-header-meta-item">{{meta.source}}</p>
      </li> -->
      <li *ngIf="meta.author_outbox">
        <p class="message-box-message-header-meta-item">By {{meta.author_outbox}}</p>
      </li>
    </ul>
  </div>

  <ng-container *ngIf="translatable || translated || deleted.deleted_at">
    <orlo-button *ngIf="translatable && !translated"
                 class="message-box-message-header-translate"
                 (click)="$event.stopPropagation(); translateText(true)"
                 [text]="'Translate message'"
                 [leadingIcon]="'ssi ssi-translate'"
                 [size]="'small-beta'"
                 [bgColor]="'#F8F9FD'"
                 [textColor]="'#14BAE3'"
                 [state]="'primary'"></orlo-button>

    <orlo-button *ngIf="translated"
                 class="message-box-message-header-translate"
                 (click)="$event.stopPropagation(); translateText(false)"
                 [text]="'Undo translations'"
                 [leadingIcon]="'ssi ssi-undo'"
                 [size]="'small-beta'"
                 [bgColor]="'#F8F9FD'"
                 [textColor]="'#14BAE3'"
                 [state]="'primary'"></orlo-button>
  </ng-container>
</div>

<div class="message-box-message-body">

  <div
    class="message-box-content-box"
    [ngClass]="{'message-box-content-box-overflow-visible': mediaOnlyMessage}"
  >
    <p
      *ngIf="!mediaOnlyMessage && !redacted?.redacted_by"
       class="message-box-content"
      >
      <ng-content></ng-content>
    </p>

    <p *ngIf="mediaOnlyMessage && !hasAttachments && !activity.media && !redacted?.redacted_by"
       class="message-box-content">
      <em translate="NO_MESSAGE_TEXT">
      </em>
    </p>

    <p *ngIf="activity && activity.media && activity.media.length > 0 && activity.media[0].link && account?.account_type_name === 'Instagram Business' && !selfPenned"
       class="message-box-content">
      Mentioned you in their {{activity.interaction.is_private ? 'Story' : 'Post'}}<br>
      <a href="{{activity.media[0].link}}"
         target="_blank">View {{activity.interaction.is_private ? 'Story' : 'Post'}} on Instagram</a>
    </p>

    <p *ngIf="activity && redacted && redacted?.redacted_by || (activity && activity.interaction.content === '[Redacted]')"
        class="message-box-content">
      <span *ngIf="activity.interaction?.redacted_by == 14">
        <em>Message redacted by System</em>
      </span>
      <span *ngIf="activity.interaction?.redacted_by != 14">
        <em>Message redacted by {{redacted?.redacted_by?.fullName}}</em>
      </span>
      <span *ngIf="redacted.redacted_at"><em> at {{redacted.redacted_at | date:'HH:mm d/MM/y'}}</em></span>
    </p>

    <ssi-message-attachments *ngIf="mediaOnlyMessage && hasAttachments && !redacted?.redacted_by"
                             class="flex"
                             [activity]="activity"
                             [attachments]="attachments"></ssi-message-attachments>
  </div>
</div>

<ssi-message-actions-inline
  *ngIf="isPrivate"
  [activity]="activity"
  [account]="account"
  [selfPenned]="selfPenned"
  [assignedSuccess]="assignedSuccess"
  [tagsTooltip]="tagsTooltip"
  [sentiment]="sentiment"
  [shouldEnableSentimentShortcut]="!selfPenned && isActive"
  [sentimentOptions]="sentimentOptions"
  [likeConfig]="likeConfig"
  [hideCommentConfig]="hideCommentConfig"
  [isInbox]="isInbox"
  [activeTags]="activeTags"
  [showConversationAssignTo]="showConversationAssignTo"
  [showDelete]="showDelete"
  [showRedact]="showRedact"
  [showViewNatively]="showViewNatively"
  [isConvertedToActivity]="isConvertedToActivity"
  [notes]="notes"
  (viewNativelyClicked)="viewNativelyClicked.emit()"
  (showActionsChange)="showActionsChange.emit($event)"
  (toggleLikeClicked)="toggleLikeClicked.emit()"
  (toggleShowHideCommentClicked)="toggleShowHideCommentClicked.emit($event)"
  (conversationAssignToClicked)="openAssign(); showActions = false"
  (deleteClicked)="deleteClicked.emit()"
  (redactConfirmed)="redactConfirmed.emit()"
  (hideActivity)="hideActivity.emit()"
></ssi-message-actions-inline>

<div class="shared-post-container"
     *ngIf="activity?.sharePreview">
  <ssi-message-share-preview [sharePreview]="activity.sharePreview"
                             [activity]="activity"></ssi-message-share-preview>
</div>

<div class="bottom-line-container"
     *ngIf="activity?.sharePreview?.type === ShareType.QuotedTweet">
  <div class="bottom-line"></div>
</div>

<ssi-message-footer
  [isPrivate]="isPrivate"
  [activity]="activity"
  [isActive]="isActive"
  [isRoot]="isRoot"
  [isInbox]="isInbox"
  [isActionable]="isActionable"
  [selfPenned]="selfPenned"
  [canReply]="canReply"
  [enableActions]="enableActions"
  [activeTags]="activeTags"
  [tagsTooltip]="tagsTooltip"
  [sentiment]="sentiment"
  [sentimentOptions]="sentimentOptions"
  [shouldEnableSentimentShortcut]="!selfPenned && isActive"
  [assignedSuccess]="assignedSuccess"
  (showActionsChange)="showActionsChange.emit($event)"
  [showConversationAssignTo]="showConversationAssignTo"
  [showDelete]="showDelete"
  [showRedact]="showRedact"
  [showViewNatively]="showViewNatively"
  [account]="account"
  [stats]="stats"
  [statsShown]="showStats"
  [deleted]="deleted"
  [redacted]="redacted"
  [likeConfig]="likeConfig"
  [hideCommentConfig]="hideCommentConfig"
  [retweetConfig]="retweetConfig"
  [isConvertedToActivity]="isConvertedToActivity"
  [notes]="notes"
  (setActive)="setActive.emit()"
  (retweet)="retweet.emit($event)"
  (quoteRetweet)="quoteRetweet.emit()"
  [retweetSuccess]="retweetSuccess"
  (showStats)="showStats = !showStats"
  (viewNativelyClicked)="viewNativelyClicked.emit()"
  (toggleLikeClicked)="toggleLikeClicked.emit()"
  (toggleShowHideCommentClicked)="toggleShowHideCommentClicked.emit($event)"
  (conversationAssignToClicked)="openAssign(); showActions = false"
  (deleteClicked)="deleteClicked.emit()"
  (redactConfirmed)="redactConfirmed.emit()"
  (hideActivity)="hideActivity.emit()"
></ssi-message-footer>

<div *ngIf="isRoot && showStats"
     class="message-box-expandable-meta">
  <!-- <span
    class="message-box-expandable-meta-hide"
    (click)="showStats = false">
    <i class="ssi ssi-arrow-up"></i>
  </span> -->

  <ul class="message-box-stats">
    <ng-container *ngFor="let stat of parsedStats">
      <li *ngIf="stat.value || !stat.supported"
          [ssiTooltip]="stat.supported ? stat.tooltip : 'Not supported by this network'"
          [tooltipWindowClass]="'tooltip-chunky'"
          class="message-box-stat">
        <i [ngClass]="stat.iconClass"
           class="message-box-stat-icon ssi"></i>
        <span class="message-box-stat-value">
          {{stat.supported ? stat.value : '-'}}
        </span>
      </li>
    </ng-container>
  </ul>
</div>

<ssi-message-link-preview *ngIf="linkPreview"
                          class="message-box-message-link-preview"
                          [selfPenned]="selfPenned"
                          [title]="linkPreview.title"
                          [description]="linkPreview.description"
                          [url]="linkPreview.url"
                          [thumb]="linkPreview.media"></ssi-message-link-preview>