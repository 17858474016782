import './reply-box.component.scss';

import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  ElementRef,
  Input,
  ViewChild,
  SimpleChanges,
  OnChanges,
  Renderer2,
  AfterViewChecked,
  AfterViewInit
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import twitterText, { UrlWithIndices } from 'twitter-text';

import { mapToIterable } from '../../utils';
import { CompanyService } from '../../services/company/company.service';
import { SurveyService } from '../../services/api/surveys';
import { Debounce } from '../../decorators';
import {
  Account,
  Activity,
  ActivityThread,
  Conversation,
  socialNetworkSettings
} from '@ui-resources-angular';
import { ConfirmationModalComponent } from '../../../common/components/confirmation-modal/confirmation-modal.component';
import { Option } from '../../../common/components/dropdown-select-2/dropdown-select-2.component';
import { FileUploadFile } from '../../directives/file-uploader/file-uploader.directive';
import { FilestackFile } from '../../services/filestack/filestack.service';
import { ActivityReplyUser } from '../activity/activity.component';

export interface Attachment {
  id: number;
  label: string;
  type: string;
}

export const keyboardShortcutKeys = [
  'keydown.meta.a',
  'keydown.meta.u',
  'keydown.meta.r',
  'keydown.meta.s',
  'keydown.meta.l',
  'keydown.meta.t',
  'keydown.meta.f',
  'keydown.meta./'
];

const DESKTOP_WIDTH = 520;

export enum ReplyBoxLayout {
  Mobile = 'mobile',
  Desktop = 'desktop',
  Hidden = 'hidden'
}

export enum ActivityStatus {
  Unread = 'unread',
  Unactioned = 'unactioned',
  Actioned = 'actioned'
}

@Component({
  selector: 'ssi-reply-box',
  templateUrl: './reply-box.component.html'
})
export class ReplyBoxComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewChecked, AfterViewInit {
  isActive = false;
  expanded = false;
  disableReply = true;
  translated = false;
  isPredictive = false;
  replyText = '';
  textareaHasFocus = false;
  private maxCharactersAllowed: number;
  isLiveChat = false;
  mediaUploading = false;
  addPrivateReplyLinkToggled = false;
  showKeyboardShortcuts = false;
  messageSessionInvalid = false;
  reviewTrackers = false;
  messageSessionExplanation?: any = {};
  includedReplyToUsers: Array<ActivityReplyUser | any> = [];
  public wordsRemaining: number;
  layout: ReplyBoxLayout = ReplyBoxLayout.Hidden;
  ReplyBoxLayout = ReplyBoxLayout;

  replyContent: {
    tracker?: angular.promisetracker.PromiseTracker;
    text?: string;
    account?: Account;
    visible?: boolean;
    add_dm_reply_link?: boolean;
    file?: any;
    link?: any;
  } = {};

  /* Most recent inbound message (thread.current activity) */
  @Input() activity: Activity;
  @Input() activityThread: ActivityThread;
  @Input() replyValue: any;
  @Input() isInbox = false;
  @Input() isDisabled = false;
  @Input() isSocialWall = false;
  @Input() replyAccounts: Account[] = [];
  @Input() replyAccount: Account;
  @Input() includableReplyToUsers: ActivityReplyUser[];
  @Input() replyingToVisible = false;
  @Input() accountTypeName: string;
  @Input() networkReplyOptions: any;
  @Input() shouldShowPushToCrm = false;
  @Input() shouldShowResolve = false;
  @Input() shouldShowTranslate = false;
  @Input() mediaRestrictions: any;
  @Input() attachments?: Attachment | Attachment[];
  @Input() prediction?: string;
  @Input() isTimedSession? = false;
  @Input() daysToCountdown?: number = 1;
  @Input() sessionCreatedAt?: any;
  @Input() supportsAddReplyLink? = false;
  @Input() keyboardShortcuts?: any;
  @Input() hideArrowShortcuts? = false;
  @Input() focusReply? = false;
  @Input() conversation: Conversation;
  @Input() replyInProgress = false;
  @Input() isPushMode = false;
  @Input() firstCommentScheduling = false;
  @Input() inboxApproval = false;

  @ViewChild('replyBox') replyBoxElm: ElementRef;
  @ViewChild('predictionElement') predictionElement: ElementRef;

  @Output() onReplyAccountChange = new EventEmitter<Account>();
  @Output() onTextareaFocus = new EventEmitter<any>();
  @Output() onKeyUp = new EventEmitter<any>();
  @Output() onKeyDown = new EventEmitter<any>();
  @Output() onTranslate = new EventEmitter<any>();
  @Output() onPushToCrm = new EventEmitter<any>();
  @Output() onPaste = new EventEmitter<any>();
  @Output() onAddImage = new EventEmitter<any>();
  @Output() onAddGif = new EventEmitter<any>();
  @Output() onTriggerEmoji = new EventEmitter<any>();
  @Output() onAddPrivateReplyLink = new EventEmitter<any>();
  @Output() onSendReply = new EventEmitter<any>();
  @Output() onResolveConversation = new EventEmitter<any>();
  @Output() onHoldConversationStatusChange = new EventEmitter<any>();
  @Output() onUpdateReplyTextValue = new EventEmitter<any>();
  @Output() onRemoveAttachment = new EventEmitter<any>();
  @Output() onFileUploadSuccess = new EventEmitter<any>();
  @Output() onFileUploadError = new EventEmitter<any>();
  @Output() onKeyboardShortcut = new EventEmitter<any>();
  @Output() onPushSelectedTags = new EventEmitter<any>();
  @Output() onReplyBoxStatusChange = new EventEmitter<any>();
  @Output() onCancel = new EventEmitter<any>();

  constructor(
    public modal: NgbModal,
    private renderer2: Renderer2,
    private companyService: CompanyService,
    private translateService: TranslateService,
    private surveyService: SurveyService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.replyValue) {
      this.replyText = changes.replyValue.currentValue;
      if (!this.maxCharactersAllowed) {
        this.maxCharactersAllowed = this.getMaxCharactersAllowed();
      }
      this.updateReplyTextValue(this.replyText);
    }

    if (changes.shouldShowTranslate) {
      this.translated = !changes.shouldShowTranslate.currentValue;
    }

    if (changes.replyAccounts) {
      this.replyAccounts = (this.replyAccounts || []).filter(
        (acc) => acc.account_type_name === this.accountTypeName
      );
    }

    if (changes.includableReplyToUsers) {
      this.includableReplyToUsers = this.includableReplyToUsers || [];
      if (this.activity.conversation.depth === 0) {
        // for tier 1 posts include all includable users automatically
        this.includedReplyToUsers = [...this.includableReplyToUsers];
        this.includableReplyToUsers.forEach((user) => {
          user.excluded = false;
        });
      } else {
        this.includableReplyToUsers.forEach((user) => {
          user.excluded = true;
        });
      }
    }
  }

  ngOnInit() {
    if (this.firstCommentScheduling) {
      this.isActive = true;
    }

    if (!this.hideArrowShortcuts) {
      keyboardShortcutKeys.map((key) => {
        this.renderer2.listen('document', `${key}`, (event: KeyboardEvent) => {
          this.onKeyboardShortcut.emit(event);
        });
      });
    }

    this.maxCharactersAllowed = this.getMaxCharactersAllowed();

    this.companyService
      .hasFeatureAccess('PREDICTIVE_TEXT')
      .then((isPredictive) => (this.isPredictive = !!isPredictive));
    this.onReplyBoxStatusChange.emit({ status: 'COMPRESSED' });
  }

  ngOnDestroy() {
    this.onReplyBoxStatusChange.emit({ status: 'NONE' });
  }

  ngAfterViewChecked(): void {
    if (this.activity && this.activity.interaction) {
      this.messageSessionExplanation = this.getMessageTooltip();
    }

    if (this.replyBoxElm && this.layout !== this._getLayoutClass()) {
      this.layout = this._getLayoutClass();
    }

    this.setSessionValidity();
  }

  ngAfterViewInit(): void {
    this.layout = this._getLayoutClass();
  }

  getMaxCharactersAllowed(): number {
    return socialNetworkSettings[this.accountTypeName].maxPostCharacters[
      this.isPrivateThread ? 'private' : 'reply'
    ];
  }

  setSessionValidity() {
    if (!this.activityThread || !this.activityThread.thread) {
      return;
    }

    const isReviewTracker =
      this.activityThread.thread.current.interaction.social_type ===
      'review_trackers';

    const threadHasChildActivities =
      this.activityThread.thread.children.activities &&
      this.activityThread.thread.children.activities.length > 0;

    if (
      isReviewTracker &&
      (threadHasChildActivities || !this.activity.social_actions.can_reply)
    ) {
      this.messageSessionInvalid = true;
    }
  }

  getMessageTooltip(): any {
    let tooltip = {
      title: '',
      content: '',
      iconClass: '',
      validityMessage: ''
    };
    switch (this.activity.interaction.social_type) {
      case 'whatsapp':
        tooltip = {
          title: 'WhatsApp',
          content: 'VALID_TIMED_SESSION_EXPLANATION_WHATSAPP',
          iconClass: 'ssi ssi-whatsapp',
          validityMessage: 'This session is no longer valid'
        };
        return tooltip;
      case 'facebook':
        tooltip = {
          title: 'Facebook Messenger Policy',
          content: this.messageSessionInvalid
            ? `INVALID_TIMED_SESSION_EXPLANATION_FACEBOOK`
            : `VALID_TIMED_SESSION_EXPLANATION_FACEBOOK`,
          iconClass: 'fa fa-facebook',
          validityMessage: 'This session is no longer valid'
        };
        return tooltip;
      case 'review_trackers':
        tooltip = {
          title: 'ReviewTrackers API limitation',
          content: this.activity.social_actions.can_reply
            ? `REVIEWTRACKERS_ONLY_SUPPORT_SENDING_ONE_REPLY_PER_REVIEW`
            : `REVIEWTRACKERS_ONLY_SUPPORT_SENDING_REPLIES_FOR_GOOGLE_AND_FACEBOOK_REVIEWS`,
          iconClass: 'ssi ssi-star',
          validityMessage: this.activity.social_actions.can_reply
            ? `You can no longer reply to this review. You can <a href="${this.activity.interaction.link}" target="_blank" rel="noopener noreferrer">view this review</a> natively.`
            : `You cannot reply to this review here. <a href="${this.activity.interaction.link}" target="_blank" rel="noopener noreferrer">View this review</a> natively.`
        };
        return tooltip;
      default:
        return {};
    }
  }

  collapseReply() {
    if (this.textareaHasFocus) {
      return;
    }

    if (!this.mediaUploading) {
      this.isActive = false;
      this.expanded = false;
      this.onReplyBoxStatusChange.emit({ status: 'COMPRESSED' });
    }
  }

  toggleMediaUpload(mediaUploading: boolean) {
    this.mediaUploading = mediaUploading;
    // this.isActive = mediaUploading;
    // this.onReplyBoxStatusChange.emit({
    //   status: mediaUploading ? 'ACTIVE' : 'COMPRESSED'
    // });
  }

  updateReplyTextValue(text: string) {
    this.onUpdateReplyTextValue.emit(text);
    this.wordsRemaining = this.getWordsRemaining(
      text,
      this.maxCharactersAllowed
    );
  }

  getWordsRemaining(text: string, maxCharactersAllowed: number): number {
    if (!text || text.length === 0) {
      return maxCharactersAllowed;
    }

    if (this.replyAccount.account_type_name === 'Twitter') {
      return maxCharactersAllowed - twitterText.parseTweet(text).weightedLength;
    }

    return maxCharactersAllowed - text.length;

    // const links: UrlWithIndices[] = twitterText.extractUrlsWithIndices(text);
    // let processedText = text;

    // if (links.length) {
    //   const activeAccount: Account = this.replyAccount;
    //   let offset = 0;

    //   links.forEach((link) => {
    //     const processedUrl = `http://${activeAccount.default_vanity_domain}/'xxxxx'`;

    //     processedText =
    //       processedText.substring(0, link.indices[0] + offset) +
    //       processedUrl +
    //       processedText.substring(link.indices[1] + offset);

    //     offset += processedUrl.length - (link.indices[1] - link.indices[0]);
    //   });
    // }

    // return maxCharactersAllowed - processedText.length;
  }

  textareaFocus() {
    this.textareaHasFocus = true;
    this.onTextareaFocus.emit();
    this.isActive = true;
    this.onReplyBoxStatusChange.emit({ status: 'ACTIVE' });
  }

  textareaBlur() {
    this.textareaHasFocus = false;
  }

  keyUp(event: KeyboardEvent) {
    this.onKeyUp.emit(event);
  }

  keyDown(event: KeyboardEvent) {
    this.onKeyDown.emit(event);
  }

  paste(event: ClipboardEvent) {
    // not used yet
    this.onPaste.emit(event);
    this.replyContent.file = event.clipboardData.files;
    console.log('PASTE files:', event.clipboardData.files);
  }

  changeReplyAccount(account: Account) {
    this.onReplyAccountChange.emit(account);
  }

  updateReplyToUsers(selectedUsers: Array<ActivityReplyUser | any>): void {
    this.includableReplyToUsers.forEach((user) => {
      user.excluded = this.includedReplyToUsers.indexOf(user) === -1;
    });
  }

  translate() {
    this.prediction = '';
    this.onTranslate.emit(this.replyText);
    this.translated = !this.translated;
  }

  pushToCrm() {
    this.onPushToCrm.emit(event);
  }

  addPrivateReplyLink() {
    this.addPrivateReplyLinkToggled = !this.addPrivateReplyLinkToggled;
    this.onAddPrivateReplyLink.emit();
  }

  toggleReplyView() {
    this.expanded = !this.expanded;
    this.onReplyBoxStatusChange.emit({
      status: this.expanded ? 'EXPANDED' : 'ACTIVE'
    });
  }

  fileUploadSuccess(files: FileUploadFile[]) {
    this.onFileUploadSuccess.emit(files[0].filestackFile);
  }

  gifUploadSuccess(gifObj: FilestackFile) {
    this.onFileUploadSuccess.emit(gifObj);
  }

  fileUploadError(file) {
    this.onFileUploadError.emit(file);
  }

  removeAttachment() {
    this.onRemoveAttachment.emit();
  }

  public sendReply(replyText: string) {
    // this method is being accessed outside of this component (from the parent)
    if (this.replyInProgress || !this.canReply()) {
      return;
    }

    this.replyContent.text = replyText;
    this.onSendReply.emit(this.replyContent);

    if (
      this.activityThread &&
      this.activityThread.thread.current.interaction.social_type ===
        'review_trackers'
    ) {
      this.messageSessionInvalid = true;
    }
  }

  async holdConversationStatusChange(option: any): Promise<void> {
    if (this.canReply()) {
      // there is some text/content in the reply-box - deliver that first
      this.sendReply(this.replyText); // TODO: Test if we need to await this action (can resolve if reply in progress)!?
      option.skipConversationStatusCheck = true;
    }

    this.onHoldConversationStatusChange.emit(option);
  }

  async resolve(withSurvey = true) {
    // default 'withSurvey' flag to 'true' excpet user explicitly selects 'without survey' option on UI - backend will
    // not try to send a survey if it's e.g. public convo or surveys aren't enabled for account
    // (it's safer that way since surveyConfig is being cached locally)

    if (this.canReply()) {
      // there is some text/content in the reply-box - deliver that first
      this.sendReply(this.replyText); // TODO: Test if we need to await this action (can resolve if reply in progress)!?
    }

    this.onResolveConversation.emit({ with_survey: withSurvey });
  }

  sessionValidityChange(isInvalid: boolean) {
    this.messageSessionInvalid = isInvalid;
    if (isInvalid) {
      this.onReplyBoxStatusChange.emit({ status: 'HIDDEN' });
    }
  }

  get attachmentsCount(): number {
    if (Array.isArray(this.attachments)) {
      return this.attachments.length;
    }

    if (this.attachments) {
      return 1;
    }

    return 0;
  }

  get isPrivateThread(): boolean {
    return this.activity && this.activity.interaction.is_private;
  }

  public canReply(): boolean {
    // this method is being accessed outside of this component (from the parent)
    if (this.messageSessionInvalid || this.isDisabled) {
      return false;
    }

    if (this.replyText) {
      if (!this.replyText.trim().length) {
        // white space
        return false;
      }
      return this.wordsRemaining >= 0;
    }

    return !!(
      this.networkReplyOptions &&
      this.networkReplyOptions.responseMedia &&
      this.networkReplyOptions.responseMedia.textOptional &&
      this.attachments
    );
  }

  private _getLayoutClass(): ReplyBoxLayout {
    if (!this.replyBoxElm) {
      return ReplyBoxLayout.Hidden;
    }

    const replyBoxWidth = this.replyBoxElm.nativeElement.clientWidth;
    return replyBoxWidth > DESKTOP_WIDTH
      ? ReplyBoxLayout.Desktop
      : ReplyBoxLayout.Mobile;
  }
}
