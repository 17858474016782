import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { transition, trigger, useAnimation } from '@angular/animations';
import { slideIn, slideOutSmooth } from '../../animations';
import { Subscription } from 'rxjs';
import {
  PUBLISHER_ACTIVE,
  PublisherActive,
  PublisherActiveParams,
  PublisherDisableOptions
} from './publisher-active';
import {
  AccountModel,
  Account,
  CampaignModel,
  Campaign,
  OutboxPublisher,
  UserModel,
  OutboxFileType,
  BlockingWord,
  getMimetypeFromUrl,
  ImageMimetype,
  Outbox,
  OutboxPublisherFile,
  OutboxMessageAttachmentImage,
  MediaCategory,
  OutboxMessageAttachmentType,
  OutboxMessageAttachment,
  OutboxMessageAttachmentVideoWithTitle,
  AutoComment
} from '@ui-resources-angular';
import { orderBy } from 'lodash-es';
import { WorkflowManagerService } from '../../../common/services/workflow-manager/workflow-manager.service';
import { AsyncTrackerFactory } from 'angular-async-tracker';
import { FileUploadFile } from '../../directives/file-uploader/file-uploader.directive';
import { FileUploaderService } from '../../directives/file-uploader/file-uploader.service';
import { PopupService } from '../../services/popup/popup.service';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash-es';
import { CompanyService } from '../../services/company/company.service';
import { StorageService } from '../../services/storage';
import {
  FilestackService,
  FilestackSources
} from '../../services/filestack/filestack.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PublisherSocialNetworkPreviewModalComponent } from './publisher-social-network-preview-modal/publisher-social-network-preview-modal.component';
import { QuoteRetweetModalComponent } from './quote-retweet-modal/quote-retweet-modal.component';
import { StateService } from '@uirouter/angular';
import bytes from 'bytes';
import moment from 'moment';
import {
  SuggestedTextModalComponent,
  SuggestedTextModalResult
} from './suggested-text-modal/suggested-text-modal.component';
import { FacebookShareModalComponent } from './facebook-share-modal/facebook-share-modal.component';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { SaveDraftModalComponent } from '../save-draft-modal/save-draft-modal.component';
import { ScheduleFirstCommentComponent } from '../schedule-first-comment/schedule-first-comment.component';
import {
  DraftExtra,
  DraftsLibraryService,
  DraftType
} from '../../../modules/auth/marketing/drafts-library/drafts-library.service';
import { NotificationService } from '../../services/notification/notification.service';
import { PublisherExpiryModalComponent } from './publisher-landing/publisher-actions-and-media/publisher-expiry-modal/publisher-expiry-modal.component';

export enum Stage {
  Landing = 'landing',
  Schedule = 'schedule'
}

function getOrderedOpenCampaigns(campaigns: Campaign[]): Campaign[] {
  return orderBy(campaigns, ['name']).filter((campaign) => !campaign.is_closed);
}

@Component({
  selector: 'ssi-publisher',
  templateUrl: './publisher.component.html',
  animations: [
    trigger('slideInOut', [
      transition('void => *', useAnimation(slideIn)),
      transition('* => void', useAnimation(slideOutSmooth))
    ])
  ]
})
export class PublisherComponent implements OnInit, OnDestroy {
  @ViewChild('postInvalidCharacters') postInvalidCharacters: TemplateRef<any>;
  @ViewChild('draftSavedMessageTemplate')
  draftSavedMessageTemplate: TemplateRef<any>;

  isActive = false;
  publisherActiveSubscription: Subscription;
  accounts: Account[] = [];
  campaigns: Campaign[] = [];
  allCampaigns: Campaign[] = [];
  stage: Stage = Stage.Landing;
  stages = Stage;
  slideAnimationCompleted = false;
  post: OutboxPublisher;
  disable: PublisherDisableOptions;
  params: PublisherActiveParams;

  loadingTrackers = {
    initial: this.asyncTrackerFactory.create(),
    publishing: this.asyncTrackerFactory.create(),
    savingDraft: this.asyncTrackerFactory.create()
  };

  fileUploadSources: FilestackSources[];

  constructor(
    private storageService: StorageService,
    @Inject(PUBLISHER_ACTIVE) public publisherActive: PublisherActive,
    public accountModel: AccountModel,
    private workflowManager: WorkflowManagerService,
    private campaignModel: CampaignModel,
    private userModel: UserModel,
    private asyncTrackerFactory: AsyncTrackerFactory,
    private popup: PopupService,
    private translate: TranslateService,
    private company: CompanyService,
    private modal: NgbModal,
    private filestack: FilestackService,
    private blockingWord: BlockingWord,
    private state: StateService,
    private fileUploaderService: FileUploaderService,
    private draftsLibraryService: DraftsLibraryService,
    protected notificationService: NotificationService
  ) {
    this.publisherActiveSubscription = this.publisherActive.subscribe(
      async (params) => {
        this.params = params;
        const promise = this.setupPost(params);
        this.loadingTrackers.initial.add(promise);
        try {
          await promise;
        } catch (e) {
          console.error(e);
        }
      }
    );
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.publisherActiveSubscription) {
      this.publisherActiveSubscription.unsubscribe();
    }
  }

  changeStage(stage: Stage) {
    if (stage === Stage.Schedule && this.post.accounts.length === 0) {
      return;
    }
    this.stage = stage;
  }

  async saveAsDraft(): Promise<void> {
    const user = await this.userModel.getAuthUser();
    let isDraftPublic = false;
    let draftTitle = (this.params.draft && this.params.draft.title) || '';
    const modalParams = {
      title: 'Save new draft',
      meta: 'Add a title for your new draft:',
      userIsAdmin: user.hasCompanyPermission('manage_public_drafts'),
      isDraftPublic,
      showSecondaryAction: false,
      primaryActionButtonText: 'Save current draft',
      draftTitle
    };
    let replaceDraft = false;

    if (this.params.draft && this.params.draft.id) {
      isDraftPublic = this.params.draft.draft_type === DraftType.Public;
      Object.assign(modalParams, {
        title: 'Save current draft or create a new one?',
        meta:
          'Would you like to save the changes made to the current draft you have selected? Or create a shiny brand new one?',
        isDraftPublic,
        showSecondaryAction: true
      });
    }

    const confirmationModal = this.modal.open(SaveDraftModalComponent, {
      windowClass: 'orlo-modal'
    });
    Object.assign(confirmationModal.componentInstance, modalParams);
    confirmationModal.componentInstance.onDraftTitleChange.subscribe(
      (newDraftTitle: string) => (draftTitle = newDraftTitle)
    );
    confirmationModal.componentInstance.onPublicDraftToggle.subscribe(
      (isPublic: boolean) => (isDraftPublic = isPublic)
    );
    const result = await confirmationModal.result;
    if (!result) {
      return;
    }
    replaceDraft = this.params.draft && this.params.draft.id && result === 2;

    this.post.multiImage = true;
    if (this.stage === 'landing') {
      this.post.album = {};
    }

    // if (this.stage === 'schedule' && this.post.schedules.length === 0) {
    //   return;
    // }

    if (this.illegalCharsRemovedWithWarning()) {
      return;
    }

    if (!(await this.validateFiles())) {
      return;
    }

    if (
      this.post.validity.isValid &&
      !this.loadingTrackers.savingDraft.active
    ) {
      const outboxMessages = await this.post.toOutboxMessages();
      const extra: DraftExtra = {
        isSplit: this.post.isSplit,
        splitPostAccountId: this.post.splitPostAccount.id,
        igShareToFeed: this.post.igShareToFeed,
        scheduleFirstCommentToggled: this.post.scheduleFirstCommentToggled
      };

      if (this.post.hasTargetingSet()) {
        extra.targeting = this.post.targeting;
      }

      const savingDraft = this.draftsLibraryService.createOrUpdateDraft(
        draftTitle || 'Untitled draft',
        isDraftPublic ? DraftType.Public : DraftType.Private,
        outboxMessages,
        extra,
        replaceDraft ? this.params.draft.id : undefined
      );
      this.loadingTrackers.publishing.add(savingDraft);
      await savingDraft;

      this.publisherActive.next({ isActive: false });

      this.notificationService.open(
        this.draftSavedMessageTemplate,
        {
          class: 'ssi ssi-completed-notification',
          color: '#B2C614'
        },
        5000
      );
    }
  }

  private async _setExpiryDateModal(): Promise<boolean> {
    const modal = await this.modal.open(PublisherExpiryModalComponent, {
      windowClass: 'orlo-modal'
    });
    modal.componentInstance.actionButtonText =
      this.post.schedules.length === 0
        ? 'Set expiry date and publish post'
        : 'Set expiry date and schedule post';
    if (this.post.delete_at) {
      modal.componentInstance.expiryDate = this.post.delete_at;
    }
    if (this.post.schedules.length) {
      modal.componentInstance.startDate = this.post.schedules.reduce(function (
        a,
        b
      ) {
        return a > b ? a : b;
      });
    }
    return modal.result.then((expiryDate) => {
      if (!expiryDate) {
        return false;
      }
      this.post.delete_at = expiryDate;
      return true;
    });
  }

  async publish() {
    if (!this.post.validity.isValid || this.loadingTrackers.publishing.active) {
      return;
    }

    if (this.post.delete_at) {
      const modal = await this._setExpiryDateModal();
      if (!modal) {
        return;
      }
    }

    this.post.multiImage = true;
    if (this.stage === 'landing') {
      this.post.album = {};
    }

    if (this.stage === 'schedule' && this.post.schedules.length === 0) {
      return;
    }

    if (this.illegalCharsRemovedWithWarning()) {
      return;
    }

    if (!(await this.validateFiles())) {
      return;
    }

    if (this.post.schedules.length > 1) {
      const { accounts, schedules } = this.post;
      const momentSchedules = schedules.map((schedule) => moment(schedule));
      const datesValid = momentSchedules.every((schedule) =>
        momentSchedules.every((otherSchedule) => {
          const difference = moment
            .duration(schedule.diff(otherSchedule))
            .abs()
            .asHours();
          return difference === 0 || difference > 168;
        })
      );
      if (this.post.hasTwitterSelected() && !datesValid) {
        this.popup.alert({
          isError: true,
          message:
            'Scheduled dates must be at least 7 days apart from other scheduled times to prevent social network spamming rules.'
        });
        return;
      }
    }

    if (this.post.scheduleFirstCommentToggled) {
      const orderedAccounts = orderBy(this.post.accounts, [
        'account_type_id',
        'name'
      ]);
      for (let i = 0; i < orderedAccounts.length; ++i) {
        // prepopulate text only for accounts of the same type
        const account = orderedAccounts[i];
        const previousAccount = orderedAccounts[i - 1];

        const editModeAutoComment =
          this.post.edit &&
          Array.isArray(this.post.edit.auto_comment) &&
          this.post.edit.auto_comment[0];

        const prepopulateReply = editModeAutoComment
          ? editModeAutoComment
          : i > 0 &&
            this.post.autoCommentByAccountId[previousAccount.id] &&
            account.account_type_id === previousAccount.account_type_id
          ? this.post.autoCommentByAccountId[previousAccount.id]
          : undefined;

        try {
          await this.showScheduleFirstCommentModal(account, prepopulateReply);
        } catch (e) {
          console.log('Cancelled / modal dismissed..');
          this.post.autoCommentByAccountId = {};
          return;
        }
      }
    }

    const publishPromise = this.post.publish();
    this.loadingTrackers.publishing.add(publishPromise);
    const publishedPosts = await publishPromise;

    if (
      this.params.draft &&
      this.params.draft.draft_type === DraftType.Private
    ) {
      await this.draftsLibraryService.deleteDraft(this.params.draft.id);
    }

    this.publisherActive.next({ isActive: false, published: true });
  }

  async showScheduleFirstCommentModal(
    account: Account,
    prepopulateReply?: AutoComment
  ): Promise<AutoComment | undefined> {
    const modal = await this.modal.open(ScheduleFirstCommentComponent, {
      windowClass: 'orlo-modal orlo-modal-1200 position-top-20',
      backdropClass: 'orlo-modal-backdrop'
    });
    modal.componentInstance.account = account;
    modal.componentInstance.publisher = this.post;
    modal.componentInstance.prepopulateReply = prepopulateReply;

    return await modal.result;
  }

  async validateFiles(): Promise<boolean> {
    if (!Array.isArray(this.post.files)) {
      return true;
    }

    if (
      !this.post.edit &&
      (!(await this.validateImageFiles()) || !(await this.validateVideoFiles()))
    ) {
      return false;
    }

    return true;
  }

  async validateImageFiles(): Promise<boolean> {
    const promises = this.post.imageFiles.map((file) =>
      this.fileUploaderService.validateAndConvertImage(
        file,
        this.post.mediaRestrictions
      )
    );

    const results = await Promise.all(promises);

    let hasErrors = false;
    results.forEach((result, i) => {
      // update the outbox file with new props that might have changed during the validation procedure (url, size, etc.)
      this.post.imageFiles[i].url = result.file.url;
      this.post.imageFiles[i].handle = result.file.handle;
      this.post.imageFiles[i].size = result.file.size;
      this.post.imageFiles[i].mimetype = result.file.mimetype;
      this.post.imageFiles[i].filestackFile = {
        ...this.post.imageFiles[i].filestackFile,
        ...result.file
      };

      if (result.errors) {
        hasErrors = true;
      }
    });

    return !hasErrors;
  }

  async validateVideoFiles(): Promise<boolean> {
    if (this.post.instagramAccountsOnly()) {
      const isSingleVideoCarousel =
        this.post.files.length === 1 &&
        this.post.files[0].type === OutboxFileType.Video &&
        this.post.files[0].mediaCategory !== MediaCategory.Reel &&
        this.post.files[0].mediaCategory !== MediaCategory.Story;

      if (isSingleVideoCarousel) {
        await this.showInstagramVideoUpdatesInfoModal();
        this.post.files[0].mediaCategory = MediaCategory.Reel;
      }
    }

    const promises = this.post.videoFiles.map((file) =>
      this.fileUploaderService.validateAndConvertVideo(
        file,
        this.post.mediaRestrictions
      )
    );

    const results = await Promise.all(promises);

    let hasErrors = false;
    results.forEach((result, i) => {
      // update the outbox file with new props that might have changed during the validation procedure (url, size, etc.)
      this.post.videoFiles[i].url = result.file.url;
      this.post.videoFiles[i].handle = result.file.handle;
      this.post.videoFiles[i].size = result.file.size;
      this.post.videoFiles[i].mimetype = result.file.mimetype;
      this.post.videoFiles[i].filestackFile = {
        ...this.post.videoFiles[i].filestackFile,
        ...result.file
      };

      if (result.errors) {
        hasErrors = true;
      }
    });

    return !hasErrors;
  }

  illegalCharsRemovedWithWarning(): boolean {
    const illegalChars = /[\u000B]/g;
    if (illegalChars.test(this.post.text)) {
      const modal = this.modal.open(this.postInvalidCharacters, {
        windowClass: 'modal-vertical'
      });
      // await modal.result;
      this.post.text = this.post.text.replace(illegalChars, '');

      return true;
    }

    return false;
  }

  async showPostPreview() {
    if (this.post.validity.isValid) {
      const modal = this.modal.open(
        PublisherSocialNetworkPreviewModalComponent,
        {
          centered: true
        }
      );
      modal.componentInstance.post = this.post;
      try {
        await modal.result;
        await this.publish();
      } catch (e) {}
    }
  }

  async fileUploadSuccess(files: FileUploadFile[]): Promise<void> {
    try {
      if (!Array.isArray(files)) {
        throw new Error(
          `Value for 'publisher file uploa files' not in expected format.`
        );
      }

      let addToAll = false;
      OutboxPublisher.setFileType(files[0]);
      const isImage = (files[0] as any).type === OutboxFileType.Image;

      if (
        this.post.isSplit &&
        this.post.splitPostAccount &&
        !this.post.splitPostAccount.isInstagram() &&
        !this.post.hasVideoAttachedToAnyPost() &&
        isImage
      ) {
        const confirmationModal = this.modal.open(ConfirmationModalComponent, {
          windowClass: 'orlo-modal'
        });
        confirmationModal.componentInstance.icon = 'ssi ssi-paper-clip';
        confirmationModal.componentInstance.title = 'Attach to all?';
        confirmationModal.componentInstance.info = `Would you like to attach this image to all your split posts`;
        confirmationModal.componentInstance.cancelButton = 'No thanks';
        confirmationModal.componentInstance.confirmButton = `Yes please`;
        addToAll = await confirmationModal.result;
      }

      files.forEach((file) => {
        this.post.addFile(file, {}, addToAll);
      });

      const fileWithCustomPostText = files.find((file) =>
        get(file, 'customSource.metadata.outbox_post.text')
      );

      const fileWithInternalNote = files.find((file) =>
        get(file, 'customSource.metadata.outbox_post.note')
      );

      if (fileWithCustomPostText || fileWithInternalNote) {
        const postText: string = get(
          fileWithCustomPostText,
          'customSource.metadata.outbox_post.text'
        );
        const postTextNote: string = get(
          fileWithInternalNote,
          'customSource.metadata.outbox_post.note'
        );

        const modal = this.modal.open(SuggestedTextModalComponent, {
          centered: true,
          windowClass:
            postTextNote && !postText
              ? 'thin rounded-corners-15'
              : 'rounded-corners-15'
        });
        modal.componentInstance.suggestedText = postText ? postText : '';
        modal.componentInstance.mediaNote = postTextNote ? postTextNote : '';
        const result: SuggestedTextModalResult = await modal.result;
        if (result.overwrite) {
          this.post.text = postText;
        } else if (result.addToPost) {
          this.post.text = this.post.text.concat(' ', postText);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async showInstagramVideoUpdatesInfoModal(): Promise<void> {
    const preventConfirmation = this.storageService.get(
      'orlo-prevent-instagram-updates-info-confirmation'
    );
    if (preventConfirmation) {
      return;
    }

    const confirmationModal = this.modal.open(ConfirmationModalComponent, {
      windowClass: 'orlo-modal'
    });
    // confirmationModal.componentInstance.icon = 'ssi ssi-paper-clip';
    confirmationModal.componentInstance.negativeConfirmation = true;
    confirmationModal.componentInstance.title =
      'Instagram Updates to Video Posts';
    confirmationModal.componentInstance.info = `All new single video posts under fifteen minutes will now be shared as reels on Instagram. Ensure your video meets the sizing and formatting criteria.`;
    confirmationModal.componentInstance.cancelButton = `Don't show again`;
    confirmationModal.componentInstance.confirmButton = `Okay thanks!`;
    const allowConfirmation = await confirmationModal.result;

    if (!allowConfirmation) {
      this.storageService.set(
        'orlo-prevent-instagram-updates-info-confirmation',
        true
      );
    }
  }

  fileUploadError(err) {
    console.error(err);
    const message =
      err.message ||
      this.translate.instant(
        'SORRY_BUT_WE_COULD_NOT_UPLOAD_THE_FILE_YOU_SELECTED_PLEASE_TRY_AGAIN_OR_CONTACT_SUPPORT_IF_THE_PROBLEM_CONTINUES'
      );
    this.popup.alert({
      title: this.translate.instant('FILE_UPLOAD_FAILED'),
      message
    });
  }

  private async setupPost({
    isActive,
    create,
    edit,
    draft,
    stage,
    disable
  }: PublisherActiveParams) {
    if (this.isActive === isActive) {
      return;
    }

    this.isActive = isActive;
    if (!this.isActive) {
      return;
    }

    this.disable = disable;

    this.stage = Stage.Landing;
    if ((create && create.quoteRetweet) || (create && create.shareId)) {
      this.publisherActive.next({ isActive: false });
    }

    const [
      accounts,
      campaigns,
      authUser,
      companyConfig,
      blockingWords
    ] = await Promise.all([
      this.accountModel.findAccounts(this.workflowManager.getCurrentId()),
      this.campaignModel.findAll(),
      this.userModel.getAuthUser(),
      this.company.getConfig(),
      this.blockingWord.getBlockingWords()
    ]);

    const blockingWordsRaw = blockingWords.words.map(
      (blockingWord) => blockingWord.word
    );

    if (companyConfig.only_use_managed_files) {
      this.fileUploadSources = [FilestackSources.CustomSource];
    } else {
      this.fileUploadSources = undefined; // use defaults
    }
    // console.log(accounts);
    this.accounts = orderBy(accounts, ['account_type_id', 'name']).filter(
      (account) => !account.socialNetwork.inboundInitiated
    );

    this.allCampaigns = campaigns;

    this.campaigns = getOrderedOpenCampaigns(campaigns)
      .filter((campaign) => !campaign.parent_id)
      .reduce((build, parent) => {
        return [
          ...build,
          parent,
          ...getOrderedOpenCampaigns(parent.getChildren())
        ];
      }, []);

    this.post = new OutboxPublisher(
      this.accounts,
      authUser,
      companyConfig.use_utm_link_tracking,
      companyConfig.use_link_shortening,
      blockingWordsRaw,
      { replyToSocialId: create ? create.replyToSocialId : undefined },
      true
    );

    if (create) {
      if (!create.copy) {
        if (create.accounts) {
          this.post.accounts = [...create.accounts];
        }
        this.post.text = create.text;
        if (create.schedules) {
          create.schedules.forEach((date) => {
            this.post.addSchedule(date);
          });
        }

        if (create.activeCampaignID && Array.isArray(this.campaigns)) {
          this.post.campaign = this.campaigns.find((item) => {
            const id = parseInt(item.id as any, 10);
            const passedId = parseInt(create.activeCampaignID as any, 10);
            return id === passedId;
          });
        }

        if (create.files) {
          console.log('create.files: ', create.files);
          const filestackClient = await this.filestack.getClient();
          const filesAdded = create.files.map(async (file) => {
            // copy images across to filestack if added from the content generator etc
            if (!file.mimetype) {
              file = await filestackClient.storeURL(file.url);
              console.error('create.files: upload (filestack): Size'); // only for tracksjs
            }
            this.post.addFile({
              url: file.url,
              type: OutboxFileType.Image // TODO - handle videos
            });
          });
          await Promise.all(filesAdded);
        }
        if (create.quoteRetweet) {
          const modal = this.modal.open(QuoteRetweetModalComponent, {
            windowClass: 'modal-vertical orlo-modal-dark'
          });
          this.post.quoteRetweetUrl = create.quoteRetweet;
          modal.componentInstance.post = this.post;
        }
        if (create.shareId) {
          const modal = this.modal.open(FacebookShareModalComponent, {
            windowClass: 'modal-vertical'
          });
          this.post.shareId = create.shareId;
          modal.componentInstance.post = this.post;
        }
      } else {
        const copyMessage = await create.copy.toOutboxPublisherMessageFormat();

        const filesUrls = Array.isArray(create.copy.outbox_files)
          ? create.copy.outbox_files.map((f) => f.public_url)
          : [];
        const videoGifUrls = await OutboxPublisher.getVideoGifUrls(filesUrls);

        const originalPost = new OutboxPublisher(
          this.accounts,
          authUser,
          companyConfig.use_utm_link_tracking,
          companyConfig.use_link_shortening,
          blockingWordsRaw,
          { edit: copyMessage, videoGifUrls }
        );
        this.post.accounts = [...originalPost.accounts];
        this.post.text = originalPost.text;
        this.post.allMentions = originalPost.allMentions;
        this.post.deserializeAttachments(copyMessage, videoGifUrls);
        this.post.album.name = originalPost.album.name;
        this.post.requiresValidation = originalPost.requiresValidation;
        this.post.campaign = originalPost.campaign;
        this.post.targeting.Facebook = {
          ...originalPost.targeting.Facebook
        };
        this.post.targeting.LinkedIn = {
          ...originalPost.targeting.LinkedIn
        };
        this.post.targeting['Nextdoor Agency'] = {
          ...originalPost.targeting['Nextdoor Agency']
        };
        this.post.targeting['Nextdoor Agency US'] = {
          ...originalPost.targeting['Nextdoor Agency US']
        };

        if (Array.isArray(originalPost.tags)) {
          this.post.tags = originalPost.tags;
        }
      }
    } else if (edit && edit.post) {
      const editMessage = await edit.post.toOutboxPublisherMessageFormat();

      const filesUrls = Array.isArray(edit.post.outbox_files)
        ? edit.post.outbox_files.map((f) => f.public_url)
        : [];
      const videoGifUrls = await OutboxPublisher.getVideoGifUrls(filesUrls);

      this.post = new OutboxPublisher(
        this.accounts,
        authUser,
        companyConfig.use_utm_link_tracking,
        companyConfig.use_link_shortening,
        blockingWordsRaw,
        { edit: editMessage, videoGifUrls }
      );

      if (editMessage.youtube_visibility) {
        this.post.videoVisibility = editMessage.youtube_visibility;
      }
      if (edit.post.social_id) {
        this.post.edit.social_id = edit.post.social_id;
      }
    } else if (draft) {
      const videoWithTitleFilesUrls = [];
      draft.outbox_messages.forEach((m) => {
        m.attachments
          .filter((a) => a.type === OutboxMessageAttachmentType.VideoWithTitle)
          .forEach((a: OutboxMessageAttachmentVideoWithTitle) => {
            if (videoWithTitleFilesUrls.indexOf(a.url) === -1) {
              videoWithTitleFilesUrls.push(a.url);
            }
          });
      });

      const videoGifUrls = await OutboxPublisher.getVideoGifUrls(
        videoWithTitleFilesUrls
      );

      this.post.fromDraft(draft, videoGifUrls);
    }

    if (stage) {
      this.stage = stage;
    }
  }
}
