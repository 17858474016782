import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { catchError, map } from 'rxjs/operators';
import angular from 'angular';
import moment from 'moment';

import {
  AccountModel,
  Outbox,
  socialNetworkSettings
} from '@ui-resources-angular';
import { CountryFinderService } from '../../../../common/services/country-finder/country-finder.service';
import { ApiService } from '../../../../common/services/api';
import { HighchartsHelperService } from '../../../../common/services/highcharts-helper/highcharts-helper.service';
import { ReportSpreadsheetTransformersService } from '../../../../common/services/report-spreadsheet-transformers/report-spreadsheet-transformers.service';
import {
  Campaign,
  CampaignsService
} from '../../../../common/services/api/campaigns';
import { WorkflowManagerService } from '../../../../common/services/workflow-manager/workflow-manager.service';
import { networks } from '../../../../common/constants';
import { AccountTypeIdNameMap } from '../../../../common/enums';

export async function workflowAccountsResolveFn(
  accountModel: AccountModel,
  workflowManager: WorkflowManagerService
) {
  const accounts = await accountModel.findAccounts(
    workflowManager.getCurrentId()
  );
  return accounts;
}
export interface ReportData {
  postsData: any;
  mapData: any;
  clicksData: any;
  engagementData: any;
}
export interface EngagementLocationResponse {
  city: string;
  count: number;
  country_code: string;
  lat: string;
  lng: string;
}

export interface TopPostLink {
  campaign_id: string;
  clicks: string;
  clicks_es: string;
  created_at: string;
  id: string;
  original_url: string;
  outbox_id: string;
}

export interface AccountTypeStats {
  account_type_id: number;
  count_accounts: number;
  count_posts: number;
  sum_clicks: number;
  sum_likes: number;
  sum_shares: number;
  sum_comments: number;
  sum_impressions: number;
  sum_reach: number;
  // sum_reactions: number;
}
export interface OutboxStatsResponse {
  account_type_stats: AccountTypeStats[];
  totals: {
    count_accounts: number;
    count_posts: number;
    sum_clicks: number;
    sum_likes: number;
    sum_shares: number;
    sum_comments: number;
    sum_impressions: number;
    sum_reach: number;
  };
}
export interface InboundResponse {
  inbound: {
    count: number;
    sentiment: {
      positive: number;
      semi_positive: number;
      neutral: number;
      semi_negative: number;
      negative: number;
    };
    negative: number;
    neutral: number;
    positive: number;
    semi_negative: number;
    semi_positive: number;
    source: {
      by_account_type: {
        [networkName: string]: number;
      };
    };
    users_engaged: number;
  };
}

export const groupByParams = {
  datetime: 'datetime',
  // geohash: 'geohash',
  countryCity: 'country,city',
  outbox: 'outbox',
  account_type: 'account_type'
};

export interface FilterPeriod {
  start: string;
  end: string;
}

@Injectable()
export class CampaignAnalyticsService {
  constructor(
    private api: ApiService,
    private countryFinder: CountryFinderService,
    private reportSpreadsheetTransformers: ReportSpreadsheetTransformersService
  ) {}

  async getOutboxStats(
    campaignIds: string[],
    filterPeriod: FilterPeriod,
    includeDeleted: boolean,
    include_tags: string[],
    exclude_tags: string[]
  ) {
    const endpoint = `${this.api.url}/campaign/campaignOutboxStats`;
    const reportParams = {
      campaign_ids: campaignIds,
      start: filterPeriod.start,
      end: filterPeriod.end,
      include_deleted: includeDeleted,
      include_tags,
      exclude_tags
    };

    return this.api
      .post(endpoint, reportParams)
      .pipe(
        map((response: OutboxStatsResponse) => {
          response['pieReachData'] = response.account_type_stats.map(
            (accStat) => {
              const account =
                socialNetworkSettings[
                  AccountTypeIdNameMap[accStat.account_type_id]
                ];
              return {
                name: accStat.account_type_id,
                y: accStat.sum_reach,
                color: account ? account.brand.color : '#eee'
              };
            }
          );
          const getEngagementRate = (data) => {
            const res =
              (
                ((Number(data.sum_likes) +
                  Number(data.sum_shares) +
                  Number(data.sum_comments) +
                  Number(data.sum_clicks)) /
                  Number(data.sum_impressions)) *
                100
              ).toFixed(2) + '%';
            return res;
          };
          response['perNetwork'] = {
            ...response.account_type_stats.reduce(
              (r, { account_type_id, ...obj }) => (
                (r[account_type_id] = {
                  ...obj,
                  engagementRate: getEngagementRate(obj)
                }),
                r
              ),
              {}
            ),
            all: {
              ...response.totals,
              engagementRate: getEngagementRate(response.totals)
            }
          };
          response['networksData'] = response.account_type_stats.map(
            (networkData) => {
              const network =
                networks[AccountTypeIdNameMap[networkData.account_type_id]];
              return {
                ...networkData,
                name: network
                  ? network.name
                  : AccountTypeIdNameMap[networkData.account_type_id],
                icon: network ? network.icon : 'ssi ssi-user-small',
                label: network
                  ? network.label
                  : AccountTypeIdNameMap[networkData.account_type_id],
                color: network ? network.color : '#eee'
              };
            }
          );
          return response;
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }

  async getInboundStats(
    campaignIds: string[],
    filterPeriod: FilterPeriod,
    include_tags: string[],
    exclude_tags: string[]
  ) {
    const endpoint = `${this.api.url}/campaign/inbound`;

    const reportParams = {
      'campaign_ids[]': campaignIds,
      start: filterPeriod.start,
      end: filterPeriod.end,
      include_tags,
      exclude_tags
    };

    return this.api
      .get(endpoint, { params: reportParams })
      .pipe(
        map((response: InboundResponse) => {
          if (Object.keys(response.inbound.sentiment).length === 0) {
            response.inbound.sentiment = {
              positive: 0,
              semi_positive: 0,
              neutral: 0,
              semi_negative: 0,
              negative: 0
            };
          }

          response['engagementSourcesPie'] = Object.entries(
            response.inbound.source.by_account_type
          ).map(([source, amount]) => {
            const network = Object.entries(socialNetworkSettings).find(
              ([, networkSettings]) => networkSettings.publishKey === source
            );
            if (network) {
              const [name, socialNetwork] = network;
              return { name, color: socialNetwork.brand.color, y: amount };
            }
            return { name: source, color: '#eee', y: amount };
          });

          return response;
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }

  async getMapData(
    campaignIds: string[],
    filterPeriod: FilterPeriod,
    include_tags: string[],
    exclude_tags: string[]
  ) {
    const endpoint = `${this.api.url}/campaign/engagementLocation_v2`;
    const reportParams = {
      campaign_ids: campaignIds,
      start: filterPeriod.start,
      end: filterPeriod.end,
      include_tags,
      exclude_tags
    };

    return this.api
      .post(endpoint, reportParams)
      .pipe(
        map((response: EngagementLocationResponse[]) => {
          const locationsData: {
            locations: EngagementLocationResponse[];
            mapMarkers: { lat: number; lng: number }[];
            clicksByLocation: { city: string; count: number; country: any }[];
          } = {
            locations: [],
            mapMarkers: [],
            clicksByLocation: []
          };
          locationsData.locations = response;
          locationsData.mapMarkers = [];
          response.forEach((location) => {
            for (let i = 0; i < location.count; i++) {
              locationsData.mapMarkers.push({
                lat: parseFloat(location.lat) + i / 100000,
                lng: parseFloat(location.lng)
              });
            }
          });

          const capitalizeFirstLetter = (str: string) =>
            str.charAt(0).toUpperCase() + str.slice(1);

          locationsData.clicksByLocation = response
            .filter((location) => location.country_code)
            .map((location) => {
              return {
                count: location.count,
                city: capitalizeFirstLetter(location.city.toLowerCase()),
                country: this.countryFinder.getCountryFromCode(
                  location.country_code
                )
              };
            })
            .filter((location) => location.country);

          return locationsData;
        }),
        catchError((e) => this.api.mapError(e, endpoint))
      )
      .toPromise();
  }

  /**
   * Create the Spreadsheet in frontend to be downloaded
   * @param report report data to build the report with
   * @param campaign campaign data to be used on the spreadsheet
   * @param filterPeriod filter date to be shown in the spreadsheet
   */
  createSpreadsheetFromReport(
    filterPeriod: FilterPeriod,
    report: ReportData,
    campaign: Campaign,
    topPosts: Outbox[]
  ) {
    report.postsData.engagementRate =
      (
        ((report.postsData.totals.sum_likes +
          report.postsData.totals.sum_shares +
          report.postsData.totals.sum_comments +
          report.postsData.totals.sum_clicks) /
          report.postsData.totals.sum_impressions) *
        100
      ).toFixed(2) + '%';
    return [
      () => {
        return {
          name: 'Overview',
          rows: [
            ['Campaign - ' + campaign.name],
            [],
            [
              'Report start',
              'Report end',
              'Days old',
              'Total posts',
              'Total clicks',
              'Total reach',
              'Total impressions',
              'Total reposts / shares',
              'Total likes',
              'Total comments',
              'Engagement rate'
            ],
            [
              moment(filterPeriod.start).format('MM/DD/YY HH:mm:ss'),
              moment(filterPeriod.end).format('MM/DD/YY HH:mm:ss'),
              campaign.age_days,
              report.postsData.totals.count_posts,
              report.postsData.totals.sum_clicks,
              report.postsData.totals.sum_reach,
              report.postsData.totals.sum_impressions,
              report.postsData.totals.sum_shares,
              report.postsData.totals.sum_likes,
              report.postsData.totals.sum_comments,
              report.postsData.engagementRate
            ]
          ]
        };
      },
      () => {
        return {
          name: 'Link clicks by date',
          rows: [
            ['Campaign link clicks by date'],
            [],
            ...this.reportSpreadsheetTransformers.timeSeries([
              { header: 'Clicks', data: report.clicksData.response.by_datetime }
            ])
          ]
        };
      },
      () => {
        return {
          name: 'Link clicks by location',
          rows: [
            ['Region', 'Sub-region', 'Country', 'City', 'Total clicks'],
            [],
            ...report.mapData.clicksByLocation.map((location) => {
              return [
                location.country.region,
                location.country.subregion,
                location.country.name.common,
                location.city,
                location.count
              ];
            })
          ]
        };
      },
      () => {
        return {
          name: 'Top posts',
          rows: [
            ['Campaign - Top posts'],
            [],
            ['Top posts by clicks'],
            ...this.reportSpreadsheetTransformers.outboxPosts2(topPosts),
            []
            // ['Top posts by reach'],
            // ...this.reportSpreadsheetTransformers.outboxPosts(
            //   report.postsData.top_posts_by_reach
            // ),
            // [],
          ]
        };
      }
    ].map((fn) => fn());
  }

  // getNetworkSpecificLinkClicks(accountTypeId: string) {
  //   const reportParamsNew = {
  //     since: filterPeriod.start,
  //     until: filterPeriod.end
  //   };
  //   this.api.post(`${this.api.url}/stats/eslinkclicks`, {
  //     ...reportParamsNew,
  //     group_by: Object.values(groupByParams)
  //   });
  // }
}
