<div class="spacer-bottom row">
  <div class="col-xs-6">
    <span class="upload-subtitles-modal-header">{{selectedLocale.locale ? 'Change' : 'Upload'}} Captions File</span>
  </div>
</div>

<div class="upload-subtitles-modal-content">
  <div class="col-xs-6">
    <a 
    class="upload-subtitles"
    [class.uploaded]="subtitleFilename"
    ssiFileUploader
    [accept]="subtitleMimeType"
    [maxFiles]="1"
    (uploadStart)="fileUploadStarted()"
    (uploadFinally)="fileUploadComplete()"
    (uploadSuccess)="fileUploadSuccess($event)"
    (uploadError)="fileUploadError($event)">
    <i class="ssi {{ subtitleFilename ? 'ssi-correctedit' : 'ssi-plus'}}"></i> {{subtitleFilename ? subtitleFilename : 'Upload file'}}
    </a>
  </div>
  <div class="col-xs-6">
    <div *ngIf="!toggleLanguageSearch" class="language-select" [class.selected]="selectedLocale.locale" (click)="toggleLanguageSearch = !toggleLanguageSearch">
      {{selectedLocale.displayName}}
      <i class="ssi ssi-arrow-down-correct"></i>
    </div>
    <div *ngIf="toggleLanguageSearch" class="language-search">
      <input
        class="form-control"
        [(ngModel)]="searchLanguage"
        [placeholder]="'Type your language'"
        type="text" />
      <ssi-list-select
        class="options-light options-lg option-circles-hidden"
        [(ngModel)]="selectedLocale"
        (ngModelChange)="toggleLanguageSearch = !toggleLanguageSearch"
        [multiple]="false"
        [allowUnselect]="false">
        <ssi-list-select-option
          *ngFor="let language of locales | filterByString: searchLanguage | sortBy: 'displayName'"
          [value]="language">
          {{ language.displayName }}
          <i class="ssi ssi-tick"></i>
        </ssi-list-select-option>
      </ssi-list-select>
    </div>
  </div>

</div>

<div class="row spacer-top">
  <div class="col-xs-6">
  </div>
  <div class="col-xs-6 text-right">
    <button
      class="btn btn-text"
      (click)="activeModal.close(null)">
      Cancel
    </button>
    <button
      class="btn btn-text"
      (click)="save()"
      [disabled]="!subtitleFilename && !selectedLocale">
     Save
    </button>
  </div>
</div>
