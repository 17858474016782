<ul
  class="dropdown-menu dropdown-menu-inbox"
  *ngIf="choiceLoading || choices?.length > 0"
  [style.top.px]="position?.top"
  [style.left.px]="position?.left">
  <li *ngIf="choiceLoading"><div class="loading"></div></li>
  <li
    *ngFor="let choice of choices; trackBy:trackById"
    [class.active]="activeChoice === choice">
    <a
      href="javascript:;"
      (click)="selectChoice.next(choice)">
      <img *ngIf="choice.image_url !== null; else useDefault" [src]="choice.image_url">
      <ng-template #useDefault>
        <i class="ssi ssi-fw ssi-user-minimal" *ngIf="(choice.id | slice:0:13) === 'urn:li:person'" style="margin-right: 2px;"></i>
        <i class="ssi ssi-fw ssi-linkedin-stylised" *ngIf="(choice.id | slice:0:19) === 'urn:li:organization'" style="margin-right: 2px;"></i>
      </ng-template>
      {{ choice.name }} @{{ choice.username }}
      <i class="ssi ssi-verified-badge text-primary" *ngIf="choice.verified"></i>
      <i class="ssi ssi-user-minimal ssi-icon-right" *ngIf="(choice.id | slice:0:13) === 'urn:li:person'"></i>
      <i class="ssi ssi-linkedin-stylised ssi-icon-right" *ngIf="(choice.id |  slice:0:19) === 'urn:li:organization'"></i>
    </a>
  </li>
</ul>