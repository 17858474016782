<div class="post"
     [ngClass]="{objective: postType === 'objective','post-no-media': !media}">
    <div class="post-side">
        <span class="post-soc">
            <img [src]="postData.author.avatar">
        </span>
    </div>
    <div class="post-head">
        <div class="post-head-soc">
            <i class="post-head-soc-icon"
               [ngClass]="postData.socialMediaAccount.icon"></i>
            <p class="post-head-soc-name">{{postData.socialMediaAccount.displayName}}</p>
        </div>
        <ul class="post-head-info">
            <li *ngIf="postData.socialMediaAccount.userName">
                @{{postData.socialMediaAccount.userName}}
            </li>
            <li>{{postData.createdDate}}</li>
            <li>{{postData.createdTime}}</li>
            <li *ngIf="post.author && post.author.id !== '14'">
                <i class="ssi ssi-person-outline"></i>
                <p>{{postData.author.fullName}}</p>
            </li>
            <li *ngIf="post.author && post.author.id === '14'">
                <i class="ssi ssi-posted-natively"></i>
                <p>Natively posted</p>
            </li>
        </ul>
    </div>
    <div class="post-body">
        <p [innerHTML]="post.text | linkify: {mentions: mentions}"></p>
    </div>
    <ul class="post-status"
        *ngIf="postType === 'objective'">
        <li [ssiTooltip]="'Share Count'"
            [tooltipAppendToBody]="true">
            <i class="ssi ssi-shares post-status-icon"></i>
            <p class="post-status-label">{{post.share_count}}</p>
        </li>
        <li [ssiTooltip]="'Comments'"
            [tooltipAppendToBody]="true">
            <i class="ssi ssi-status-bubble post-status-icon"></i>
            <p class="post-status-label">{{post.comment_count}}</p>
        </li>
        <li *ngIf="post.reach"
            [ssiTooltip]="'Organic Reach'"
            [tooltipAppendToBody]="true">
            <i class="fa fa-bullhorn post-status-icon"></i>
            <p class="post-status-label">{{post.reach}}</p>
        </li>
        <li [ssiTooltip]="'Organic Impressions'"
            [tooltipAppendToBody]="true">
            <i class="ssi ssi-view-eye post-status-icon"></i>
            <p class="post-status-label">{{post.impressions}}</p>
        </li>
        <li [ssiTooltip]="'Likes'"
            [tooltipAppendToBody]="true">
            <i class="ssi ssi-thumbs-up-like post-status-icon"></i>
            <p class="post-status-label">{{post.like_count}}</p>
        </li>
        <li [ssiTooltip]="'Clicks'"
            [tooltipAppendToBody]="true">
            <i class="ssi ssi-clicks post-status-icon"></i>
            <p class="post-status-label">{{post.clicks}}</p>
        </li>
        <li [ssiTooltip]="'Campaign name'"
            [tooltipAppendToBody]="true">
            <i class="ssi ssi-campaign post-status-icon"
               *ngIf="postData.campaign.name"></i>
            <p class="post-status-label">{{postData.campaign.name}}</p>
        </li>
    </ul>
    <div class="post-gallery"
         *ngIf="media"
         [ssiEnlargeImage]="media">
        <span *ngIf="!mediaStack"
              [style.background-image]="'url(' + media[0] + ')'"
              class="post-gallery-frame"></span>
        <span *ngIf="mediaStack">
            <span *ngFor="let image of media | slice:0:3 | reverse; let i=index"
                  [style.background-image]="'url(' + image + ')'"
                  class="post-gallery-frame post-gallery-frame-stack"></span>
        </span>
    </div>
    <div class="post-menu">
        <i class="ssi ssi-outbox-menu post-action"
           (mouseenter)="postMenu = true"></i>
    </div>
    <div class="post-menu-items"
         *ngIf="postMenu"
         (mouseleave)="postMenu = false">
        <i class="ssi ssi-delete text-danger"
           (click)="removePost()"
           [ssiTooltip]="'Delete'"
           [tooltipAppendToBody]="true"></i>
        <i *ngIf="['3','4','8','9'].includes(account.account_type_id)"
           class="ssi ssi-ad"
           (click)="boostPost()"
           [ssiTooltip]="'Boost Post'"
           [tooltipAppendToBody]="true"></i>
        <i
          *ngIf="post.social_id && !post.deleted_at && authUser?.hasAccountPermission(post.account_id, 'post_edit')"
          class="ssi ssi-correctedit"
          (click)="closeModal.emit()"
          [ssiTriggerPublish]="{
          edit: {post: post},
          disable: {
            previewPost: true,
            changeAccount: true,
            changeCampaign: true,
            changeTargeting: true,
            requestingValidation: true,
            scheduling: true,
            editMedia: true
          }
          }"
          [ssiTooltip]="'Edit Live Post'"
          [tooltipAppendToBody]="true"></i>
        <i *ngIf="!post.social_id"
          class="ssi ssi-correctedit"
          (click)="closeModal.emit()"
          [ssiTriggerPublish]="{edit: {post: post}}"
          [ssiTooltip]="'Edit Post'"
          [tooltipAppendToBody]="true"></i>
        <i class="ssi ssi-campaign"
           (click)="changeCampaignModal()"
           [ssiTooltip]="post.campaign_id ? 'Change Campaign' : 'Add Campaign'"
           [tooltipAppendToBody]="true"></i>
        <i class="ssi ssi-copy-paste"
           (click)="closeModal.emit()"
           [ssiTriggerPublish]="{create: {copy: post}}"
           [ssiTooltip]="'Copy Post'"
           [tooltipAppendToBody]="true"></i>
        <a
          *ngIf="post.social_id && !!account.socialNetwork.outbox.threadReplies"
          uiSref="auth.twitterThreads"
          [uiParams]="{outboxId: post.id }"
          class="ssi ssi-plus"
          [ssiTooltip]="'Add to Thread'"
          [tooltipAppendToBody]="true"></a>
        <a *ngIf="post.externalLink"
           href="{{post.externalLink}}"
           target="_blank"
           class="ssi ssi-view-natively"
           [ssiTooltip]="'View Post Natively'"
           [tooltipAppendToBody]="true"></a>

        <i class="ssi ssi-outbox-menu useless-icon"></i>
    </div>
</div>