
<div class="report-action-bar">
  <div class="report-warning" *ngIf="warningBanner">
    <div class="report-warning-inner">
      <i class="ssi ssi-warning"></i>
      <div>Some filters have not been applied to this report because the associated accounts/streams have been deleted. Press “Save report” and create a new report if you do not want to see this message again.</div>
      <i class="ssi ssi-delete-close" (click)="warningBanner = false"></i>
    </div>
  </div>
  <header class="report-action-bar-header">
    <div class="report-action-bar-container">
      <div class="report-action-bar-header-left">
        <p class="report-header-name">{{report?.name}}</p>
      </div>
      <div class="report-action-bar-action-wrap" ngbDropdown>
        <button ngbDropdownToggle class="report-action-bar-action">
          <i class="ssi ssi-arrow-down-new"></i>
        </button>
        <div class="report-action-bar-dropdown-wrap" ngbDropdownMenu>
          <ul class="report-action-bar-dropdown">
            <li class="report-action-bar-dropdown-heading">
              <p>Public</p>
            </li>
            <ng-container *ngFor="let report of publicReports">
              <li
                class="report-action-bar-dropdown-item"
                uiSref="auth.insights.reports.view"
                [uiParams]="{id: report.id}"
                [uiOptions]="{inherit: false}"
              >
                <p>{{report.name}}</p>
              </li>
            </ng-container>
            <li class="report-action-bar-dropdown-heading">
              <p>Private</p>
            </li>
            <ng-container *ngFor="let report of privateReports">
              <li
                class="report-action-bar-dropdown-item"
                uiSref="auth.insights.reports.view"
                [uiParams]="{id: report.id}"
                [uiOptions]="{inherit: false}"
              >
                <p>{{report.name}}</p>
              </li>
            </ng-container>
          </ul>
          <ul class="report-action-bar-dropdown-footer">
            <li
              class="report-action-bar-dropdown-footer-item"
              uiSref="auth.insights.reports.create"
            >
              <p><i class="ssi ssi-add-small"></i> Custom Report</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="report-action-bar-header-right">
        <button
          *ngIf="!browserlessMode"
          class="report-action-bar-button"
          (click)="onSaveClick.emit()"
        >
          <span>Save report</span>
        </button>
      </div>
    </div>
  </header>

  <div class="report-action-bar-actions">
    <div class="report-action-bar-container report-action-bar-container-wrap">
      <div class="report-action-bar-actions-left">
        <ssi-tags-input
          [tags]="tags"
          [condition]="tagsCondition"
          [disabled]="tags.length"
          (onTagsChange)="onKeywordFilter.emit($event)"
        ></ssi-tags-input>

        <!-- <ul class="report-actions-list">
          <li class="report-actions-list-action">
            <button (click)="onFiltersClick.emit()"
                    class="filters-toggle-btn">
              <span *ngIf="activeFilterTotal" class="report-actions-list-action-badge">
                {{activeFilterTotal}}
              </span>
              <i class="ssi ssi-filter-trimmed"></i> Filter
            </button>
          </li>
        </ul> -->
      </div>

      <div class="report-action-bar-actions-right">
        <div class="report-actions">
          <ul class="report-actions-list">
            <li class="report-actions-list-action">
              <button (click)="refreshReport()">
                <i class="ssi ssi-refresh-to-check"></i> Refresh
              </button>
            </li>
            <!-- <li class="report-actions-list-action">
              <button (click)="onFiltersClick.emit()">
                <span *ngIf="activeFilters.length" class="report-actions-list-action-badge">
                  {{activeFilters.length}}
                </span>
                <i class="ssi ssi-filter-trimmed"></i> Filter
              </button>
            </li> -->

            <!-- <li class="report-actions-list-action dropdown" ngbDropdown>
              <button class="dropdown-toggle" ngbDropdownToggle>
                <i class="ssi ssi-date-preset"></i> {{date}}
              </button>
              <div class="dropdown-menu">
                <ssi-radio-list
                  [listItems]="dateOptions"
                  [value]="date"
                  (onValueChange)="dateSelected($event)"
                ></ssi-radio-list>
                <hr class="dropdown-menu-break">
                <p
                  class="dropdown-menu-item dropdown-menu-item-radio-list-inline"
                  (click)="onDateCustomClick.emit()"
                >
                  <i class="ssi ssi-addsmall dropdown-menu-item-icon dropdown-menu-item-icon-small"></i>
                  <span>Custom range</span>
                </p>
              </div>
            </li> -->
            <li class="report-actions-list-action"
                *ngIf="userOwnsReport">
              <button (click)="onAddWidgetClick.emit()">
                <i class="ssi ssi-add-widget"></i> Add widget
              </button>
            </li>
            <li *ngIf="!templateMode" class="report-actions-list-action">
              <button (click)="onCopyReportClick.emit()">
                <i class="ssi ssi-copy-paste-correct"></i>Copy report
              </button>
            </li>
            <!-- <li class="report-actions-list-action">
              <button
                (click)="onShareClick.emit()"
                ssiTooltip
                [tooltipTemplate]="actionLinkShare"
                tooltipWindowClass="tooltip-chunky-big"
              >
                <i class="ssi ssi-share-small"></i> Share
              </button>
            </li> -->
            <li *ngIf="reportView === ReportView.Graph" class="report-actions-list-action">
              <button
                (click)="onExportClick.emit()"
                [disabled]="!widgetsLoaded || pdfExportDisabled"
                [ssiTooltip]="'PLEASE_WAIT_UNTIL_REPORT_HAS_FINISHED_LOADING_BEFORE_EXPORTING' | translate"
                [tooltipDisabled]="widgetsLoaded"
                tooltipWindowClass="tooltip-chunky-big"
              >
                <i class="ssi ssi-print" *ngIf="!pdfExportDisabled"></i>
                <i
                  class="ssi ssi-loading ssi-spin"
                  *ngIf="pdfExportDisabled"
                ></i>
                Export
              </button>
            </li>
            <li *ngIf="userOwnsReport"
                class="report-actions-list-action dropdown"
                ngbDropdown>
              <button class="dropdown-toggle"
                      ngbDropdownToggle>
                <i class="ssi ssi-more-actions"></i> More
              </button>
              <ul class="dropdown-menu dropdown-menu-md">
                <!-- <li class="dropdown-menu-item" (click)="onCopyReportClick.emit()">
                  <i class="ssi ssi-copy-paste-correct dropdown-menu-item-icon"></i>
                  <span>Copy report</span>
                </li> -->
                <!-- <li class="dropdown-menu-item" *ngIf="userOwnsReport" (click)="onAddWidgetClick.emit()">
                  <i class="ssi ssi-add-widget dropdown-menu-item-icon"></i>
                  <span>Add widget</span>
                </li> -->
                <li class="dropdown-menu-item"
                    *ngIf="userOwnsReport"
                    (click)="onEditReportClick.emit()">
                  <i class="ssi ssi-correct-edit-small dropdown-menu-item-icon"></i>
                  <span>Edit report</span>
                </li>
                <li
                  class="dropdown-menu-item"
                  *ngIf="userOwnsReport && schedulesAutomatedReportsFeature"
                  (click)="openAutomateReportModal()"
                >
                  <i class="ssi ssi-small-schedule dropdown-menu-item-icon"></i>
                  <span>Automate Report</span>
                </li>
                <!-- <li
                  class="dropdown-menu-item"
                  *ngIf="report?.is_shared"
                  (click)="onShareClick.emit()"
                >
                  <i class="ssi ssi-add-link dropdown-menu-item-icon"></i>
                  <span>Share report link</span>
                </li> -->
                <!-- <li class="dropdown-menu-item" (click)="onSettingsClick.emit()">
                  <i class="ssi ssi-settings-cog dropdown-menu-item-icon"></i>
                  <span>Settings</span>
                </li> -->
                <ng-container *ngIf="userOwnsReport">
                  <li class="dropdown-menu-break-wrap">
                    <hr class="dropdown-menu-break">
                  </li>
                  <li class="dropdown-menu-item"
                      (click)="onDeleteClick.emit()">
                    <i class="ssi ssi-small-delete dropdown-menu-item-icon dropdown-menu-item-icon-red"></i>
                    <span>Delete report</span>
                  </li>
                </ng-container>
              </ul>
            </li>
          </ul>

          <ul class="report-actions-toggles">
            <li class="report-actions-toggle">
              <input type="radio"
                     id="graph"
                     value="graph"
                     name="reportView"
                     [(ngModel)]="reportView"
                     (ngModelChange)="onReportViewChange.emit(reportView)">
              <label for="graph">
                <i class="ssi ssi-report-toggle"></i>
                <span class="hidden">Graph</span>
              </label>
              <input type="radio"
                     id="comment"
                     value="comment"
                     name="reportView"
                     [(ngModel)]="reportView"
                     (ngModelChange)="onReportViewChange.emit(reportView)">
              <label for="comment">
                <i class="ssi ssi-post-results"></i>
                <span class="hidden">Comment</span>
              </label>
            </li>
            <li *ngIf="showPinButton"
                class="report-actions-button"
                [ngClass]="{'report-actions-button-active': pinned}">
              <button (click)="pinned = !pinned; onPinClick.emit(pinned)">
                <i class="ssi ssi-pin-correct"></i>
                <span class="hidden">Pin</span>
              </button>
            </li>
            <li class="report-actions-list-action">
              <button (click)="onFiltersClick.emit()"
                      class="filters-toggle-btn">
                <span *ngIf="activeFilterTotal > 0"
                      class="report-actions-list-action-badge">
                    {{activeFilterTotal}}
                </span>
                <i class="ssi ssi-filter-trimmed"></i> Filter
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
