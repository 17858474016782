import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';

import { Store } from '../store';
import { ApiService } from '../api.service';
import { Campaign } from './campaign.model';

export const sortCampaignsByName = (campaign1: Campaign, campaign2: Campaign) =>
  campaign1.name.toLowerCase().localeCompare(campaign2.name.toLowerCase());

@Injectable({ providedIn: 'root' })
export class CampaignsService {
  store = new Store<Campaign>(Campaign);
  endpoint = `${this.api.url}/campaign/index_v2`;

  constructor(protected api: ApiService) {}

  /**
   * @param {obj} opts
   * @param {boolean} opts.refreshStore
   * @returns Campaign array from store, if refreshStore isn't passed
   */
  async getAll(opts = { refreshStore: false }): Promise<Campaign[]> {
    if (this.store.value.length && !opts.refreshStore) {
      return Promise.resolve(this.store.value);
    }

    return this.api
      .get(this.endpoint)
      .pipe(
        map((response: Campaign[]) => {
          this.store.value = response;
          return this.store.value;
        }),
        catchError((e) => this.api.mapError(e, this.endpoint))
      )
      .toPromise();
  }

  /**
   * Get single campaign object by passing the ID
   * @param id Campaign id
   * @returns Single Campaign from store
   */
  async getById(id: string | number): Promise<Campaign> {
    await this.getAll();
    return this.store.find(id);
  }

  /**
   * Get all children campaigns by passign the campaign id
   * @param id Campaign id
   * @returns Array of Campaigns that belong to this campaign
   */
  async getChildren(id: string | number): Promise<Campaign[]> {
    const campaigns = await this.getAll();
    return campaigns.filter((c) => c.parent_id === id);
  }

  /**
   * Get parent campaign from the list of all campaigns by passign the ID
   * @param id Campaign id
   * @returns Single Campaign from store
   */
  async getParent(id: string | number): Promise<Campaign> {
    const campaigns = await this.getAll();
    return campaigns.find((c) => c.parent_id === id);
  }

  async getAllLive() {
    const campaigns = await this.getAll();
    return campaigns.filter((c) => !c.is_closed);
  }

  async getAllParents(): Promise<Campaign[]> {
    const campaigns = await this.getAll();
    return campaigns.filter((c) => !c.parent_id);
  }

  async getAllSortedByParent(): Promise<{
    live: Campaign[];
    completed: Campaign[];
  }> {
    const campaigns = await this.getAll();
    return this.sortByParent(campaigns);
  }

  sortByParent(
    campaigns: Campaign[]
  ): {
    live: Campaign[];
    completed: Campaign[];
  } {
    const parentsLive = campaigns
      .filter((campaign) => !campaign.parent_id && !campaign.is_closed)
      .sort(sortCampaignsByName);
    const parentsCompleted = campaigns
      .filter((campaign) => !campaign.parent_id && campaign.is_closed)
      .sort(sortCampaignsByName);

    const sortedByParentLive: Campaign[] = [];
    const sortedByParentCompleted: Campaign[] = [];

    parentsLive.map((liveCampaign) => {
      let children = campaigns.filter((c) => c.parent_id === liveCampaign.id);
      children = children.sort(sortCampaignsByName);
      sortedByParentLive.push(liveCampaign, ...children);
    });

    parentsCompleted.map((completedCampaign) => {
      let children = campaigns.filter(
        (c) => c.parent_id === completedCampaign.id
      );
      children = children.sort(sortCampaignsByName).filter((c) => c.is_closed);
      sortedByParentCompleted.push(completedCampaign, ...children);
    });

    return {
      live: sortedByParentLive,
      completed: sortedByParentCompleted
    };
  }

  createOrUpdate(campaign: Campaign): Promise<Campaign> {
    return this.api
      .post(this.endpoint, campaign)
      .pipe(
        map((response: Campaign) => {
          campaign.id ? this.store.update(response) : this.store.add(response);
          return response;
        }),
        catchError((e) => this.api.mapError(e, this.endpoint))
      )
      .toPromise();
  }

  delete(campaign: Campaign): Promise<any> {
    return this.api
      .delete(this.endpoint, { params: { id: campaign.id } })
      .pipe(
        map((response: any) => {
          this.store.remove(campaign.id);
          return response;
        }),
        catchError((e) => this.api.mapError(e, this.endpoint))
      )
      .toPromise();
  }
}
