import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  NgModule,
  NgModuleFactoryLoader,
  SystemJsNgModuleLoader,
  enableProdMode,
  Injector
} from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { UpgradeModule } from '@angular/upgrade/static';

import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
  TranslateService
} from '@ngx-translate/core';

import { UIResourcesModule } from '@ui-resources-angular';

import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

import {
  LocalStorageModule,
  LocalStorageService
} from 'angular-2-local-storage';

import { firebaseProject } from '../../library/constants/live-chat';

import { appInjector } from './app-injector';

import {
  DEFAULT_TRANSLATE_LANGUAGE,
  USER_LANGUAGE_STORAGE_KEY,
  googleMapsAPIKey
} from './common/constants';
import { ServicesModule } from './common/services/services.module';
import { MissingTranslationHandlerService } from './common/services/missing-translation-handler/missing-translation-handler.service';
import {
  DEFAULT_TRANSLATIONS,
  TranslateHttpLoaderService
} from './common/services/translate-http-loader/translate-http-loader.service';
import { NgUpgradeModule } from './common/ng-upgrade.module';
import './common/stylesheets';
import { ModulesModule } from './modules/modules.module';
import { EnvironmentType, ENVIRONMENT } from './environment';

import { AgmCoreModule } from '@agm/core';
import { ErrorHandlerService } from './common/services/error-handler/error-handler.service';
import { HotkeyModule } from 'angular2-hotkeys';

if (ENVIRONMENT === EnvironmentType.Production) {
  enableProdMode();
}

export function defaultTranslationsFactory(): any {
  return process.env.DEFAULT_TRANSLATIONS;
}

const imports = [
  BrowserModule,
  BrowserAnimationsModule,
  UpgradeModule,
  UIRouterUpgradeModule.forRoot(),
  NgUpgradeModule,
  UIResourcesModule.forRoot(),
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useClass: TranslateHttpLoaderService
    },
    missingTranslationHandler: {
      provide: MissingTranslationHandler,
      useClass: MissingTranslationHandlerService
    }
  }),
  HttpClientModule,
  AngularSvgIconModule.forRoot(),
  LocalStorageModule.withConfig(),
  ServicesModule.forRoot(),
  ModulesModule,
  HttpClientModule,
  AngularFireModule.initializeApp(
    firebaseProject.credentials.firebase,
    firebaseProject.name
  ),
  AngularFireAuthModule,
  AngularFirestoreModule,
  AngularFireDatabaseModule,
  AngularFireMessagingModule,
  AngularFireStorageModule,
  AgmCoreModule.forRoot({
    apiKey: googleMapsAPIKey
  }),
  HotkeyModule.forRoot()
  // ServiceWorkerModule.register(`/firebase-messaging-sw.js`),
  // ServiceWorkerModule.register('ngsw-worker.js', {
  //   enabled: ENVIRONMENT === EnvironmentType.Production
  // }),
];

@NgModule({
  imports,
  providers: [
    {
      provide: NgModuleFactoryLoader,
      useClass: SystemJsNgModuleLoader
    },
    {
      provide: DEFAULT_TRANSLATIONS,
      useFactory: defaultTranslationsFactory
    },
    {
      provide: '$auth',
      useFactory: ($injector: any) => $injector.get('$auth'),
      deps: ['$injector']
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerService,
      multi: true
    }
  ]
})
export class AppModule {
  constructor(
    translate: TranslateService,
    localStorage: LocalStorageService,
    private injector: Injector,
    private upgrade: UpgradeModule
  ) {
    translate.setDefaultLang(DEFAULT_TRANSLATE_LANGUAGE);
    const savedLanguage = localStorage.get<string>(USER_LANGUAGE_STORAGE_KEY);
    if (savedLanguage) {
      translate.use(savedLanguage);
    } else {
      translate.use(DEFAULT_TRANSLATE_LANGUAGE);
    }

    translate.onLangChange.subscribe(({ lang }) => {
      localStorage.set(USER_LANGUAGE_STORAGE_KEY, lang);
    });
  }

  ngDoBootstrap() {
    appInjector(this.injector);
  }
}
