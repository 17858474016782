import { Injectable } from '@angular/core';
import moment from 'moment';
import { ReportSpreadsheetTransformersService } from '../report-spreadsheet-transformers/report-spreadsheet-transformers.service';
import { API } from '@ui-resources-angular';
import { HighchartsHelperService } from '../highcharts-helper/highcharts-helper.service';
import { TIMEZONE_OFFSET } from '../../constants';
import angular from 'angular';

@Injectable()
export class MarketingAnalyticsService {
  constructor(
    private reportSpreadsheetTransformers: ReportSpreadsheetTransformersService,
    private api: API,
    private highchartsHelper: HighchartsHelperService
  ) {}

  createSpreadsheetExport(accountReport) {
    const ranges = {
      current:
        moment(accountReport.range.start).format('Do MMM YY') +
        ' - ' +
        moment(accountReport.range.end).format('Do MMM YY'),
      previous:
        moment(accountReport.range.compareTo.start).format('Do MMM YY') +
        ' - ' +
        moment(accountReport.range.compareTo.end).format('Do MMM YY')
    };

    return [
      () => {
        const accountTypeSection = [];
        Object.entries(accountReport.data.totals).forEach(
          ([accountType, totals]: [string, any]) => {
            ['current', 'previous'].forEach((period) => {
              accountTypeSection.push([
                totals.account.name +
                  ' (' +
                  totals.account.account_type_name +
                  ') ' +
                  ranges[period],
                totals[period].connections,
                totals[period].total_reach,
                totals[period].total_clicks,
                totals[period].messages_out,
                totals[period].engagement_rate
              ]);
            });
            accountTypeSection.push([]);
          }
        );

        return {
          name: 'Overview',
          rows: [
            ['Marketing Analytics - Overview'],
            [],
            ...this.reportSpreadsheetTransformers.accountsList(
              accountReport.accounts
            ),
            [],
            [
              '',
              'Connections',
              'Reach',
              'Link clicks',
              'Sent messages',
              'Engagement Rate'
            ],
            ...accountTypeSection
          ]
        };
      },
      () => {
        return {
          name: 'Link clicks',
          rows: [
            ['Marketing Analytics - Link Clicks'],
            [],
            ...this.reportSpreadsheetTransformers.timeSeries([
              {
                header: 'Clicks',
                data: accountReport.data.time_series.total_clicks.current.values
              }
            ])
          ]
        };
      },
      () => {
        return {
          name: 'Reach',
          rows: [
            ['Marketing Analytics - Reach'],
            [],
            ...this.reportSpreadsheetTransformers.timeSeries([
              {
                header: 'Reach',
                data: accountReport.data.time_series.total_reach.current.values
              }
            ])
          ]
        };
      },
      () => {
        return {
          name: 'Gender',
          rows: [
            ['Marketing Analytics - Gender'],
            [],
            ['', 'Male', 'Female'],
            [
              ranges.current,
              accountReport.allTotals.current.male_connections + '%',
              accountReport.allTotals.current.female_connections + '%'
            ],
            [
              ranges.previous,
              accountReport.allTotals.previous.male_connections + '%',
              accountReport.allTotals.previous.female_connections + '%'
            ]
          ]
        };
      },
      () => {
        const messages = Object.keys(
          accountReport.data.time_series.messages_in.current.values
        ).map((date) => {
          return [
            moment(new Date(date)).format('MM/DD/YY'),
            accountReport.data.time_series.messages_in.current.values[date],
            accountReport.data.time_series.messages_out.current.values[date]
          ];
        });

        return {
          name: 'Inbox',
          rows: [
            ['Marketing Analytics - Inbox'],
            [],
            ['Date', 'Messages In', 'Messages Out'],
            ...messages
          ]
        };
      },
      () => {
        return {
          name: 'Top posts',
          rows: [
            ['Marketing Analytics - Top posts'],
            [],
            ['Top posts by clicks'],
            ...this.reportSpreadsheetTransformers.outboxPosts(
              accountReport.data.top_posts.by_clicks
            ),
            [],
            ['Top posts by reach'],
            ...this.reportSpreadsheetTransformers.outboxPosts(
              accountReport.data.top_posts.by_reach
            ),
            [],
            ['Top posts by impressions'],
            ...this.reportSpreadsheetTransformers.outboxPosts(
              accountReport.data.top_posts.by_impressions
            )
          ]
        };
      }
    ]
      .map((fn) => fn())
      .filter(Boolean);
  }

  getTopPostTimes({
    accountIds,
    start,
    end,
    stats = ['clicks', 'shares', 'comments', 'likes'],
    includeEmptyValues = false
  }) {
    const WEIGHTS = Object.freeze({
      likes: 10,
      shares: 30,
      comments: 40,
      clicks: 20,
      click_es: 20
    });

    function iterateData(weekData, action) {
      Object.values(weekData).forEach((dayData) => {
        Object.entries(dayData).forEach(([hourKey, hourData]) => {
          dayData[hourKey] = action(hourData);
        });
      });
    }

    function calculateScore(weekData) {
      const clone = angular.copy(weekData);

      const keys = Object.keys(WEIGHTS);
      const sums = {};
      iterateData(clone, (hourData) => {
        keys.forEach((key) => {
          sums[key] = sums[key] || 0;
          sums[key] += hourData[key];
        });
        return hourData;
      });

      const scores = [];

      iterateData(clone, (hourData) => {
        hourData.score = 0;
        keys.forEach((key) => {
          hourData.score +=
            (sums[key] > 0 ? hourData[key] / sums[key] : 0) * WEIGHTS[key];
        });
        scores.push(hourData.score);
        return hourData;
      });

      const maxScore = Math.max(...scores);

      iterateData(clone, (hourData) => {
        const normalisedScore =
          (maxScore > 0 ? hourData.score / maxScore : 0) * 100;
        delete hourData.score;
        return {
          value: normalisedScore,
          data: {
            totals: hourData
          }
        };
      });

      return clone;
    }

    function aggregateHours(weekData) {
      const hours = {};
      const keys = Object.keys(WEIGHTS);
      Object.values(weekData).forEach((dayData) => {
        Object.entries(dayData).forEach(([hourKey, hourData]) => {
          hours[hourKey] = hours[hourKey] || {};
          keys.forEach((key) => {
            hours[hourKey][key] = hours[hourKey][key] || 0;
            hours[hourKey][key] += hourData[key];
          });
        });
      });
      return hours;
    }

    return this.api
      .post('outbox/hourOfDayAggregation_v2', {
        account_ids: accountIds,
        start,
        end,
        tz_offset: TIMEZONE_OFFSET,
        with: stats
      })
      .then(({ data }) => {
        const chartData = calculateScore(data);
        const aggregatedScores = calculateScore({ all: aggregateHours(data) })
          .all;

        const topHours = Object.entries(aggregatedScores).map(
          ([hour, { value }]: [string, { value: number }]) => {
            return {
              label: moment()
                .hours(+hour)
                .format('ha'),
              percent: value,
              hour: +hour
            };
          }
        );

        topHours.sort((a, b) => b.percent - a.percent);

        const chart = this.highchartsHelper.generateWeekSummaryHeatmapChart({
          chartData,
          includeEmptyValues,
          tooltipFormatter() {
            return `
            At <b>${this.series.xAxis.categories[this.point.x]}</b> on <b>${
              this.series.yAxis.categories[this.point.y]
            }</b>
            posts have a success rate of <b>${this.point.value}%</b>
            in comparison to the rest of the week<br><br>
            <div style="text-align: center">
              <b>${this.point.data.totals.comments}</b> comments,
              <b>${this.point.data.totals.shares}</b> shares,
              <b>${this.point.data.totals.clicks}</b> clicks,
              <b>${this.point.data.totals.likes}</b> likes
            </div>
          `;
          }
        });

        return { chart, topHours };
      });
  }
}
