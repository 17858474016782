<div class="report-header">
  <h1>{{report}}</h1>
  <div class="report-date-selector">
    <div class="control-selector"
         *ngIf="!toggleControls && canChangeDates"
         (click)="toggleControls = !toggleControls">
      <i class="ssi ssi-date-preset"></i>
    </div>
    <ssi-highchart-controls *ngIf="toggleControls"
                            (chartChangeDate)="reportChangeDate($event.timestamp)"
                            (chartChangeDateCustom)="reportChangeDateCustom()"
                            [now]="now"
                            [chartSearchDates]="reportSearchDates"
                            [chartDefaultStartDate]="reportDefaultStartDate"
                            [reportMode]="true"></ssi-highchart-controls>
  </div>
  <div class="report-name">
    <ng-container *ngIf="!dateRangesTemplate">
      <div class="report-date-range"
           (click)="onDateRangeClicked.emit()">{{reportStartDate | date:'d MMM yy'}} - {{reportEndDate | date:'d MMM yy'}}</div>
      <h2 *ngIf="name">{{name}}</h2>
    </ng-container>

    <ng-container *ngTemplateOutlet="dateRangesTemplate"></ng-container>
  </div>
</div>