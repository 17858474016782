import './activity.component.scss';

import {
  ActivityModel,
  AccountModel,
  ProfileModel,
  OutboxModel,
  Account,
  Activity,
  ActivityStatus,
  ActivityTags,
  CrmPerson,
  User,
  UserModel,
  ActivityThread,
  Conversation,
  API
} from '@ui-resources-angular';
import { uniqBy } from 'lodash-es';
import { WorkflowManagerService } from '../../../../angular/common/services/workflow-manager/workflow-manager.service';
import { PopupService } from '../../../../angular/common/services/popup/popup.service';
import { UserPreferencesService } from '../../../../angular/common/services/user-preferences/user-preferences.service';
import { CompanyService } from '../../../../angular/common/services/company/company.service';
import debounce from 'debounce-promise';
import { PredictedResponseService } from '../../../../angular/common/services/predicted-response/predicted-response.service';
import { format } from 'date-fns';
import {
  PUBLISHER_ACTIVE,
  PublisherActive
} from '../../../../angular/common/components/publisher/publisher-active';
import {
  InboxKeyboardShortcutsService,
  KeyboardShortcut
} from '../../../../angular/common/services/inbox-keyboard-shortcuts/inbox-keyboard-shortcuts.service';
import { SocialType } from '../../../../angular/common/enums';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';

import { Campaign, CampaignsService, Colleague } from '../../services/api';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '../../services/translation/translation.service';
import { AsyncTracker, AsyncTrackerFactory } from 'angular-async-tracker';
import { SocialPostCharactersRemainingPipe } from '../../pipes/social-post-characters-remaining/social-post-characters-remaining.pipe';
import { InboxMode } from '../../../modules/auth/inbox/inbox.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExportMessagesComponent } from '../export-messages/export-messages.component';
import { InboxAlertModalComponent } from '../inbox-alert-modal/inbox-alert-modal.component';
import { ProfileHelperService } from '../../services/profile-helper/profile-helper.service';

const DELETE_COMMENT_PERMISSION = 'delete-comment';
const REDACT_MESSAGES_PERMISSION = 'can_redact';

export const GA_EVENT_CATEGORY_INBOX = 'Inbox';
export const GA_EVENT_ACTION_MESSAGE_OPENED_TO_REPLY_SENT_TIME =
  'message opened to reply sent time';
export const GA_EVENT_ACTION_MESSAGE_OPENED_TO_REPLY_STARTED_TIME =
  'message opened to reply started time';
export const GA_EVENT_ACTION_REPLY_STARTED_TO_REPLY_SENT_TIME =
  'reply started to reply sent time';

export interface ActivityReplyUser {
  name: string;
  username: string;
  id: string;
  excluded: boolean;
}

@Component({
  selector: 'ssi-activity',
  templateUrl: './activity.component.html'
})
export class ActivityComponent implements OnInit, OnChanges {
  currentLanguage: string;
  workflowAccounts: Account[];

  reply: {
    tracker: AsyncTracker;
    text?: string;
    account?: Account;
    visible?: boolean;
    add_dm_reply_link?: boolean;
    file?: any;
    link?: any;
    totalIncludedUsers: number;
    users: ActivityReplyUser[];
  } = {
    tracker: this.asyncTracker.create(),
    users: [],
    totalIncludedUsers: 0
  };

  selfpenned: boolean;

  translation: {
    translatedText: string;
    detectedSourceLanguage: string;
    activity: {
      account: Account;
      interaction: {
        content: string;
        entities: any;
      };
    };
  };

  keyboardShortcuts: Array<{
    character: string;
    description: string;
    action: (event) => any;
  }>;

  focusReply: boolean;
  retweetSuccess: boolean;
  replyBoxDisabled = false;
  commentPermissions: any;
  facebookOutboxPermission: boolean;
  allTags: string[];

  replyTimeTracking: {
    activityLoadedAt: Date;
    replyStartedAt?: Date;
    trackingEventSent?: boolean;
  };

  crmPerson: CrmPerson;
  ownReplies = [];
  currentUser: User;
  currentUserCanDelete: boolean;
  lastMessageReceivedAt: string;
  cropActivityText = true;
  pluginPermission = false;
  signature = '';
  campaign: Campaign;
  daysToCountdown = 1;
  doActivityExistCheck = false;
  areActivityNotesVisible = false;

  private _contentText: string = '';
  private _isPredictive: boolean = false;
  private _prediction: string = '';
  private _previousText: string = '';

  @Input() activity: Activity;
  @Input() areActivityNotesExpanded: boolean;
  @Input() canSplitConversation: boolean;
  @Input() conversation: Conversation;
  @Input() hideArrowShortcuts: boolean;
  @Input() inboxMode: InboxMode;
  @Input() loadInitialConversation?: boolean;
  @Input() isPushMode: boolean = false;
  @Input() parentActivity: Activity;
  @Input() activityThread: ActivityThread;
  @Input() colleagues: Colleague[];

  @Input() isActive?: boolean;
  @Input() isAddNoteButtonVisible?: boolean;
  @Input() isChild?: boolean;
  @Input() isInbox?: boolean;
  @Input() isSocialWall?: boolean;
  @Input() hideFooter?: boolean = false;
  @Input() isNestedThread?: boolean;
  @Input() isParent?: boolean;
  @Input() isSelfPenned?: boolean;
  @Input() isActionable?: boolean;
  @Input() replyBoxSticky?: boolean;
  @Input() showReply?: boolean;

  @Output() onChangeActivity = new EventEmitter<any>();
  @Output() onReplyCreated = new EventEmitter<{
    activity: Activity;
    replyActivity: Activity;
  }>();
  @Output() onReplyTextChanged = new EventEmitter<{
    isDefault: boolean;
    activity: Activity;
  }>();
  @Output() onReplyBoxStatusChanged = new EventEmitter<{
    status: string;
  }>();
  @Output() onShowTags = new EventEmitter<any>();
  @Output() onShowFilters = new EventEmitter<any>();
  @Output() onMessageboxClick = new EventEmitter<any>();
  @Output() scrollToBottomOfThread = new EventEmitter<any>();
  @Output() onSplitConversation = new EventEmitter<any>();
  @Output() openSplitConversationPanel = new EventEmitter<any>();
  @Output() closeSplitConversationPanel = new EventEmitter<any>();
  @Output() splitConversation = new EventEmitter<any>();
  @Output() onResolveConversation = new EventEmitter<any>();
  @Output() onHoldConversationStatusChange = new EventEmitter<any>();

  constructor(
    private translate: TranslateService,
    private translationService: TranslationService,
    private asyncTracker: AsyncTrackerFactory,
    private socialPostCharactersRemainingPipe: SocialPostCharactersRemainingPipe,
    private activityModel: ActivityModel,
    private accountModel: AccountModel,
    private userModel: UserModel,
    private profileModel: ProfileModel,
    private popup: PopupService,
    private api: API,
    private outboxModel: OutboxModel,
    private workflowManager: WorkflowManagerService,
    private userPreferences: UserPreferencesService,
    private company: CompanyService,
    private predictedResponseService: PredictedResponseService,
    private activityTags: ActivityTags,
    private campaignsService: CampaignsService,
    @Inject(PUBLISHER_ACTIVE) private publisherActive: PublisherActive,
    private inboxKeyboardShortcutsService: InboxKeyboardShortcutsService,
    private _hotkeysService: HotkeysService,
    private modal: NgbModal,
    private profileHelper: ProfileHelperService
  ) {}

  public get isPredictive() {
    return this._isPredictive;
  }

  public get shouldShowActivityLink() {
    if (
      !this.activity ||
      !this.activity.interaction ||
      !this.activity.social_actions ||
      (this.activity.interaction.social_type === 'instagram_graph' &&
        this.activity.interaction.is_private)
    ) {
      return false;
    }

    return (
      !this.activity.interaction.deleted_at &&
      !this.activity.social_actions.is_hidden
    );
  }

  keyPressed(event) {
    console.log('event:', event);
  }

  async ngOnInit() {
    this.lastMessageReceivedAt =
      (this.activityThread &&
        this.activityThread.thread.siblings.newer.activities &&
        this.activityThread.thread.siblings.newer.activities[0] &&
        this.activityThread.thread.siblings.newer.activities[0].interaction &&
        this.activityThread.thread.siblings.newer.activities[0].interaction
          .created_at) ||
      (this.activity &&
        this.activity.interaction &&
        this.activity.interaction.created_at);

    this.currentLanguage = this.translate.currentLang;

    this.daysToCountdown =
      this.activity && this.activity.sessionValidityPeriodInDays;

    this.company
      .hasFeatureAccess('PREDICTIVE_TEXT')
      .then((isPredictive) => (this._isPredictive = !!isPredictive));

    this.accountModel
      .findAccounts(this.workflowManager.getCurrentId())
      .then((workflowAccounts) => {
        this.workflowAccounts = workflowAccounts;
      });

    await this.userModel.getAuthUser().then((user) => {
      this.currentUser = user;
    });

    this.userPreferences.getPreferences().then((userPreferences) => {
      this.cropActivityText = JSON.parse(
        userPreferences.inbox_message_read_more
      );
    });

    if (this.isInbox) {
      this.userPreferences.getPreferences().then((userPreferences) => {
        this.signature = userPreferences.inbox_signature
          ? `${userPreferences.inbox_signature}`
          : '';
        this.reply.text = this.signature ? this.signature : '';
      });
    }

    if (this.isInbox && this.isActive) {
      this.setupKeyboardShortcuts();
    }

    this.company
      .hasFeatureAccess('ORLO_PLUGIN')
      .then((result) => (this.pluginPermission = !!result));

    this.allTags = await this.activityTags.getTags();

    if (this.areActivityNotesExpanded) {
      this.areActivityNotesVisible = true;
    }

    this.getCampaignName();
  }

  replyTextChanged = debounce(() => {
    if (this.activity && this.activity.account) {
      this.onReplyTextChanged.emit({
        isDefault: !this.reply.text || this.reply.text === this.signature,
        activity: this.activity
      });
    }
  }, 500);

  initActivity = debounce(async () => {
    this.ownReplies = [];

    if (this.activity && this.activity.account) {
      this.replyTimeTracking = {
        activityLoadedAt: new Date()
      };

      this.reply.account = this.activity.account;
      this.reply.visible = this.showReply;

      if (this.activity['outbox_message']) {
        this.activity['outbox'] = this.outboxModel.inject(
          this.activity['outbox_message']
        );
      }

      this.resetReply();

      this.activity.loadMedia();

      if (this.isInbox && this.isActive) {
        this.activity.getNotes();
      }

      this.translation = null;

      this.commentPermissions = {};
      if (
        this.activity.account.socialNetwork.activity &&
        this.activity.account.socialNetwork.activity.permissions &&
        this.activity.account.socialNetwork.activity.permissions.includes(
          DELETE_COMMENT_PERMISSION
        )
      ) {
        Object.assign(this.commentPermissions, {
          account: DELETE_COMMENT_PERMISSION,
          ids: this.activity.account_id
        });
      }

      const accountTypesNeedChecking = [
        SocialType.Facebook,
        SocialType.Facebook,
        SocialType.InstagramGraph,
        SocialType.Instagram,
        SocialType.Twitter
      ];
      if (
        !this.activity.interaction.is_private &&
        accountTypesNeedChecking.includes(
          this.activity.interaction.social_type as any
        )
      ) {
        const hasFeatureFlag = await this.company.hasFeatureAccess(
          'INBOX_SOCIAL_CHECK'
        );
        this.doActivityExistCheck = hasFeatureFlag;
        this.replyBoxDisabled = hasFeatureFlag;
      }
    }
  }, 500);

  async getCampaignName() {
    if (
      this.activity &&
      this.activity.outbox &&
      this.activity.outbox.campaign_id
    ) {
      this.campaign = await this.campaignsService.getById(
        this.activity.outbox.campaign_id
      );
    }
  }

  setupKeyboardShortcuts() {
    this.keyboardShortcuts = [
      {
        character: KeyboardShortcut.MarkActioned,
        description: 'MARK_AS_ACTIONED',
        action: () => {
          if (this.activity) {
            this.activity.changeStatus(ActivityStatus.Actioned);
          }
        }
      },
      {
        character: KeyboardShortcut.MarkUnread,
        description: 'MARK_AS_UNREAD',
        action: () => {
          if (this.activity) {
            this.activity.changeStatus(ActivityStatus.Unread);
          }
        }
      },
      {
        character: KeyboardShortcut.AddResponse,
        description: 'ADD_RESPONSE',
        action: (event) => {
          event.preventDefault();
          this.focusReply = true;
        }
      },
      {
        character: KeyboardShortcut.AmendSentiment,
        description: 'AMEND_SENTIMENT',
        action: () => {
          this.inboxKeyboardShortcutsService.shortcutPressed$.next(
            KeyboardShortcut.AmendSentiment
          );
        }
      },
      {
        character: KeyboardShortcut.AmendLanguage,
        description: 'AMEND_LANGUAGE',
        action: () => {
          this.inboxKeyboardShortcutsService.shortcutPressed$.next(
            KeyboardShortcut.AmendLanguage
          );
        }
      },
      {
        character: KeyboardShortcut.ShowTags,
        description: 'INBOX_ACTION_ADD_TAGS_TITLE',
        action: () => {
          this.onShowTags.emit();
        }
      },
      {
        character: KeyboardShortcut.AddFilter,
        description: 'ADD_FILTER',
        action: () => {
          this.onShowFilters.emit();
        }
      }
    ];

    this.keyboardShortcuts.forEach((shortcut) => {
      this._hotkeysService.add(
        new Hotkey(shortcut.character, (event) => shortcut.action(event))
      );
    });
  }

  currentUserHasDeleteCommentPermissions(user) {
    if (!this.activity || !this.activity.account || !user) {
      return false;
    }

    if (
      this.activity.account.socialNetwork.activity &&
      this.activity.account.socialNetwork.activity.permissions &&
      this.activity.account.socialNetwork.activity.permissions.includes(
        DELETE_COMMENT_PERMISSION
      )
    ) {
      return user.hasAccountPermission(
        this.activity.account_id,
        DELETE_COMMENT_PERMISSION
      );
    }

    return true;
  }

  currentUserHasRedactMessagesPermissions(user: User) {
    if (
      !user ||
      !this.activity ||
      !this.activity.interaction ||
      !this.activity.account
    ) {
      return false;
    }

    const userHasRedactPermission = user.hasCompanyPermission(
      REDACT_MESSAGES_PERMISSION
    );

    const activityCanBeRedacted =
      this.activity.account.socialNetwork.activity &&
      !this.activity.interaction.redacted_by &&
      !(this.activity.interaction.content === '[redacted]');

    if (
      !userHasRedactPermission ||
      !activityCanBeRedacted ||
      this.selfpenned ||
      !this.activity ||
      !this.activity.account ||
      !user
    ) {
      return false;
    }

    return true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isActive) {
      this.replyTimeTracking = {
        // in case the activity was part of a thread and then became active
        activityLoadedAt: new Date()
      };
    }
    if (changes && changes.activity && changes.activity.currentValue) {
      this.initActivity();
    }
  }

  viewProfile(event: { profileId: string }) {
    this.profileHelper.viewProfile({
      accountId: this.activity.account_id,
      profileId: event.profileId
    });
  }

  fileUploadErrored(error) {
    this.popup.alert({
      isError: true,
      message: error
    });
  }

  onResolve(opts: any): void {
    this.onResolveConversation.emit({ opts });
  }

  holdConversationStatusChange(opts: any): void {
    this.onHoldConversationStatusChange.emit({ opts });
  }

  resetReply() {
    this.reply = {
      tracker: this.reply.tracker,
      account: this.reply.account,
      visible: this.reply.visible,
      text: this.signature,
      users: [],
      totalIncludedUsers: 0
    };
    this.updateReplyUsers();
    this.replyTextChanged();
  }

  changeReplyAccount(account) {
    this.reply.account = account;
    this.updateReplyUsers();
  }

  splitConversationPanelStatus(isPanelOpen) {
    if (isPanelOpen) {
      this.openSplitConversationPanel.emit();
    } else {
      this.closeSplitConversationPanel.emit();
    }
  }

  public isOutboundMessage(activity: Activity): boolean {
    if (
      !this.currentUser ||
      !activity ||
      !activity.account ||
      !activity.author
    ) {
      return;
    }

    return String(activity.author.id) === String(activity.account.social_id);
  }

  private updateReplyUsers() {
    const previousReplyUsers = this.reply.users;
    const mentions: any[] =
      (this.activity.interaction.entities &&
        this.activity.interaction.entities.user_mentions) ||
      [];

    const users = mentions
      .filter((mention) => mention.id_str !== this.reply.account.social_id)
      .map((mention) => {
        return {
          id: mention.id_str,
          username: mention.screen_name,
          name: mention.name,
          excluded: previousReplyUsers.some(
            (user) => user.id === mention.id_str && user.excluded
          )
        };
      });
    this.reply.users = uniqBy(users, 'id');
    this.reply.totalIncludedUsers = this.reply.users.filter(
      (user) => !user.excluded
    ).length;
  }

  async pushMessagesToCrm() {
    const { data } = await this.api.get(
      `crm/person?profile_account_type_id=${this.activity.account.account_type_id}&profile_social_id=${this.activity.author.id}`
    );
    this.crmPerson = (data as any).results[0];

    const modal = this.modal.open(ExportMessagesComponent, {
      windowClass: 'xl-modal orlo-modal',
      centered: true
    });
    modal.componentInstance.activity = this.activity;
    modal.componentInstance.activityThread = this.activityThread.thread;
    modal.componentInstance.person = this.crmPerson;
  }

  quoteRetweet(params) {
    this.publisherActive.next({
      isActive: true,
      ...params
    });
  }

  async checkReplyExists(repliedActivity): Promise<boolean> {
    const { data } = (await this.api.get('social/check', {
      params: { id: repliedActivity.id }
    })) as any;
    if (
      !data ||
      !data.check_succeeded ||
      (!data.status.is_deleted && data.status.can_reply)
    ) {
      this.replyBoxDisabled = false;
      return true;
    }
    const modal = this.modal
      .open(InboxAlertModalComponent, {
        windowClass: 'xl-modal orlo-modal',
        centered: true
      })
      .result.then(() => {
        repliedActivity.interaction.deleted_at = new Date().toString();
      });
    return false;
  }

  async saveReply(replyContent: any) {
    this.reply.text = replyContent.text;
    const { socialNetwork } = this.reply.account;
    const errors: any = {};

    const maxPostChars =
      socialNetwork.maxPostCharacters[
        this.activity.interaction.is_private ? 'private' : 'reply'
      ];
    const charactersRemaining = this.socialPostCharactersRemainingPipe.transform(
      this.reply.text,
      maxPostChars,
      this.reply.account.account_type_name === 'Twitter'
    );
    const statusIsTooLong = charactersRemaining < 0;
    if (statusIsTooLong) {
      errors.statusLength = {};
    }

    if (
      socialNetwork.activity &&
      socialNetwork.activity.response &&
      socialNetwork.activity.response.validate
    ) {
      const validationResult = socialNetwork.activity.response.validate({
        text: this.reply.text
      });
      if (!validationResult.isValid) {
        Object.assign(errors, validationResult.errors);
      }
    }

    if (Object.values(errors).length > 0) {
      const errorStrings = [];

      if (errors.statusLength) {
        errorStrings.push(
          this.translate.instant(
            'YOUR_REPLY_IS__CHARACTERSTOOMANY__CHARACTERS_TOO_LONG',
            {
              charactersTooMany: Math.abs(charactersRemaining)
            }
          )
        );
      }

      if (errors.uppercase) {
        errorStrings.push(
          this.translate.instant('YOUR_REPLY_CANNOT_BE_ALL_UPPERCASE_LETTERS')
        );
      }

      if (errors.hashtags) {
        errorStrings.push(
          this.translate.instant(
            'YOUR_REPLY_CANNOT_CONTAIN_MORE_THAN__MAXALLOWEDHASHTAGS__HASHTAGS',
            {
              maxAllowedHashtags: errors.hashtags.amount.max
            }
          )
        );
      }

      if (errors.links) {
        errorStrings.push(
          this.translate.instant(
            'YOUR_REPLY_CANNOT_CONTAIN_MORE_THAN__MAXAMOUNTLINKS__LINKS',
            {
              maxAmountLinks: errors.links.amount.max
            }
          )
        );
      }

      this.popup.alert({
        isError: true,
        message: errorStrings.join('<br><br>')
      });
    } else {
      const activity = this.activity;
      const reply = {
        reply: this.reply.text,
        humanAgent: this.activity && this.activity.usingMessengerApi,
        file: this.reply.file,
        account_id: this.reply.account.id,
        link: this.reply.link,
        add_dm_reply_link: this.reply.add_dm_reply_link,
        recursive: false,
        exclude_users: this.reply.users
          .filter((user) => user.excluded)
          .map((user) => user.id)
      };

      if (!this.replyTimeTracking.trackingEventSent) {
        const totalReplyTime: number =
          Date.now() - this.replyTimeTracking.activityLoadedAt.getTime();
        this.trackGaEvent({
          action: GA_EVENT_ACTION_MESSAGE_OPENED_TO_REPLY_SENT_TIME,
          value: totalReplyTime
        });
        if (this.replyTimeTracking.replyStartedAt) {
          const replyStartedToReplySentTime =
            Date.now() - this.replyTimeTracking.replyStartedAt.getTime();
          this.trackGaEvent({
            action: GA_EVENT_ACTION_REPLY_STARTED_TO_REPLY_SENT_TIME,
            value: replyStartedToReplySentTime
          });
        }
        this.replyTimeTracking.trackingEventSent = true;
      }

      await this.company
        .hasFeatureAccess('FACEBOOK_OUTBOX')
        .then((result) => (this.facebookOutboxPermission = !!result));

      const promise = activity.saveReply(reply).then((replyActivity) => {
        if (this.isInbox) {
          if (activity.social_actions.reply_type === 'comment') {
            if (this.facebookOutboxPermission) {
              activity.conversation.responses = replyActivity;
              this.injectTempActivity(activity, reply);
            } else {
              this.onReplyCreated.emit({ activity, replyActivity });
            }
          } else {
            this.injectTempActivity(activity, reply);
          }

          if (this.replyBoxSticky) {
            this.scrollToBottomOfThread.emit();
          }
        } else {
          this.reply.visible = false;
        }

        this.resetReply();
      });
      this.reply.tracker.add(promise);
    }
  }

  injectTempActivity(activity: Activity, reply) {
    const replyAccount = this.reply.account;
    const responseIds = activity.conversation.responses;
    const outboxResponseId = responseIds[responseIds.length - 1];
    this.outboxModel.findAll({ id: outboxResponseId }).then((outbox: any) => {
      const fakeActivity = {
        id: `activity-outbox-${outboxResponseId}`,
        etag: outbox.reply_to_social_id && outbox.reply_to_social_id,
        account_id: replyAccount.id,
        interaction: {
          social_type: activity.interaction.social_type,
          content: reply.reply,
          entities: {
            user_mentions: [],
            hashtags: [],
            media: []
          },
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          is_private: activity.interaction.is_private
        },
        inbox: {
          requires_action: false,
          is_viewed: true
        },
        conversation: {
          responses: [],
          depth: 0,
          thread_id: activity.conversation.thread_id,
          length: activity.conversation.length + 1
        },
        social_actions: {
          can_like: true,
          can_reply: true,
          can_share: true,
          can_delete: true,
          can_hide: false,
          is_hidden: false,
          reply_type: 'reply',
          is_liked: false,
          is_shared: false,
          is_commented: false,
          share_count: 0,
          like_count: 0
        },
        media: reply.file
          ? [
              {
                type: 'photo',
                link: reply.file.url,
                size: {
                  full: reply.file.url,
                  thumb: reply.file.url
                }
              }
            ]
          : [],
        properties: {
          sentiment: 0,
          language: 'en',
          sentiment_by: 14
        },
        author: {
          name: replyAccount.name,
          id: replyAccount.social_id,
          username: replyAccount.username,
          avatar: replyAccount.picture
        },
        outbox,
        reply_requires_validation: activity.reply_requires_validation,
        isTempActivity: true
      };
      const tempActivity = this.activityModel.inject(fakeActivity);

      this.onReplyCreated.emit({
        activity,
        replyActivity: tempActivity
      });
    });
  }

  toggleActivityNotesVisibility() {
    if (this.areActivityNotesExpanded) {
      return;
    }

    this.areActivityNotesVisible = !this.areActivityNotesVisible;
  }

  translateActivityContent() {
    this.translationService
      .translateText({ text: this.activity.interaction.content })
      .then((translations) => {
        this.translation = translations[0];
        if (
          this.translation.detectedSourceLanguage !==
          this.activity.properties.language
        ) {
          this.activity.updateLanguage(this.translation.detectedSourceLanguage);
        }

        this.translation.activity = {
          account: this.activity.account,
          interaction: {
            content: this.translation.translatedText,
            entities: this.activity.interaction.entities
          }
        };
      });
  }

  updateReplyText(text: string) {
    this.replyTextChanged();
    this.reply.text = text;
  }

  translateReplyText() {
    this.translationService
      .translateText({
        text: this.reply.text,
        target: this.translation.detectedSourceLanguage
      })
      .then((translations) => {
        this.reply.text = translations[0].translatedText;
      });
  }

  shareFromAccount(account) {
    this.retweetSuccess = false;
    this.api
      .post('social/share', {
        account_id: account.id,
        social_id: this.activity.interaction.id
      })
      .then((result) => (this.retweetSuccess = result ? true : false));
  }

  autoShare() {
    this.retweetSuccess = false;
    this.activity
      .autoShare()
      .then((result) => (this.retweetSuccess = result ? true : false));
  }

  async replyStarted() {
    if (this.doActivityExistCheck) {
      if ((await this.checkReplyExists(this.activity)) === true) {
        this.replyBoxDisabled = false;
      }
      this.doActivityExistCheck = false;
    }

    if (!this.replyTimeTracking.replyStartedAt) {
      this.replyTimeTracking.replyStartedAt = new Date();
      const timeToStartReply: number =
        Date.now() - this.replyTimeTracking.activityLoadedAt.getTime();
      this.trackGaEvent({
        action: GA_EVENT_ACTION_MESSAGE_OPENED_TO_REPLY_STARTED_TIME,
        value: timeToStartReply
      });
    }
  }

  addPrivateReplyLink() {
    if (this.activity.account.socialNetwork.addPrivateReplyLinkAsAttachment) {
      this.reply.add_dm_reply_link = !this.reply.add_dm_reply_link;
    } else {
      const replyLink: string = this.activity.account.socialNetwork.getPrivateReplyLink(
        this.activity.account
      );
      const replyText = this.reply.text || '';
      if (!replyText.includes(replyLink)) {
        this.reply.text = [replyText, replyLink]
          .filter((part) => !!part)
          .join(' ');
      }
    }
  }

  /**
   * Will have to be replaced with GA4 custom events (https://orlo.atlassian.net/browse/CT-3449)
   */
  private trackGaEvent({ category = GA_EVENT_CATEGORY_INBOX, action, value }) {
    if (this.isInbox && window['ga']) {
      window['ga']('send', {
        hitType: 'event',
        eventCategory: category,
        eventAction: action,
        eventValue: value
      });
    }
  }

  isTimed() {
    return (
      (this.activity &&
        this.activity.interaction &&
        this.activity.interaction.social_type &&
        this.activity.interaction.social_type === 'whatsapp') ||
      (this.activity.interaction.social_type === 'facebook' &&
        this.activity.interaction.is_private)
    );
  }

  // ----
  // predictions stuff
  // ----

  public get messageTextValue(): string {
    return this.reply.text;
  }

  public set messageTextValue(value: string) {
    this.setResponse(value);
  }

  public get prediction(): string {
    return this._prediction;
  }

  public set prediction(value: string) {
    this._prediction = value;
  }

  public async acceptPrediction(event: KeyboardEvent): Promise<boolean> {
    try {
      const prediction = String(this._prediction);
      const shouldContinue =
        !prediction.trim().length ||
        prediction.trim() === String(this.messageTextValue).trim();

      if (shouldContinue) {
        return false;
      }

      event.preventDefault();

      const shouldAddSpace =
        PredictedResponseService.CharactersRequiringSpaceSuffix.indexOf(
          prediction.substr(-1)
        ) === -1;
      const responseValue = prediction + (shouldAddSpace ? ' ' : '');

      const quantityOfWordsInPrediction: number = prediction
        .slice(this.messageTextValue.length)
        .trim()
        .split(' ').length;

      this.setResponse(responseValue);

      const storageKey = `ls` + format(new Date(), 'DD-MM-YYYY');
      const currentStoredValue: number =
        Number(window.localStorage.getItem(storageKey)) || 0;

      window.localStorage.setItem(
        storageKey,
        String(currentStoredValue + quantityOfWordsInPrediction)
      );

      await this.refreshPredictions(false);

      return true;
    } catch (error) {
      console.error('Error accepting prediction:', error);

      return false;
    }
  }

  public async onKeyDown(event: KeyboardEvent): Promise<boolean> {
    try {
      switch (event.key.toLowerCase()) {
        case 'arrowright':
          {
            const textarea = event.target as HTMLTextAreaElement;
            const cursor = textarea.selectionStart;
            const messageLength = this.reply.text.length;

            if (cursor >= messageLength) {
              return this.acceptPrediction(event);
            }
          }
          break;

        case 'tab':
          {
            return this.acceptPrediction(event);
          }
          break;
      }

      return true;
    } catch (error) {
      console.error('Error handling onKeyDown event:', error);

      return false;
    }
  }

  public async onKeyUp(event: KeyboardEvent): Promise<boolean> {
    try {
      this._previousText = this._contentText;
      this._contentText = this.reply.text;

      this.refreshPredictions(true);

      return true;
    } catch (error) {
      console.error('Error handling onKeyUp event:', error);

      return false;
    }
  }

  public async refreshPredictions(shouldFetchFreshPredictions: boolean) {
    try {
      // clear the prediction to reduce any visual lag
      // if e.g. lots of text is pasted in and the lookup is on a slow connection.

      this._prediction = '';

      if (!this.isPredictive) {
        return;
      }

      this._prediction = (
        await this.predictedResponseService.getPredictionFor(
          this.reply.text,
          shouldFetchFreshPredictions
        )
      )
        .trim()
        .replace(
          '{{name}}',
          this.activity.author.name
            ? this.activity.author.name.split(' ')[0]
            : ''
        );

      return true;
    } catch (error) {
      console.error('Error refreshing the predictions', error);

      return false;
    }
  }

  public setResponse(text: string = '') {
    try {
      this.reply.text = text;

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  handleReadMoreClick() {
    this.cropActivityText = false;
  }

  navigateTo(link) {
    window.open(link);
  }

  retweetEnabled(): boolean {
    if (
      !this.activity ||
      !this.activity.interaction ||
      !this.activity.social_actions ||
      !this.activity.inbox
    ) {
      return false;
    }

    // For some reason tweets on the social wall are always reply_type === 'reply' hence the retweet option was not showing up
    // Add an expection for social wall - show retweet option even if reply_type === 'reply' (as it was on the production before the inbox redesign work)

    return (
      this.shouldShowActivityLink &&
      !this.activity.interaction.is_private &&
      this.activity.interaction.social_type === 'twitter' &&
      this.activity.social_actions.can_share &&
      (this.activity.social_actions.reply_type === 'comment' ||
        this.activity.inbox.requires_action ||
        this.isSocialWall)
    );
  }
}
