import {
  downgradeComponent,
  downgradeInjectable
} from '@angular/upgrade/static';
import { NgZone } from '@angular/core';
import angular from 'angular';
import { TranslateService } from '@ngx-translate/core';
import {
  TeamsService,
  ColleaguesService
} from '../../angular/common/services/api';
import { RedirectService } from '../../angular/common/services/redirect/redirect.service';
import { ServerService } from '../../angular/common/services/server/server.service';
import { PUBLISHER_ACTIVE } from '../../angular/common/components/publisher/publisher-active';
import { UserPreferencesService } from '../../angular/common/services/user-preferences/user-preferences.service';
import { ErrorHandlerService } from '../../angular/common/services/error-handler/error-handler.service';
import { AuthService } from '../../angular/common/services/auth/auth.service';
import { UpdateManagerService } from '../../angular/common/services/update-manager/update-manager.service';
import { WorkflowManagerService } from '../../angular/common/services/workflow-manager/workflow-manager.service';
import { SocketsService } from '../../angular/common/services/sockets/sockets.service';
import { PublisherComponent } from '../../angular/common/components/publisher/publisher.component';
import { PluginComponent } from '../../angular/common/components/plugin/plugin.component';
import { NavbarComponent } from '../../angular/common/components/navbar/navbar.component';

export default angular
  .module('ssi.angular-shims', [])
  .factory('ngxTranslate', downgradeInjectable(TranslateService))
  .factory('teamsService', downgradeInjectable(TeamsService))
  .factory('colleaguesService', downgradeInjectable(ColleaguesService))
  .factory('publisherActive', downgradeInjectable(PUBLISHER_ACTIVE))
  .factory('redirect', downgradeInjectable(RedirectService))
  .factory('server', downgradeInjectable(ServerService))
  .factory('updateManager', downgradeInjectable(UpdateManagerService))
  .factory('userPreferences', downgradeInjectable(UserPreferencesService))
  .factory('errorHandler', downgradeInjectable(ErrorHandlerService))
  .factory('authService', downgradeInjectable(AuthService))
  .factory('socket', downgradeInjectable(SocketsService))
  .factory('workflowManager', downgradeInjectable(WorkflowManagerService))
  .directive(
    'ssiPublisher',
    downgradeComponent({ component: PublisherComponent })
  )
  .directive('ssiPlugin', downgradeComponent({ component: PluginComponent }))
  .directive('ssiNavbar', downgradeComponent({ component: NavbarComponent }));
