<div class="emoji-categories">
  <a *ngFor="let category of categories"
     (click)="changeCategory(category)"
     [class.active]="currentCategory === category"
     [ssiTooltip]="category.label"
     [tooltipAppendToBody]="true">
    <i [ngClass]="category.icon"></i>
  </a>
</div>

<div class="spacer-sides">
  <input [(ngModel)]="searchText"
         [placeholder]="'SEARCH' | translate"
         [ssiFocusOn]="true"
         class="form-control input-sm form-control-no-border"
         type="text">
</div>

<div *ngIf="!filteredEmojis"
     class="loading"></div>

<div *ngIf="filteredEmojis"
     class="emoji-list spacer-top"
     ssiVirtualScroll
     [items]="filteredEmojiRows"
     [bufferAmount]="0"
     [getItemHeight]="getItemHeight"
     (viewPortItemsUpdated)="viewPortItemsUpdated($event)">

  <div class="emojis-row"
       *ngFor="let emojiRow of viewPortEmojiRows">

    <div class="emoji-icon-container"
         *ngFor="let emoji of emojiRow; trackBy:trackByShortname">

      <a class="emoji-icon"
         title="{{emoji.name}}"
         (click)="selectEmoji(emoji)"
         [ssiEmojiText]="emoji.char"
         [spriteSize]="'24'"
         [sprites]="true">
      </a>
    </div>
  </div>

</div>