import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SaveDraftModalComponent } from './save-draft-modal.component';
import { FormsModule } from '@angular/forms';
import { FlatpickrModule } from 'angularx-flatpickr';
import { OrloComponentsModule } from 'orlo-components';
import { ToggleSwitch2Module } from '../toggle-switch-2/toggle-switch-2.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    FlatpickrModule,
    OrloComponentsModule,
    ToggleSwitch2Module
  ],
  declarations: [SaveDraftModalComponent],
  exports: [SaveDraftModalComponent],
  providers: []
})
export class SaveDraftModalModule {}
