import './date-time-picker.component.scss';
import moment from 'moment';
import {
  Component,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateButton } from 'angular-bootstrap-datetimepicker';

export const TAGS_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DateTimePickerComponent), //tslint:disable-line
  multi: true
};
let _minDate; // Needed for datetimepicker getSelectableDates scope issue
let _maxDate; // Needed for datetimepicker getSelectableDates scope issue

@Component({
  selector: 'ssi-date-time-picker',
  templateUrl: './date-time-picker.component.html'
})
export class DateTimePickerComponent implements OnInit, ControlValueAccessor {
  @Input()
  datetimepickerConfig: {
    minView?: 'year' | 'month' | 'day' | 'hour' | 'minute';
    maxView?: 'year' | 'month' | 'day' | 'hour' | 'minute';
    startView?: 'year' | 'month' | 'day' | 'hour' | 'minute';
  } = {};
  @Input() dateFormat: string;
  @Input() placeholder: string;
  @Input() selectedDate: Date = new Date();
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() required: boolean;

  @Output() onDateSelected = new EventEmitter();

  @ViewChild('pickerDropdown') pickerDropdown;
  defaultDateFormat = 'dd MMMM yyyy, h:mm a';

  constructor() {}

  ngOnInit() {
    _minDate = this.minDate;
    _maxDate = this.maxDate;
  }

  private onChangeCallback: (_: any) => void = () => {};

  writeValue(newValue: Date): void {
    this.selectedDate = newValue;
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {}

  private setViewValue(newValue: Date) {
    this.writeValue(newValue);
    this.onChangeCallback(newValue);
  }

  onSelectDate() {
    this.onDateSelected.emit(this.selectedDate);
    this.setViewValue(this.selectedDate);
    this.pickerDropdown.close();
  }

  onKeyDown(event) {
    event.preventDefault();
    if (event.keyCode === 8) {
      this.pickerDropdown.close();
    }
  }

  getSelectableDates(dateButton: DateButton) {
    if (_minDate && moment(dateButton.value).isBefore(moment(_minDate))) {
      return;
    }
    if (_maxDate && moment(dateButton.value).isAfter(moment(_maxDate))) {
      return;
    }
    return dateButton;
  }
}
