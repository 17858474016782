import angular from 'angular';
import angularTranslate from 'angular-translate';
import uiResources from './ui-resources';

declare module 'angular' {
  export namespace translate {
    // tslint:disable-next-line
    interface ITranslateService {
      getCurrentLanguage(): string;
    }
  }
}

export default angular
  .module('decorators', [uiResources, angularTranslate])
  .decorator('$translate', ($delegate) => {
    'ngInject';

    $delegate.getCurrentLanguage = () => {
      const key =
        $delegate.use() ||
        $delegate.proposedLanguage() ||
        $delegate.preferredLanguage();
      return key.toLowerCase().split('_')[0];
    };

    return $delegate;
  })
  .decorator('$http', ($delegate) => {
    'ngInject';

    const { get, post, put } = $delegate;

    // I have no idea why this is needed or why it works, but it fixes using $http breaking the angular zone

    $delegate.get = (...args) => Promise.resolve(get(...args));

    $delegate.post = (...args) => Promise.resolve(post(...args));

    $delegate.put = (...args) => Promise.resolve(put(...args));

    return $delegate;
  });
