import { Injectable } from '@angular/core';
import { API } from '@ui-resources-angular';

export enum sourceTypeName {
  Twitter = 'twitter',
  News = 'moreover_news',
  Blog = 'moreover_blog',
  Print = 'moreover_print',
  Broadcast = 'moreover_broadcast',
  Microblog = 'moreover_microblog',
  SocialVideo = 'moreover_social_video',
  SocialPhoto = 'moreover_social_photo',
  SocialNetwork = 'moreover_social_network',
  Podcast = 'moreover_podcast',
  Review = 'moreover_review',
  Wiki = 'moreover_wiki',
  Comment = 'moreover_comment',
  QA = 'moreover_qa',
  Forum = 'moreover_forum',
  Classified = 'moreover_classified',
  Facebook = 'facebook'
}

export interface Stream {
  latest_result: string;
  id: string;
  'include-retweets': boolean;
  lang: string;
  name: string;
  sources: sourceTypeName[];
  query: any;
  geo: any;
}

export const sources = {
  [sourceTypeName.Twitter]: {
    label: 'X',
    icon: 'ssi ssi-x-logo',
    color: '#000',
    name: sourceTypeName.Twitter,
    hideOption: false
  },
  [sourceTypeName.News]: {
    label: 'News',
    icon: 'ssi ssi-news',
    color: '#3CC5D8',
    name: sourceTypeName.News,
    hideOption: false
  },
  [sourceTypeName.Blog]: {
    label: 'Blog',
    icon: 'ssi ssi-blog-source',
    color: '#D41D68',
    name: sourceTypeName.Blog,
    hideOption: false
  },
  [sourceTypeName.Print]: {
    label: 'Print',
    icon: 'ssi ssi-print-source',
    color: '#F0B427',
    name: sourceTypeName.Print,
    hideOption: false
  },
  // [sourceTypeName.Broadcast]: {
  //   label: 'Broadcast',
  //   icon: 'fa fa-broadcast-tower',
  //   color: '#89A4EA',
  //   name: sourceTypeName.Broadcast,
  //   hideOption: false
  // },
  [sourceTypeName.Microblog]: {
    label: 'Microblog',
    icon: 'ssi ssi-micro-blog-source',
    color: '#425DEC',
    name: sourceTypeName.Microblog,
    hideOption: false
  },
  [sourceTypeName.SocialVideo]: {
    label: 'Social Video',
    icon: 'ssi ssi-social-video-source',
    color: '#14BAE3',
    name: sourceTypeName.SocialVideo,
    hideOption: false
  },
  [sourceTypeName.SocialPhoto]: {
    label: 'Social Photo',
    icon: 'ssi ssi-image-small',
    color: '#F40064',
    name: sourceTypeName.SocialPhoto,
    hideOption: false
  },
  [sourceTypeName.SocialNetwork]: {
    label: 'Social Network',
    icon: 'ssi ssi-social-network-source',
    color: '#FB6340',
    name: sourceTypeName.SocialNetwork,
    hideOption: true
  },
  [sourceTypeName.Podcast]: {
    label: 'Podcast',
    icon: 'ssi ssi-podcast-source',
    color: '#425DEC',
    name: sourceTypeName.Podcast,
    hideOption: false
  },
  [sourceTypeName.Review]: {
    label: 'Review',
    icon: 'ssi ssi-reviews',
    color: '#14BAE3',
    name: sourceTypeName.Review,
    hideOption: false
  },
  [sourceTypeName.Wiki]: {
    label: 'Wiki',
    icon: 'ssi ssi-wiki-source',
    color: '#FB6340',
    name: sourceTypeName.Wiki,
    hideOption: false
  },
  [sourceTypeName.Comment]: {
    label: 'Comment',
    icon: 'ssi ssi-comments-source',
    color: '#F0B427',
    name: sourceTypeName.Comment,
    hideOption: false
  },
  [sourceTypeName.QA]: {
    label: 'QA',
    icon: 'ssi ssi-qa-source',
    color: '#F40064',
    name: sourceTypeName.QA,
    hideOption: false
  },
  [sourceTypeName.Forum]: {
    label: 'Forum',
    icon: 'ssi ssi-forum-source',
    color: '#838EAB',
    name: sourceTypeName.Forum,
    hideOption: false
  },
  [sourceTypeName.Classified]: {
    label: 'Classified',
    icon: 'ssi ssi-classified-source',
    color: '#AEBDE3',
    name: sourceTypeName.Classified,
    hideOption: false
  },
  [sourceTypeName.Facebook]: {
    label: 'Facebook',
    icon: 'fa fa-facebook',
    color: '#1877F2',
    name: sourceTypeName.Facebook,
    hideOption: true
  }
};

@Injectable()
export class MonitoringStreamsService {
  constructor(private api: API) {}

  getAll(): Promise<Stream[]> {
    return this.api.get('monitoring/index_v2').then(({ data }) => data);
  }
}
