import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';

import { ComponentLibraryComponent } from './component-library.component';
import { ClDropdownSelectModule } from './cl-dropdown-select/cl-dropdown-select.module';
import { ClButtonModule } from './cl-button/cl-button.module';
import { ClPostSchedulerModule } from './cl-post-scheduler/cl-post-scheduler.module';
import { ClAccordionModule } from './cl-accordion/cl-accordion.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.component-library',
          url: '/component-library',
          component: ComponentLibraryComponent,
          resolve: ComponentLibraryComponent.resolve
        }
      ]
    }),
    ClDropdownSelectModule,
    ClButtonModule,
    ClPostSchedulerModule,
    ClAccordionModule
  ],
  exports: [ComponentLibraryComponent],
  declarations: [ComponentLibraryComponent]
})
export class ComponentLibraryModule {}
