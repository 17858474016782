<div class="cl-dropdown-select-g-container">
  <h4>G (implementation wrapped inside ssi-tags-select-create component)</h4>
  <a target="_blank"
     href="https://github.com/orlo/web-app/blob/master/src/apps/angular/modules/auth/component-library/cl-dropdown-select/cl-dropdown-select-d/cl-dropdown-select-d.component.html">
    View code on GitHub
  </a>
  <p>headButtonStyleVariant="default"; headButtonLabel template;</p>
  <p>LG size (width: 100%) - emptyState and bodyFooter templates</p>

  <ssi-tags-select-create [tagType]="'post'"
                          [headButtonVariant]="'chips'"
                          (onSelectedTagsChange)="onSelectedTagsChange($event)"></ssi-tags-select-create>
</div>