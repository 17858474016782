<div class="checkbox-container"
	 [class.active]="checked">
	<label [for]="id"> {{checkboxText}}</label>
	<input #checkboxRef
		   type="checkbox"
		   [disabled]="checked || isDisabled"
		   name="name"
		   class="checkbox-input"
		   [id]="id"
		   (change)="checkboxChange($event)"
		   [ngClass]="{'disabled' : isDisabled, 'is-checked' : isChecked}"
		   [checked]="isChecked"
		   [value]="isChecked">
	<i class="ssi ssi-tick"></i>
</div>