import { filestackApiKey } from '@ui-resources-angular';
import get from 'lodash.get';

export interface Chunk<ChunkType> {
  items: ChunkType[];
  label?: string;
}

// assume items are ordered by the value returned by getGroupByField for this to work
export function groupArray<ChunkType = any>(
  items: ChunkType[] | ChunkType = [],
  getGroupByField?: (item: ChunkType) => any,
  isSame: (label1: any, label2: any) => boolean = (label1, label2) =>
    label1 === label2
): Array<Chunk<ChunkType>> {
  if (!Array.isArray(items)) {
    items = [items];
  }

  const chunks: Array<Chunk<ChunkType>> = [];
  let buildChunk: Chunk<ChunkType> = {
    items: []
  };

  items.forEach((item) => {
    const currentLabel = getGroupByField(item);

    if (!buildChunk.label) {
      // first run of the loop
      buildChunk.label = currentLabel;
    }

    if (!isSame(buildChunk.label, currentLabel)) {
      chunks.push(buildChunk);
      buildChunk = {
        items: [],
        label: currentLabel
      };
    }

    buildChunk.items.push(item);
  });

  if (buildChunk.items.length > 0) {
    chunks.push(buildChunk);
  }

  return chunks;
}

export function toSeconds(ms: number, precision: number): number {
  const ONE_SECOND = 1000;
  return +(ms / ONE_SECOND).toFixed(precision);
}

export interface KeyValueObject<T = any> {
  [key: string]: T;
}

export function transformImage(
  url: string,
  params: KeyValueObject<KeyValueObject<string>>
): string {
  const paramsStr = Object.entries(params)
    .map(([paramKey, args]) => {
      const argsStr = Object.entries(args)
        .map(([argKey, arg]) => `${argKey}:${arg}`)
        .join(',');
      return `${paramKey}=${argsStr}`;
    })
    .join('/');

  return `https://cdn.uploads.orlo.app/${filestackApiKey}/${paramsStr}/${url}`;
}

export const trackByIndex = (index: number) => index;

export const trackByProperty = (field: string) => (
  index: number,
  object: object
) => object[field];

// trackByProperty is faster, for tested access like `profile.id` use this (slower) function instead
export const trackByNestedProperty = (field: string) => (
  index: number,
  object: object
) => get(object, field);

export function nl2br(value: string): string {
  return (value || '').replace(/\n|&#10;/g, '<br>');
}

export function oneRecordHasField(items: any[], field: string): boolean {
  return items.some((network) => get(network, field, false));
}

export function recordFieldUnionValues<FieldType>(
  items: any[],
  field: string
): FieldType[] {
  return items.reduce((result: any[], item) => {
    const itemValues = get(item, field, []);
    return result.filter((value) => itemValues.includes(value));
  }, get(items[0], field, []));
}

export function getHighestValue(
  items: any[],
  field: string
): number | undefined {
  const values = items.map((item) => get(item, field, 0));
  if (values.length > 0) {
    return Math.max(...values);
  }
}

export function getLowestValue(
  items: any[],
  field: string
): number | undefined {
  const values = items
    .map((item) => get(item, field))
    .filter((val) => typeof val !== 'undefined');
  if (values.length > 0) {
    return Math.min(...values);
  }
}

export function getExtensionFromFilename(filename: string): string {
  return /(?:\.([^.]+))?$/.exec(filename)[1];
}

export function isEmptyObj(obj: any): boolean {
  for (const i in obj) {
    return false;
  }
  return true;
}

export function onlyUnique(array: Array<any>, key = 'id') {
  const uniques = array.filter(
    (value, index, self) =>
      self.map((x) => x[key]).indexOf(value[key]) === index
  );

  return uniques;
}
