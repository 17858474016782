import './publisher-landing.component.scss';

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  TemplateRef,
  ViewChild,
  OnInit,
  ElementRef,
  OnDestroy,
  Inject
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import debounce from 'debounce-promise';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  Campaign,
  Account,
  OutboxPublisher,
  OutboxPublisherLink,
  OutboxFileType,
  OutboxPublisherMention,
  OutboxPublisherHighlightType,
  UserModel,
  ActivityTags,
  MediaCategory,
  ProfileSearchResult
} from '@ui-resources-angular';
import { OutboxTagsService, Tag, TagType } from '../../../services/api';
import { Debounce } from '../../../decorators';
import {
  PUBLISHER_ACTIVE,
  PublisherActive,
  PublisherDisableOptions
} from '../publisher-active';
import { TooltipDirective } from '../../../directives/tooltip/tooltip.directive';
import { AsyncTracker, AsyncTrackerFactory } from 'angular-async-tracker';
import {
  FileUploaderDirective,
  FileUploadFile
} from '../../../directives/file-uploader/file-uploader.directive';
import { ContextMenuComponent } from '../../context-menu/context-menu.component';
import { TextInputAutocompleteDirective } from '../../text-input-autocomplete';
import { TextInputHighlightComponent } from '../../text-input-highlight';
import {
  FilestackSources,
  FilestackService,
  FilestackClient,
  FilestackPickOptions,
  CUSTOM_SOURCE_NAME,
  FilestackFile
} from '../../../services/filestack/filestack.service';
import { PublisherMentionsAutocompleteMenuComponent } from './publisher-mentions-autocomplete-menu/publisher-mentions-autocomplete-menu.component';
import { PublisherActionsAndMediaComponent } from './publisher-actions-and-media/publisher-actions-and-media.component';
import { CompanyService } from '../../../services/company/company.service';
import {
  maximumFileSizeForImagesInBytes,
  permittedSources,
  acceptedFileTypesForImages
} from '../../../../../../library/constants/filestack';
import { ConfirmationModalComponent } from '../../confirmation-modal/confirmation-modal.component';
import { AiWritingToolModalComponent } from './ai-writing-tool-modal/ai-writing-tool-modal.component';
import { DeviceService } from '../../../services/device/device.service';
import { AccountTypeIdString } from '../../../enums';

// ----------------

const isDebug = false;

// ----------------

export enum Stage {
  ActionsAndMedia = 'actions-and-media',
  Targeting = 'targeting'
}

const MENTIONS_AUTO_COMPLETE_DEBOUNCE = 400;
const MAX_MENU_MENTIONS = 10;

export interface PublisherHighlightTagEvent {
  target: HTMLElement;
  tag: OutboxPublisherLink | OutboxPublisherMention;
}

@Component({
  selector: 'ssi-publisher-landing',
  templateUrl: './publisher-landing.component.html'
})
export class PublisherLandingComponent implements OnChanges, OnInit, OnDestroy {
  @Input() post: OutboxPublisher;
  @Input() accounts: Account[] = [];
  @Input() campaigns: Campaign[] = [];
  @Input() disable: PublisherDisableOptions;
  @Input() slideAnimationCompleted: boolean;
  @Input() publishingLoadingTracker: AsyncTracker;
  @Input() postInvalidTooltipTemplate: TemplateRef<any>;
  @Input() fileUploadSources: FilestackSources[];
  @Input() textBoxOnly = false;

  @Output() changeStage = new EventEmitter();
  @Output() publish = new EventEmitter();
  @Output() saveAsDraft = new EventEmitter<void>();
  @Output() showPostPreview = new EventEmitter();
  @Output() fileUploadSuccess = new EventEmitter<
    FileUploadFile[] | FilestackFile[]
  >();
  @Output() fileUploadError = new EventEmitter();

  @ViewChild('linkConfigTooltip') linkConfigTooltip: TooltipDirective;
  @ViewChild('linkedInImageTooltip') linkedInImageTooltip: TooltipDirective;
  @ViewChild(TextInputHighlightComponent)
  textInputHighlight: TextInputHighlightComponent;
  @ViewChild(TextInputAutocompleteDirective)
  textInputAutocomplete: TextInputAutocompleteDirective;
  @ViewChild('textarea') textarea: ElementRef;
  @ViewChild('singleImageUploader') singleImageUploader: FileUploaderDirective;
  @ViewChild('linkHighlightTagHoverTooltip')
  linkHighlightTagHoverTooltip: TooltipDirective;
  @ViewChild('mentionClickContextMenu')
  mentionClickContextMenu: ContextMenuComponent;
  @ViewChild('publisherActionsAndMedia')
  publisherActionsAndMedia: PublisherActionsAndMediaComponent;

  tooltipTemplate: ElementRef;
  stage: Stage = Stage.ActionsAndMedia;
  stages = Stage;
  mediaCategory = MediaCategory;
  linkConfigTooltipHost: HTMLElement;
  fileUploadingActive = false;
  showLinkWarning = false;
  showLinkPreview = false;
  linkedInLink = '';
  activeLink: OutboxPublisherLink;
  linkPreviewLoadingTracker = this.asyncTrackerFactory.create();
  fileUploadActive: OutboxFileType;
  OutboxFileType = OutboxFileType;
  mentionsMenuComponent = PublisherMentionsAutocompleteMenuComponent;
  highlightTagHoverElement: HTMLElement;
  textareaInitialised = false;
  userToggledActiveLink = false;
  editTitle: boolean;
  hasWritingToolEnabled = false;
  showAiContextMenu = false;
  expiryDateToggle = false;
  supportsContentExpiry = false;
  isMobile = false;
  disablePublish = false;
  videoVisibility = 'public';

  tags: Tag[] = [];
  selectedTags: Tag[] = [];
  createTagButtonVisible = false;
  locationInput: string;
  locationTags: ProfileSearchResult[];
  selectedLocationProfile: ProfileSearchResult;
  searchingLocation = false;

  destroyed$ = new Subject<void>();

  private _canSetCustomLinkedInImage: boolean = false;
  private _user;

  debouncedGetAutoCompleteMentions = debounce(async (searchText: string) => {
    const results = await this.post.getAutocompleteProfiles(searchText);
    return results.slice(0, MAX_MENU_MENTIONS);
  }, MENTIONS_AUTO_COMPLETE_DEBOUNCE);

  getAutocompleteMentions = (searchText: string) => {
    if (this.post.autocompleteProfilesAvailable() && searchText) {
      return this.debouncedGetAutoCompleteMentions(searchText);
    } else {
      return [];
    }
  };

  constructor(
    private asyncTrackerFactory: AsyncTrackerFactory,
    private cdr: ChangeDetectorRef,
    private company: CompanyService,
    private filestack: FilestackService,
    private userModel: UserModel,
    private outboxTagsService: OutboxTagsService,
    public modal: NgbModal,
    private device: DeviceService,
    @Inject(PUBLISHER_ACTIVE) public publisherActive: PublisherActive
  ) {}

  public get canSetCustomLinkedInImage() {
    return this._canSetCustomLinkedInImage;
  }

  ngOnChanges(changes) {
    if (changes.slideAnimationCompleted && this.slideAnimationCompleted) {
      this.refreshTextInputHighlight();
    }
    // fix needed for outdated matt component we can't update
    this.post.text = this.post.text;
    // console.log(changes, this.post);
  }

  async ngOnInit() {
    this.device.isMobile$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isMobile) => {
        this.isMobile = isMobile;
      });

    this.supportsContentExpiry = await this.company.hasFeatureAccess(
      'CONTENT_EXPIRY_SUPPORT'
    );

    this.outboxTagsService.postTagsStore.value$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((tags) => {
        // console.log('TAGS: ', tags);
        this.tags = tags;

        this.selectedTags = this.post.tags
          .map((tagName) => {
            return this.outboxTagsService.postTagsStore.find(tagName, 'name');
          })
          .filter((tag) => !!tag);
      });

    try {
      this._canSetCustomLinkedInImage = !!(await this.company.hasFeatureAccess(
        'LINKEDIN_LINK_IMAGE_POST'
      ));
      this.hasWritingToolEnabled = !!(await this.company.hasFeatureAccess(
        'COMPOSER_WRITING_TOOL'
      ));

      this._user = await this.userModel.getAuthUser();

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  supportsCustomLinkOption() {
    return this.post.accounts.every((acc) =>
      ['4', '8'].includes(acc.account_type_id)
    );
  }

  refreshTextInputHighlight() {
    setTimeout(() => {
      this.textInputHighlight.refresh();
    });
  }

  textareaValueChanged() {
    this.linkConfigTooltip.hide();
    // for some reason IE11 doesn't initialise text styles until it has a value
    if (this.post.text && !this.textareaInitialised) {
      this.textareaInitialised = true;
      this.refreshTextInputHighlight();
      this.post.text = this.post.text;
    }
  }

  imageUploadStarted() {
    if (this.fileUploadActive) {
      throw new Error('File upload already in progress');
    }
    this.fileUploadActive = OutboxFileType.Image;
  }

  videoUploadStarted() {
    if (this.fileUploadActive) {
      throw new Error('File upload already in progress');
    }
    this.fileUploadActive = OutboxFileType.Video;
  }

  gifUploadStarted() {
    if (this.fileUploadActive) {
      throw new Error('File upload already in progress');
    }
    this.fileUploadActive = OutboxFileType.Gif;
  }

  fileUploadComplete() {
    this.fileUploadActive = undefined;
    this.disablePublish = true;
  }

  onUploadSuccess(files: FileUploadFile[]): void {
    this.fileUploadSuccess.emit(files);
    this.disablePublish = false;
  }

  gifUploadSuccess(gifObj: FilestackFile): void {
    this.fileUploadSuccess.emit([gifObj]);
    this.disablePublish = false;
  }

  onPickerClosed() {
    if (this.post.files.length === 0) {
      this.disablePublish = false;
    }
  }

  handleChangeStage(event) {
    if (isDebug) {
      console.groupCollapsed(`publisherLanding~>handleChangeStage`);

      console.log(`event:`);
      console.dir(event);
    }

    if (event.root) {
      this.changeStage.emit({ stage: event.stage });

      if (isDebug) {
        console.log(`returning - event.root set`);
        console.groupEnd();
      }

      return;
    }

    if (event.stage !== Stage.Targeting || this.post.features.targeting) {
      this.stage = event.stage;

      if (isDebug) {
        console.log(`returning - event.stage not stage.targeting`);
        console.groupEnd();
      }

      return;
    }

    if (isDebug) {
      console.groupEnd();
    }
  }

  async highlightEntityClicked(
    event: PublisherHighlightTagEvent
  ): Promise<void> {
    const linkedInIds = ['4', '8'];
    this.editTitle = linkedInIds.some((id) =>
      this.post.accounts.map((post) => post.account_type_id).includes(id)
    );

    if (event.tag.data.type === OutboxPublisherHighlightType.Mention) {
      this.mentionClickContextMenu.show((event as any).event, event);
    } else {
      this.linkConfigTooltipHost = event.target;
      this.showLinkPreview = false;
      this.showLinkWarning = false;
      this.activeLink = event.tag as OutboxPublisherLink;

      if (
        !this.activeLink.data.preview.title ||
        !this.activeLink.data.preview.description ||
        !this.activeLink.data.preview.selectedImageIndex
      ) {
        this.getLinkPreview();
      }

      if (
        this.activeLink.data.preview.title ||
        this.activeLink.data.preview.description ||
        this.activeLink.data.preview.selectedImageIndex
      ) {
        this.showLinkPreview = true;
      }

      this.linkConfigTooltip.show();
    }
  }

  editMention(event: PublisherHighlightTagEvent): void {
    this.mentionClickContextMenu.hide();
    this.textInputAutocomplete.editChoice((event as any).tag.data);
  }

  public async addLinkedInImageUrl() {
    try {
      this.fileUploadingActive = true;
      const client = await this.filestack.getClient();
      const customSourcePath: string = `company-files/${this._user.company_id}/`;
      const options: FilestackPickOptions = {
        accept: acceptedFileTypesForImages,
        customSourcePath,
        disableTransformer: true,
        fromSources: permittedSources,
        maxFiles: 1,
        maxSize: this.post.mediaRestrictions.image.size.max,
        onUploadDone: (result) => {
          try {
            if (
              !(
                !!result &&
                !!result.filesUploaded &&
                !!result.filesUploaded.length
              )
            ) {
              throw new Error(`Unexpected result when trying to upload image!`);
            }

            const file = result.filesUploaded[0];
            if (file.size >= maximumFileSizeForImagesInBytes) {
              console.error(`File over size limit.`);

              return true;
            }

            const url: string = file.url;
            this.activeLink.data.preview.images.push({ url });
            this.activeLink.data.preview.selectedImageIndex =
              this.activeLink.data.preview.images.length - 1;

            return true;
          } catch (error) {
            console.error(error);

            return false;
          }
        },
        onClose: () => {
          this.fileUploadingActive = false;
        },
        customSourceName: CUSTOM_SOURCE_NAME
      };

      client.picker(options).open();

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
    this.fileUploadingActive = false;
  }

  handleOffClick() {
    if (!this.fileUploadingActive && !this.modal.hasOpenModals()) {
      this.linkConfigTooltip.hide();
    } else {
      return;
    }
  }

  getLinkPreview() {
    this.showLinkPreview = true;
    this.linkPreviewLoadingTracker.add(
      Promise.resolve(this.post.getLinkPreview(this.activeLink))
    );
  }

  highlightTagMouseEnter(event: PublisherHighlightTagEvent) {
    event.target.classList.add('publisher-link-tag-hover');
    if (event.tag.data.type === OutboxPublisherHighlightType.Link) {
      this.highlightTagHoverElement = event.target;
      this.cdr.detectChanges();
      this.linkHighlightTagHoverTooltip.show();
    }
  }

  highlightTagMouseLeave(event: PublisherHighlightTagEvent) {
    event.target.classList.remove('publisher-link-tag-hover');
    this.linkHighlightTagHoverTooltip.hide();
    this.highlightTagHoverElement = undefined;
  }

  getSelectedChoices(): Array<{ username: string; id: string }> {
    return this.post.mentions.map((m) => {
      return m.data;
    });
  }

  getChoiceLabel(choice: { username: string; id: string }) {
    return OutboxPublisher.getMentionLabel(choice);
  }

  utmTrackingForcedOff = false;

  toggleLinkTracking(): void {
    this.userToggledActiveLink = true;
    this.showLinkWarning = false;

    this.activeLink.data.shorten = !this.activeLink.data.shorten;

    if (!this.activeLink.data.shorten) {
      if (this.activeLink.data.utm.enabled) {
        this.activeLink.data.utm.enabled = false;
        this.utmTrackingForcedOff = true;
      }
    } else {
      if (this.utmTrackingForcedOff) {
        this.activeLink.data.utm.enabled = true;
      }
    }

    this.post.updateCharactersRemaining();
  }

  utmTrackingDisabled(): boolean {
    return !this.activeLink.data.shorten && !this.activeLink.data.utm.enabled;
  }

  toggleUtmTracking(): void {
    if (this.utmTrackingDisabled()) {
      return;
    }

    this.userToggledActiveLink = true;
    this.utmTrackingForcedOff = false;

    this.activeLink.data.utm.enabled = !this.activeLink.data.utm.enabled;
  }

  checkTikTokAccountSelected(): Account {
    return this.post.accounts.find(
      (account) => account.account_type_id === '16'
    );
  }

  checkYoutubeAccountSelected(): Account {
    return this.post.accounts.find(
      (account) => account.account_type_id === '6'
    );
  }

  openAiContextMenu() {
    if (this.hasWritingToolEnabled) {
      this.showAiContextMenu = true;
    }
    return;
  }

  openAiWritingToolModal(variant: 'generate' | 'rephrase' | 'hashtags') {
    if ((variant === 'rephrase' || variant === 'hashtags') && !this.post.text) {
      return;
    }
    this.showAiContextMenu = false;
    const modal = this.modal.open(AiWritingToolModalComponent, {
      windowClass: 'orlo-modal'
    });
    modal.componentInstance.variant = variant;
    modal.componentInstance.topicText = this.post.text;
    modal.componentInstance.twitterSelected = this.post.accounts.some(
      (acc) => acc.account_type_id === '2'
    );

    modal.result.then(async (suggestedText) => {
      if (suggestedText) {
        this.post.text =
          variant === 'hashtags'
            ? this.post.text + ' ' + suggestedText
            : suggestedText;
      }
    });
  }

  postTagsChange(selectedTags: Tag[]): void {
    // console.log('post.tags: ', this.post.tags);
    this.post.tags = selectedTags.map((t) => t.name);
  }

  onOptionsFiltered(params: {
    filterTerm: string;
    filteredOptions: Tag[];
  }): void {
    const optionExists = params.filteredOptions.some(
      (o) => o.name === params.filterTerm
    );
    this.createTagButtonVisible = !optionExists;
  }

  createTag(name: string): void {
    this.outboxTagsService.createTag(name, TagType.Post).then((createdTag) => {
      this.selectedTags.push(this.tags.find((t) => t.id === createdTag.id));
      this.postTagsChange(this.selectedTags);
    });
  }

  setPostExpiryDate() {
    this.expiryDateToggle = !this.expiryDateToggle;
    this.post.delete_at = this.expiryDateToggle ? new Date() : undefined;
  }

  onKeyUp(event: KeyboardEvent): void {
    if (event.keyCode === 13 || event.key === 'Enter') {
      this.searchLocation();
    }
  }

  async searchLocation() {
    this.searchingLocation = true;
    this.locationTags = null;
    this.locationTags = await this.post.getLocationTags(this.locationInput);
    this.searchingLocation = false;
  }

  setLocationTag(profileWithLocation: ProfileSearchResult) {
    if (this.selectedLocationProfile === profileWithLocation) {
      this.selectedLocationProfile = null;
      this.post.profileWithLocation = null;
    } else {
      this.selectedLocationProfile = profileWithLocation;
      this.post.profileWithLocation = profileWithLocation;
    }
  }

  getInstagramStoryMimetypes(): string[] {
    return [
      ...this.post.mediaRestrictions.imageStory.mimetypes,
      ...this.post.mediaRestrictions.videoStory.mimetypes
    ];
  }

  getInstagramCarouselMimetypes(): string[] {
    return [
      ...this.post.mediaRestrictions.image.mimetypes,
      ...this.post.mediaRestrictions.video.mimetypes
    ];
  }

  async setIsSplit(isSplit: boolean): Promise<void> {
    if (
      !isSplit &&
      this.post.isSplit &&
      this.post.splitPostAccount.isInstagram() &&
      !this.post.accounts.every((a) => a.isInstagram())
    ) {
      const igAcc = this.post.splitPostAccount;
      if (this.hasInstagramMediaThatCannotBeCombined(igAcc)) {
        await this.showConflictingMediaAttachmentsDialog();
        return;
      }
      // when going from split mode to combine mode and there are some instagram specific media (e.g. Reel), and there are other networks selected ->
      // change the category to "Post" so e.g. reel gets treated universaly (as a standard video), like for all other networks
      const files = this.post.getFilesForAccount(igAcc);
      files.forEach((f) => {
        f.mediaCategory = MediaCategory.Post;
      });
    }

    this.post.isSplit = isSplit;
  }

  hasInstagramMediaThatCannotBeCombined(igAcc: Account): boolean {
    const files = this.post.getFilesForAccount(igAcc);
    const hasInstagramSpecificMedia = files.some(
      (f) => f.mediaCategory === MediaCategory.Story
      // || f.mediaCategory === MediaCategory.Reel // TODO: reel can stay and be converted to standrad video post?
    );
    const hasMixOfImagesAndVideos =
      files.some((f) => f.type === OutboxFileType.Image) &&
      files.some((f) => f.type === OutboxFileType.Video);
    const hasMultipleVideos =
      files.filter((f) => f.type === OutboxFileType.Video).length > 1;

    return (
      hasInstagramSpecificMedia || hasMixOfImagesAndVideos || hasMultipleVideos
    );
  }

  async showConflictingMediaAttachmentsDialog(): Promise<boolean> {
    const confirmationModal = this.modal.open(ConfirmationModalComponent, {
      windowClass: 'orlo-modal'
    });
    // confirmationModal.componentInstance.icon = 'ssi ssi-paper-clip';
    confirmationModal.componentInstance.title = 'Conflicting Media Attachments';
    confirmationModal.componentInstance.info = `You cannot combine a post that has Instagram specific media types attached, like a Story or Reel with other Networks. Remove your Instagram media attachments you have uploaded first to be able to combine your post.`;
    // confirmationModal.componentInstance.cancelButton = `Don't show again`;
    confirmationModal.componentInstance.confirmButton = `Okay thanks!`;

    return confirmationModal.result;
  }
}
