<div class="checkbox-2-container {{variant}}"
	 [class.reversed]="reversed">
	<input #inputRef
		   type="checkbox"
		   [id]="id"
		   [disabled]="disabled"
		   [checked]="modelValue"
		   [value]="modelValue"
		   (change)="inputChange($event)">
	<div class="checkbox"
		 [class.checked]="modelValue"
		 [class.disabled]="disabled"
		 (click)="checkboxClick($event)"></div>

	<i *ngIf="modelValue"
	   class="ssi ssi-tick-small"
	   [class.checked]="modelValue"
	   [class.disabled]="disabled"
	   (click)="checkboxClick($event)"></i>

	<label *ngIf="!tooltipMessage"
		   [for]="id"
		   [class.checked]="modelValue && highlightLabel"
		   [class.disabled]="disabled">{{label}}</label>
	<label *ngIf="tooltipMessage"
		   [for]="id"
		   [class.checked]="modelValue && highlightLabel"
		   [class.disabled]="disabled"
		   [ssiTooltip]="tooltipMessage"
		   [tooltipAppendToBody]="true"
		   tooltipWindowClass="tooltip-chunky">{{label}}</label>
</div>