export default Object.freeze({
  'ssi-screen-width-sm': '1350px',
  'ssi-screen-height-sm': '850px',
  'ssi-brand-primary': '#14BAE3',
  'sentiment-positive-color': '#54bdcd',
  'sentiment-semi-positive-color': '#89d0db',
  'sentiment-neutral-color': '#D6DFF7',
  'sentiment-semi-negative-color': '#e98683',
  'sentiment-negative-color': '#F88C68'
});
