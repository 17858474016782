import angular from 'angular';
import { default as uiRouter, UrlService } from '@uirouter/angularjs';
import angularTranslate from 'angular-translate';
import angularTranslateHandlerLog from 'angular-translate-handler-log';
import angularTranslateLoaderUrl from 'angular-translate-loader-static-files';
import dynamicLocale from 'exports-loader?"tmh.dynamicLocale"!angular-dynamic-locale';
import satellizer from 'satellizer';
import { DEFAULT_TRANSLATE_LANGUAGE } from '../../angular/common/constants';
import { ErrorHandlerService } from '../../angular/common/services/error-handler/error-handler.service';
import { apiAuthHeader } from '@ui-resources-angular';
import { API_ENDPOINT } from '../../angular/environment';

export default angular
  .module('common.config', [
    uiRouter,
    'ui.router.upgrade',
    angularTranslate,
    angularTranslateHandlerLog,
    angularTranslateLoaderUrl,
    dynamicLocale,
    satellizer
  ])
  .config(($urlServiceProvider: UrlService) => {
    'ngInject';
    $urlServiceProvider.deferIntercept();
  })
  .config(($urlRouterProvider, $urlMatcherFactoryProvider) => {
    'ngInject';
    $urlRouterProvider.otherwise(($injector) => {
      const $auth = $injector.get('$auth');
      const redirect = $injector.get('redirect');

      if ($auth.isAuthenticated()) {
        redirect.login();
      } else {
        redirect.logout();
      }
    });

    $urlMatcherFactoryProvider.strictMode(false); // allow trailing slashes in urls
  })
  .config(($provide) => {
    'ngInject';
    $provide.decorator('$exceptionHandler', ($delegate, $injector, trackJs) => {
      return (exception, cause) => {
        trackJs.track(exception);
        $delegate(exception, cause);
      };
    });
  })
  .config(($translateProvider, staticFilesEndpoint) => {
    'ngInject';
    $translateProvider
      .statefulFilter(false)
      .determinePreferredLanguage(() => DEFAULT_TRANSLATE_LANGUAGE)
      .useSanitizeValueStrategy('sanitizeParameters')
      .useMissingTranslationHandlerLog()
      .fallbackLanguage(DEFAULT_TRANSLATE_LANGUAGE)
      .useStaticFilesLoader({
        prefix: `${staticFilesEndpoint}/translations/`,
        suffix: '.json'
      });
  })
  .config(($httpProvider) => {
    'ngInject';
    // Combine processing of multiple http responses received at around the same time
    $httpProvider.useApplyAsync(true);
    $httpProvider.defaults.paramSerializer = '$httpParamSerializerJQLike';
  })
  .config(($compileProvider) => {
    'ngInject';
    // Disable runtime debug information in the compiler
    $compileProvider.debugInfoEnabled(false);
  })
  .config(($locationProvider) => {
    'ngInject';
    $locationProvider.hashPrefix('');
  })
  .config(($qProvider) => {
    'ngInject';
    $qProvider.errorOnUnhandledRejections(false);
  })
  .config(($httpProvider) => {
    'ngInject';
    $httpProvider.interceptors.push(
      ($q: angular.IQService, $injector: angular.auto.IInjectorService) => {
        'ngInject';

        return {
          responseError(response: angular.IHttpPromiseCallbackArg<any>) {
            $injector.get<ErrorHandlerService>('errorHandler').http(response);
            return $q.reject(response); // error not handled
          }
        };
      }
    );
    $httpProvider.interceptors.push(($q) => {
      return {
        request(config) {
          const whitelistedAuthUrls = [
            'https://app.socialsignin.co.uk',
            'https://beta.socialsignin.co.uk'
          ];
          const shouldSendApiAuthHeader = whitelistedAuthUrls.some((url) =>
            config.url.startsWith(url)
          );

          if (!shouldSendApiAuthHeader) {
            config.headers[apiAuthHeader] = undefined;
          }
          return config;
        },

        response(response) {
          return response;
        },

        responseError(err) {
          return $q.reject(err);
        }
      };
    });
  })
  .config((tmhDynamicLocaleProvider) => {
    'ngInject';
    tmhDynamicLocaleProvider.localeLocationPattern(
      `https://code.angularjs.org/${angular.version.full}/i18n/angular-locale_{{ locale }}.js`
    );
  })
  .config(($authProvider) => {
    'ngInject';
    $authProvider.tokenName = 'auth_token';
    $authProvider.loginUrl = '/user/login';
    $authProvider.tokenHeader = apiAuthHeader;
    $authProvider.baseUrl = API_ENDPOINT;
  });
