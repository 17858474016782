import './post.component.scss';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {
  API,
  Outbox,
  OutboxPublisherMention,
  AccountModel,
  Account,
  User,
  UserModel,
  CampaignModel
} from '@ui-resources-angular';
import moment from 'moment';
import { StateService } from '@uirouter/angular';
import { PopupService } from '../../services/popup/popup.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OutboxCampaignModalComponent } from '../outbox-campaign-modal/outbox-campaign-modal.component';

@Component({
  selector: 'ssi-post',
  templateUrl: './post.component.html'
})
export class PostComponent implements OnInit {
  @Input() colleagues: any[];
  @Input() post: Outbox | any;
  @Input() postType: 'post' | 'objective';
  @Output() deletePost = new EventEmitter<string>();
  @Output() closeModal = new EventEmitter<string>();

  account: Account;
  authUser: User;

  postData = {
    author: {
      fullName: '',
      avatar: ''
    },
    socialMediaAccount: {
      icon: '',
      displayName: '',
      userName: ''
    },
    campaign: {
      name: ''
    },
    createdDate: '',
    createdTime: ''
  };
  media;
  mediaStack = false;
  postMenu = false;
  mentions: OutboxPublisherMention[] = [];

  constructor(
    private accountModel: AccountModel,
    private campaignModel: CampaignModel,
    private popup: PopupService,
    private api: API,
    private state: StateService,
    private modal: NgbModal,
    private userModel: UserModel
  ) {}

  async ngOnInit() {
    this.account = this.accountModel.get(this.post.account_id);
    this.authUser = await this.userModel.getAuthUser();
    const author = this.colleagues.filter(
      (colleague) => colleague.id === this.post.user_id
    )[0];
    const currentCampaign = this.campaignModel.get(this.post.campaign_id);

    this.assignPostData(author, this.account, currentCampaign);
    this.assignMedia();

    this.mentions = this.post.getMentions ? this.post.getMentions() : [];
  }

  assignPostData(author, socialAccount, currentCampaign) {
    if (author) {
      this.postData.author = {
        fullName: author.fullName,
        avatar: author.avatar_url
      };
    }
    if (socialAccount) {
      this.postData.socialMediaAccount = {
        icon: socialAccount.socialNetwork.icon.web,
        displayName: socialAccount.displayName,
        userName: socialAccount.username
      };
    }
    if (currentCampaign) {
      this.postData.campaign = { name: currentCampaign.name };
    }
    this.postData.createdDate = moment(this.post.created_at).format(
      'DD MMM YYYY'
    );
    this.postData.createdTime = moment(this.post.created_at).format('HH:mm');
  }

  assignMedia() {
    if (this.post.outbox_files.length > 0) {
      this.media = Array.of(this.post.outbox_files[0].public_url);
    }
    if (this.post.outbox_files.length > 1) {
      this.mediaStack = true;
      this.media = this.post.outbox_files.map((image) => image.public_url);
    }
  }

  boostPost() {
    if (this.postType === 'post') {
      this.closeModal.emit();
    }
    this.state.go('auth.marketing.advertising.boostPost', {
      outboxId: this.post.id
    });
  }

  async changeCampaignModal() {
    const modal = this.modal.open(OutboxCampaignModalComponent, {
      windowClass: 'orlo-modal modal-high-zindex',
      backdropClass: 'backdrop-high-zindex'
    });
    modal.componentInstance.post = this.post;
    const result = await modal.result;
    this.post.campaign_id = result;
  }

  async removePost() {
    const shouldDeletePost = await this.popup.confirm({
      title: 'Delete post',
      message: 'Would you like to delete this post?'
    });
    if (shouldDeletePost) {
      if (this.postType === 'post') {
        await this.post.remove();
        this.deletePost.emit();
      }
      if (this.postType === 'objective') {
        await this.api.post(
          'outbox/index',
          {},
          {
            params: {
              _method: 'DELETE',
              account_id: this.account.id,
              id: this.post.id
            }
          }
        );
        this.deletePost.emit(this.post.id);
      }
    }
  }
}
