<div
  (clickOutside)="onBlur()"
  [delayClickOutsideInit]="true"
  [class.disabled]="disabled"
  class="outbox-typeahead-container"
>
  <div
    class="choices-container"
  >
    <ul
      *ngIf="!! hasChoices"
      class="choices"
    >
      <li
        *ngFor="let choice of choices; let index = index"
        (mouseover)="select(index)"
        (click)="toggle($event, choice)"
        [class.disabled]="disabled"
      >
        {{ choice.label }}
      </li>
    </ul>

    <input
      class="input-hidden"
      type="text"
    >

    <input
      #suggestionsFilter
      (focus)="onFocus()"
      (keydown)="onKeydown($event)"
      [disabled]="disabled"
      [placeholder]="placeholderText"
      autofocus="false"
      class="suggestions-filter"
      type="text"
    >
  </div>
  
  <ul
    *ngIf="!!areSuggestionsVisible && !!isFocused"
    (clickOutside)="onBlur()"
    class="suggestions"
  >
    <li
      *ngFor="let suggestion of filteredSuggestions; let index = index"
      (click)="toggle($event, suggestion)"
      (mouseover)="select(index)"
      [class.disabled]="disabled"
      [class.active]="_indexOfSelection === index"
    >
      {{ suggestion.label }}
    </li>
  </ul>
</div>
