<div class="dd-container size-{{size}}">
  <ng-container *ngTemplateOutlet="templateRefs[DDTemplateSelectors.headButton]; context: {expanded: expanded, toggle: toggle}"></ng-container>
  <ng-container *ngIf="!templateRefs[DDTemplateSelectors.headButton] && !headless">
    <button type="button"
            [disabled]="disabled"
            class="dd-head variant-{{headButtonStyleVariant}}"
            (click)="toggle()">
      <ng-container *ngIf="!templateRefs[DDTemplateSelectors.headButtonLabel]">
        <ng-container *ngIf="placeholdersKeyword">
          <span *ngIf="multiple"
                [class.placeholder]="!modelValue?.length">{{modelValue?.length ? modelValue.length + ' ' + placeholdersKeyword : 'Select ' + placeholdersKeyword + '...'}}</span>
          <span *ngIf="!multiple"
                [class.placeholder]="!modelValue">{{(getOptionLabel ? getOptionLabel(modelValue) : findOptionLabel(modelValue)) || ('Select ' + placeholdersKeyword + '...')}}</span>
        </ng-container>
        <ng-container *ngIf="!placeholdersKeyword">
          <span *ngIf="multiple"
                [class.placeholder]="!modelValue?.length">{{modelValue?.length ? modelValue.length + ' selected' : 'Select...'}}</span>
          <span *ngIf="!multiple"
                [class.placeholder]="!modelValue">{{(getOptionLabel ? getOptionLabel(modelValue) : findOptionLabel(modelValue)) || 'Select...'}}</span>
        </ng-container>
      </ng-container>
      <ng-container *ngTemplateOutlet="templateRefs[DDTemplateSelectors.headButtonLabel]; context: {expanded: expanded, toggle: toggle}"></ng-container>

      <i class="ssi {{headButtonIcon}} button-icon"></i>
    </button>
  </ng-container>

  <div *ngIf="expanded"
       class="dd-body"
       [class.no-padding-top]="!!templateRefs[DDTemplateSelectors.bodyHeader]"
       [@slideFadeIn]
       [style.top]="yPlacement === 'top' ? yOffset : ''"
       [style.bottom]="yPlacement === 'bottom' ? yOffset : ''"
       [style.left]="xPlacement === 'left' ? xOffset : ''"
       [style.right]="xPlacement === 'right' ? xOffset : ''"
       [ngStyle]="bodyStyle">

    <div class="body-header-container"
         *ngIf="templateRefs[DDTemplateSelectors.bodyHeader]">
      <ng-container *ngTemplateOutlet="templateRefs[DDTemplateSelectors.bodyHeader]; context: {expanded: expanded, toggle: toggle}"></ng-container>
      <hr>
    </div>

    <div class="filter-container"
         *ngIf="filterable">
      <input #filterInputRef
             type="search"
             [(ngModel)]="filterTerm"
             (ngModelChange)="filter(filterTerm)"
             [placeholder]="filterPlaceholder ? filterPlaceholder : placeholdersKeyword ? 'Search ' + placeholdersKeyword + '...' : 'Search...'">
      <i class="ssi ssi-search-small"
         [class.active]="!!filterTerm"></i>
    </div>
    <hr>

    <div class="toggle-all-container"
         [class.filter-visible]="filterable"
         *ngIf="multiple && toggleAllEnabled">
      <ssi-checkbox-2 [label]="toggleAllLabel ? toggleAllLabel : placeholdersKeyword ? 'Select all ' + placeholdersKeyword : 'Select all'"
                      [variant]="'square'"
                      [reversed]="true"
                      [(ngModel)]="toggleAllChecked"
                      (ngModelChange)="toggleAllOptions($event)"></ssi-checkbox-2>
    </div>

    <ul #ulRef
        *ngIf="filteredOptions.length && groupBy">
      <ng-container *ngFor="let group of groups">
        <li class="group-separator">
          <div class="main-content">
            <span>{{group.label}}</span>
            <ssi-checkbox-2 *ngIf="groupBy.toggleAllEnabled"
                            [label]="'ALL ' + group.label"
                            [variant]="'square'"
                            [reversed]="true"
                            [(ngModel)]="group.toggleAllChecked"
                            (ngModelChange)="toggleAllGroupOptions($event, group)"></ssi-checkbox-2>
          </div>
        </li>
        <ng-container *ngTemplateOutlet="optionsTemplate; context: {options: group.options}"></ng-container>
      </ng-container>
    </ul>

    <ul #ulRef
        *ngIf="filteredOptions.length && !groupBy">
      <ng-container *ngTemplateOutlet="optionsTemplate; context: {options: filteredOptions}"></ng-container>
    </ul>

    <div *ngIf="!filteredOptions.length"
         class="empty-state-container">
      <ssi-no-results size="sm"
                      *ngIf="!templateRefs[DDTemplateSelectors.emptyState]"></ssi-no-results>

      <ng-container *ngTemplateOutlet="templateRefs[DDTemplateSelectors.emptyState]; context: {toggle: toggle}"></ng-container>
    </div>

    <ng-template #optionsTemplate
                 let-options="options">
      <li *ngFor="let option of options"
          [class.selected]="optionSelected(option)">
        <div class="main-content"
             (click)="selectOption(option);"
             [ssiTooltip]="getTooltipContent ? getTooltipContent(option) : ''"
             [tooltipDisabled]="!getTooltipContent"
             [tooltipDelay]="1000"
             tooltipWindowClass="tooltip-chunky">
          <div class="icon-container"
               *ngIf="option.icon">
            <i class="ssi {{option.icon}}"></i>
          </div>
          <div class="icon-container"
               *ngIf="templateRefs[DDTemplateSelectors.optionIcon]">
            <ng-container *ngTemplateOutlet="templateRefs[DDTemplateSelectors.optionIcon]; context: {option: option, toggle: toggle}"></ng-container>
          </div>

          <span *ngIf="!templateRefs[DDTemplateSelectors.optionLabel]">{{getOptionLabel ? getOptionLabel(option) : findOptionLabel(option)}}</span>
          <ng-container *ngTemplateOutlet="templateRefs[DDTemplateSelectors.optionLabel]; context: {option: option, toggle: toggle}"></ng-container>

          <div class="tick-container"
               [class.radio]="!multiple"
               [class.checked]="optionSelected(option)">
            <i class="ssi ssi-tick-small tick"></i>
          </div>
        </div>

        <div class="expanded-content-container"
             *ngIf="templateRefs[DDTemplateSelectors.expandedContent]">
          <ng-container *ngTemplateOutlet="templateRefs[DDTemplateSelectors.expandedContent]; context: {option: option, selected: optionSelected(option)}"></ng-container>
        </div>
      </li>
    </ng-template>


    <div class="body-footer-container"
         *ngIf="templateRefs[DDTemplateSelectors.bodyFooter]">
      <ng-container *ngTemplateOutlet="templateRefs[DDTemplateSelectors.bodyFooter]; context: {toggle: toggle}"></ng-container>
    </div>
  </div>
</div>