import angular from 'angular';
import {
  UserModel,
  WorkflowModel,
  AccountModel,
  User,
  Account,
  Workflow,
  API
} from '@ui-resources-angular';
import axios from 'axios';
import './navbar.component.scss';

import {
  customerSupportWidgetId,
  customerSupportWidgetNamespace,
  widgetEmissions
} from '../../../../../library/constants/live-chat';
import { AuthService } from '../../../../angular/common/services/auth/auth.service';
import {
  Component,
  OnInit,
  Inject,
  HostListener,
  OnDestroy
} from '@angular/core';
import { LiveChatService } from '../../../../angular/common/services/live-chat/live-chat.service';
import { Location } from '@angular/common';
import { NotificationManagerService } from '../../../../angular/common/services/notification-manager/notification-manager.service';
import { PluginService } from '../../../../angular/common/services/plugin/plugin.service';
import { PopupService } from '../../../../angular/common/services/popup/popup.service';
import {
  PublisherActive,
  PUBLISHER_ACTIVE
} from '../../../../angular/common/components/publisher/publisher-active';
import { StateService, TransitionService } from '@uirouter/angularjs';
import { TranslateService } from '@ngx-translate/core';
import { WidgetEmission } from '../../../../../library/interfaces/live-chat/widget-emission';
import { takeUntil } from 'rxjs/operators';
import { UserPreferencesService } from '../../services/user-preferences/user-preferences.service';
import { Subject, Subscription, interval } from 'rxjs';
import { STATIC_FILES_ENDPOINT } from '../../constants';
import { WorkflowManagerService } from '../../../common/services/workflow-manager/workflow-manager.service';
import { UpdateManagerService } from '../../services/update-manager/update-manager.service';
import { DeviceService } from '../../services/device/device.service';
import { CompanyService } from '../../services/company/company.service';
import { P } from '@angular/cdk/keycodes';
import { Dashboard, DashboardService } from '../../services/api/dashboard';

export interface TranslationLanguage {
  code: string;
  english_name: string;
  is_base_language: boolean;
  is_ready_to_publish: boolean;
  last_updated_at: string;
  local_name: string;
  translation_progress: string;
}
@Component({
  selector: 'ssi-navbar',
  templateUrl: './navbar.component.html'
})
export class NavbarComponent implements OnInit, OnDestroy {
  permissions;
  user;
  languages: TranslationLanguage[];
  navbarCollapsed = true;
  clickIsInsideNavbar = false;
  selectedDropdown = null;
  workflows: any[];
  currentWorkflow: any;
  currentLanguage;
  newVersionAvailable = false;
  isMobile = false;
  destroyed$ = new Subject();
  publisherOpen = false;
  doesWidgetHaveUpdatedConversations = false;
  orloInsightsEnabled = false;
  oldMonitoringDisabled = false;
  onlineTrainingDisabled = false;
  outboxValidationTagging = false;
  showPendoResourceGuide = true;
  adStatsEnabled = false;
  browserlessMode = false;
  $notificationCountSubscription: Subscription;
  dashboards: Dashboard[];

  constructor(
    private translate: TranslateService,
    private $state: StateService,
    private userModel: UserModel,
    public workflowModel: WorkflowModel,
    public userPreferences: UserPreferencesService,
    private accountModel: AccountModel,
    public updateManager: UpdateManagerService,
    public notificationManager: NotificationManagerService,
    private plugin: PluginService,
    @Inject(PUBLISHER_ACTIVE) private publisherActive: PublisherActive,
    private authService: AuthService,
    private liveChatService: LiveChatService,
    public workflowManager: WorkflowManagerService,
    private device: DeviceService,
    private company: CompanyService,
    protected dashboardService: DashboardService
  ) {
    'ngInject';
  }

  async ngOnInit() {
    this.$notificationCountSubscription = interval(60000)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.notificationManager.updateCurrentWorkflowTotals();
      });

    this.device.isMobile$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isMobile) => {
        this.isMobile = isMobile;
      });

    this.updatePermissions();
    this.userPreferences.getPreferences();

    this.currentLanguage = this.translate.currentLang;

    this.orloInsightsEnabled = await this.company.hasFeatureAccess(
      'ORLO_INSIGHTS'
    );

    this.oldMonitoringDisabled = await this.company.hasFeatureAccess(
      'HIDE_OLD_MONITORING'
    );

    this.onlineTrainingDisabled = await this.company.hasFeatureAccess(
      'HIDE_ONLINE_TRAINING'
    );

    this.outboxValidationTagging = await this.company.hasFeatureAccess(
      'OUTBOX_VALIDATION_TAGGING'
    );

    this.showPendoResourceGuide = await this.company.hasFeatureAccess(
      'PENDO_RESOURCE_GUIDE'
    );

    this.browserlessMode = localStorage.getItem('browserless-io') === 'true';

    if (this.browserlessMode) {
      this.showPendoResourceGuide = false;
    }

    this.checkForNewDashboards();

    this.publisherActive
      .pipe(takeUntil(this.destroyed$))
      .subscribe(({ isActive }) => {
        this.publisherOpen = isActive;
      });

    this.userModel.getAuthUser().then((authUser) => {
      this.user = authUser;
    });

    axios
      .get(`${STATIC_FILES_ENDPOINT}/translations/index.json`)
      .catch((error) =>
        console.error(`Unable to retrieve translations:`, error)
      )
      .then((res: any) => {
        this.languages =
          res.data &&
          res.data.filter(
            (language: TranslationLanguage) => language.is_ready_to_publish
          );
      });

    this.updateManager.newVersionAvailable$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.newVersionAvailable = true;
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  goHome() {
    if (this.$state.current.name.startsWith('auth.dashboard.view')) {
      return;
    }
    this.$state.go('auth.dashboard', null, { reload: true });
  }

  async checkForNewDashboards() {
    this.dashboardService.store.value$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((dashboards) => {
        this.dashboards = dashboards;
      });
  }

  async updatePermissions() {
    Promise.all([
      this.userModel.getAuthUser(),
      this.accountModel.findAccounts(this.workflowManager.getCurrentId())
    ]).then(([user, accounts]: [User, Account[]]) => {
      try {
        this.workflows = this.workflowModel.getAll();
        this.currentWorkflow = this.workflowManager.getCurrentId()
          ? this.workflowModel.get(this.workflowManager.getCurrentId())
          : null;

        const hasAccountPermission = (permissions) => {
          const accountIds = accounts.map((account) => [account.id]);
          return user.hasAccountPermission(accountIds, permissions);
        };

        const workflows: Workflow[] = this.workflows;

        if (!Array.isArray(workflows)) {
          throw new Error(
            `value for 'navbar workflows' not in expected format.`
          );
        }

        const hasAnyWorkflowAccountValidationPermission = workflows.find(
          (workflow) => {
            const accountIds = workflow.accounts.map((account) => [account.id]);
            return user.hasAccountPermission(accountIds, ['validate_posts']);
          }
        );

        this.permissions = {
          bulkUploadPosts: hasAccountPermission([
            ['post'],
            ['post_un-validated']
          ]),
          communicate: hasAccountPermission([['post'], ['post_un-validated']]),
          contentGenerator: hasAccountPermission([
            ['post'],
            ['post_un-validated']
          ]),
          inbox: hasAccountPermission('view_inbox'),
          manageCampaignAnalytics: user.hasCompanyPermission(
            'manage_campaigns'
          ),
          managePosts: hasAccountPermission([['post'], ['post_un-validated']]),
          monitoring: user.hasCompanyPermission('view_monitoring_streams'),
          viewMonitoring: user.hasCompanyPermission('view_monitoring_streams'),
          manageMonitoring: user.hasCompanyPermission(
            'manage_monitoring_streams'
          ),
          insights: user.hasCompanyPermission('view_insights'),
          validatePosts:
            user.hasCompanyPermission('validate_posts') ||
            hasAnyWorkflowAccountValidationPermission,
          viewAccountReport: user.hasCompanyPermission('view_account_report'),
          viewCampaignAnalytics: user.hasCompanyPermission(
            'view_campaign_analytics'
          ),
          viewCompetitorAnalytics: user.hasCompanyPermission(
            'view_competitor_analytics'
          ),
          viewEngagementAnalytics: user.hasCompanyPermission(
            'view_engagement_analytics'
          ),
          viewMarketingAnalytics: user.hasCompanyPermission(
            'view_marketing_analytics'
          ),
          viewSurveyReports: user.hasCompanyPermission('view_survey_reports'),
          viewTeamReport: user.hasCompanyPermission('view_team_report'),
          publisher: hasAccountPermission([['post'], ['post_un-validated']]),
          contentLibrary: user.hasCompanyPermission('manage_company_files'),
          contentCalendar: hasAccountPermission([
            ['post'],
            ['post_un-validated']
          ])
        };

        this.permissions.viewAnalytics =
          !!this.permissions.viewAccountReport ||
          !!this.permissions.viewCampaignAnalytics ||
          !!this.permissions.viewCompetitorAnalytics ||
          !!this.permissions.viewEngagementAnalytics ||
          !!this.permissions.viewMarketingAnalytics ||
          !!this.permissions.viewTeamReport;

        return true;
      } catch (error) {
        console.error(error);

        return false;
      }
    });
  }

  setLanguage = (code) => {
    this.translate.use(code);
    this.currentLanguage = code;
  };

  logout = () => {
    this.userModel.logout().finally(async () => {
      await this.liveChatService.logout();
      this.authService.clearJWT();
    });
  };

  changeWorkflow = (workflow) => {
    this.workflowManager
      .loadWorkflow(workflow.id)
      .then(() => this.$state.reload());
  };

  openPlugin = (pluginName) => {
    this.plugin.action('openPlugin', { name: pluginName });
  };

  toggleLiveChat = (event) => {
    window.postMessage(
      { id: `${customerSupportWidgetNamespace}-widget-toggle` },
      '*'
    );
  };

  toggleDropdownOpen = (dropdownName) => {
    this.selectedDropdown =
      this.selectedDropdown === dropdownName ? null : dropdownName;
  };

  isDropdownOpen = (dropdownName) => this.selectedDropdown === dropdownName;

  // Any click events will be within the component itself
  @HostListener('click')
  clicked() {
    this.clickIsInsideNavbar = true;
  }

  // Any click at all within the document will invoke this
  @HostListener('document:click')
  clickedOut() {
    if (!this.clickIsInsideNavbar) {
      this.selectedDropdown = null;
      this.navbarCollapsed = true;
    }
    this.clickIsInsideNavbar = false;
  }

  @HostListener('window:message', ['$event'])
  handleMessage(event: MessageEvent) {
    try {
      const message: WidgetEmission = event.data;

      if (!(!!message.id && !!message.data)) {
        return true;
      }

      switch (message.id) {
        case `${customerSupportWidgetNamespace}-${widgetEmissions.conversationUpdated}`:
          {
            this.doesWidgetHaveUpdatedConversations = !!message.data
              .hasUpdatedConversations;
          }
          break;
      }

      return true;
    } catch (error) {
      console.error('navbar handleMessage error:', error);

      return false;
    }
  }
}
