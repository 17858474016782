<div class="suggested-text-modal">
  <i class="ssi ssi-times close-icon"
     (click)="ignore()"></i>

  <div
    class="media-guidance"
    *ngIf="mediaNote"
    [ngClass]="{'guidance-only': !suggestedText}"
  >
    <i class="ssi ssi-media-guidance"></i>
    <h2>Media Guidance</h2>
    <h5>This is a some media guidance with this file:
    </h5>
    <p>"{{mediaNote}}"</p>
  </div>


  <div
    class="suggested-text"
    *ngIf="suggestedText"
    [ngClass]="{'suggested-text-only': !mediaNote}"
  >
    <i class="ssi ssi-suggested-post-text"></i>
    <h2 translate="ADD_SUGGESTED_POST_TEXT"></h2>
    <h5>
      The file you selected has the following suggested post text below, you
      can either add this suggested text to you existing post, or overwrite your post entirely which will replace any
      text you have currently typed.
    </h5>
    <p>
      "{{suggestedText}}"
    </p>
  </div>
  <div
    class="content-footer"
    *ngIf="suggestedText"
  >
    <orlo-button
      [text]="'Overwrite post'"
      (btnClick)="overwrite()"
      [textColor]="'#fff'"
      [bgColor]="'#838EAB'"
    ></orlo-button>
    <orlo-button
      [text]="'Add to post'"
      (btnClick)="addToPost()"
      [textColor]="'#fff'"
      [bgColor]="'#14BAE3'"
    ></orlo-button>
    <orlo-button
      [text]="'Ignore suggestion'"
      (btnClick)="ignore()"
      [textClass]="'uppercase'"
      [state]="'text'"
      [textColor]="'#43537F'"
    ></orlo-button>
  </div>

  <div
    class="content-footer"
    *ngIf="mediaNote && !suggestedText"
  >
    <orlo-button
      [text]="'Okay thanks!'"
      (btnClick)="ignore()"
      [textColor]="'#fff'"
      [bgColor]="'#14BAE3'"
    ></orlo-button>
  </div>
</div>