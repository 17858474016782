import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';

import { InsightsComponent } from './insights.component';
import { InsightsService } from './insights.service';

import { ThemeDetectorsModule } from './theme-detectors/theme-detectors.module';
import { WidgetsModule } from './widgets/widgets.module';
import { ReportsModule } from './reports/reports.module';
import { CommonInsightsModule } from './common/common-insights.module';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.insights',
          url: '/insights',
          component: InsightsComponent
        }
      ]
    }),
    ThemeDetectorsModule,
    WidgetsModule,
    ReportsModule,
    CommonInsightsModule
  ],
  declarations: [InsightsComponent],
  entryComponents: [InsightsComponent],
  providers: [InsightsService]
})
export class InsightsModule {}
