<div class="spacer-bottom row">
  <h2 class="alt-text-modal-header">Add your Alt Text</h2>
  <p>
    Alt Text describes your image or gif and helps Facebook, LinkedIn and X offer context to users who
    may have trouble seeing your content. Check out our <a
      href="https://support.orlo.tech/faq"
      target="_blank"
      rel="noopener noreferrer"
    >Help Centre</a> to read top tips on writing Alt Text.
  </p>
</div>

<div class="alt-text-modal-description">
  <textarea
    class="form-control"
    [(ngModel)]="altText"
    (ngModelChange)="updateCharacters($event)"
    [placeholder]="'Describe your image here...'"
    rows="5"
  >
  </textarea>
  <div class="alt-text-modal-character">
    <span
      class="font-weight-normal"
      [class.text-primary]="charactersRemaining > 0"
      [class.text-danger]="charactersRemaining < 0"
    >
      {{ charactersRemaining }}
    </span>
  </div>
</div>

<div class="row spacer-top">
  <div class="col-xs-6">
  </div>
  <div class="col-xs-6 text-right">
    <button
      class="btn btn-text"
      (click)="activeModal.close(null)"
    >
      Cancel
    </button>
    <button
      class="btn btn-text"
      (click)="save()"
      [disabled]="altText.length > maxCharacters"
    >
      Save
    </button>
  </div>
</div>