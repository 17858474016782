<div class="save-draft-modal">
  <div class="save-draft-modal-header">
    <div class="save-draft-modal-header-left">
      <h2>{{title}}</h2>
    </div>
    <div class="save-draft-modal-header-right">
      <button (click)="activeModal.close(false)" class="save-draft-modal-close">
        <i class="ssi ssi-addsmall"></i>
      </button>
    </div>
  </div>

  <div class="save-draft-modal-body">
    <p>{{meta}}</p>
    <div class="save-draft-modal-body-row">
      <label class="save-draft-modal-label" for="draftTitle">Draft Title</label>
      <div class="save-draft-modal-body-row-input">
        <input
          class="save-draft-modal-input"
          id="draftTitle"
          type="text"
          placeholder="Type your draft title here..."
          [disabled]="isDraftPublic && !userIsAdmin"
          [maxlength]="draftTitleMaxLength"
          [(ngModel)]="draftTitle"
          (ngModelChange)="onDraftTitleChange.emit($event)"
        >
        <span
          [ngClass]="{'save-draft-modal-body-row-input-meta-active': draftTitle?.length}"
          class="save-draft-modal-body-row-input-meta"
        >
          {{draftTitleMaxLength - draftTitle?.length}}
        </span>
      </div>
    </div>

    <div *ngIf="showPublicDraftOption" class="save-draft-modal-body-row">
      <ssi-toggle-switch-2
        label="Public Draft"
        [(ngModel)]="isDraftPublic"
        (ngModelChange)="onPublicDraftToggle.emit($event)"
      ></ssi-toggle-switch-2>
    </div>
  </div>

  <div class="save-draft-modal-footer">
    <div class="save-draft-modal-footer-left">
      <orlo-button
        *ngIf="showSecondaryAction"
        class="save-draft-modal-button save-draft-modal-button-link"
        text="Save as new draft"
        textColor="#43537F"
        bgColor="#fff"
        (click)="activeModal.close(1)"
      ></orlo-button>
    </div>
    <div class="save-draft-modal-footer-right">
      <orlo-button
        class="save-draft-modal-button save-draft-modal-button-secondary"
        text="Cancel"
        textColor="#43537F"
        bgColor="#fff"
        (click)="activeModal.close(false)"
      ></orlo-button>
      <orlo-button
        *ngIf="!isDraftPublic || isDraftPublic && userIsAdmin"
        class="save-draft-modal-button"
        [text]="primaryActionButtonText"
        textColor="#fff"
        bgColor="#14BAE3"
        (click)="activeModal.close(2)"
      ></orlo-button>
    </div>
  </div>
</div>