<div class="date-time-picker-wrap">
  <div ngbDropdown #pickerDropdown="ngbDropdown" autoClose="outside">
    <input
      ngbDropdownToggle
      type="text"
      class="form-control"
      [value]="selectedDate ? (selectedDate | date: (dateFormat || defaultDateFormat)) : ''"
      [placeholder]="placeholder || ''"
      [required]="required"
      (keypress)="onKeyDown($event)">
    <div ngbDropdownMenu>
      <dl-date-time-picker
        [(ngModel)]="selectedDate"
        (ngModelChange)="onSelectDate()"
        [selectFilter]="getSelectableDates"
        [leftIconClass]="['glyphicon', 'glyphicon-arrow-left']"
        [rightIconClass]="['glyphicon', 'glyphicon-arrow-right']"
        [startView]="datetimepickerConfig.startView"
        [maxView]="datetimepickerConfig.maxView"
        [minView]="datetimepickerConfig.minView">
      </dl-date-time-picker>
    </div>
  </div>
</div>
