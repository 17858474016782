import './message-box.component.scss';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  ViewChild,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { Team, Colleague } from '../../services/api';
import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { Account, Activity } from '@ui-resources-angular';

@Component({
  selector: 'ssi-message-box',
  templateUrl: './message-box.component.html',
  encapsulation: ViewEncapsulation.None
})
export class MessageBoxComponent implements OnInit, OnChanges {
  @Input() activity: Activity;
  @Input() isInbox = false;
  @Input() isActive?: boolean;
  @Input() isPrivate?: boolean;
  @Input() isRoot?: boolean;
  @Input() isActionable?: boolean;
  @Input() canReply?: boolean;
  @Input() meta?: any;
  @Input() avatar?: string;
  @Input() selfPenned?: boolean;
  @Input() isLeft?: true;
  @Input() pinned?: boolean;
  @Input() translatable?: boolean;
  @Input() attachments?: any;
  @Input() getStats?: any;
  @Input() stats?: any;
  @Input() tier?: number;
  @Input() retweetConfig?: any;
  @Input() deleted?: any;
  @Input() redacted?: any;
  @Input() isHidden?: boolean;
  @Input() enableActions?: boolean;
  @Input() actionLikeConfig?: any;
  @Input() account?: Account;
  @Input() conversation?;
  @Input() showConversationAssignTo?: boolean;
  @Input() conversationAssignedTo?: Colleague | Team;
  @Input() actionViewNatively?: boolean;
  @Input() actionHideCommentConfig?: any;
  @Input() actionDelete?: boolean;
  @Input() actionRedact?: boolean;
  @Input() linkPreview?: any;
  @Input() allTags?: string[];
  @Input() activeTags?: string[];
  @Input() sentiment?: number | boolean;
  @Input() notes?: any;
  @Input() unsupportedMessage?: boolean;
  @Input() mediaOnlyMessage?: boolean;
  @Input() retweetSuccess?: boolean;
  @Input() canSplitConversation?: boolean;
  @Input() isConvertedToActivity = false;

  @Output() changeSentiment = new EventEmitter<number>();
  @Output() translate = new EventEmitter<any>();
  @Output() untranslate = new EventEmitter<any>();
  @Output() pinToggle = new EventEmitter<boolean>();
  @Output() addNote = new EventEmitter<any>();
  @Output() changeActiveTags = new EventEmitter<string[]>();
  @Output() showNotes = new EventEmitter();
  @Output() toggleLikeClicked = new EventEmitter();
  @Output() viewNativelyClicked = new EventEmitter();
  @Output() toggleShowHideCommentClicked = new EventEmitter();
  @Output() retweet = new EventEmitter();
  @Output() quoteRetweet = new EventEmitter();
  @Output() deleteClicked = new EventEmitter();
  @Output() redactConfirmed = new EventEmitter();
  @Output() messageboxClicked = new EventEmitter();
  @Output() avatarClicked = new EventEmitter();
  @Output() splitConversationPanelStatus = new EventEmitter<boolean>();
  @Output() splitConversation = new EventEmitter<any>();
  @Output() conversationAssignToClicked = new EventEmitter();

  @ViewChild('actionsMenu') actionsMenu: CdkConnectedOverlay;

  hidden = false;
  showSplitPanel = false;
  actionsTriggerOrigin: any;
  showActions = false;
  tagsTooltip: string;
  actionsActiveItem = '';
  assignedSuccess: Colleague | Team;
  sentimentOptions = [
    {
      score: -0.75,
      label: 'Negative',
      icon: 'ssi-negative-new',
      class: 'message-box-message-action-sentiment-v-negative'
    },
    {
      score: -0.25,
      label: 'Semi Negative',
      icon: 'ssi-semi-negative-new',
      class: 'message-box-message-action-sentiment-negative'
    },
    {
      score: 0,
      label: 'Neutral',
      icon: 'ssi-neutral-new',
      class: 'message-box-message-action-sentiment-neutral'
    },
    {
      score: 0.25,
      label: 'Semi Positive',
      icon: 'ssi-semi-positive-new',
      class: 'message-box-message-action-sentiment-positive'
    },
    {
      score: 0.75,
      label: 'Positive',
      icon: 'ssi-positive-new',
      class: 'message-box-message-action-sentiment-v-positive'
    }
  ];

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.activeTags) {
      this.tagsTooltip =
        this.activeTags && this.activeTags.length
          ? '<ul>' +
            this.activeTags.map((tag) => `<li>${tag}</li>`).join('') +
            '</ul>'
          : '';
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activeTags && changes.activeTags.currentValue) {
      this.tagsTooltip =
        this.activeTags && this.activeTags.length
          ? '<ul>' +
            this.activeTags.map((tag) => `<li>${tag}</li>`).join('') +
            '</ul>'
          : '';
    }
  }

  updateSentiment(val) {
    this.sentiment = val;
    this.changeSentiment.emit(val);
  }

  updateActionsMenu(item) {
    this.actionsActiveItem = item;
    this.cdr.detectChanges();
    this.actionsMenu.overlayRef.updatePosition();
  }

  changeTags(tags) {
    this.activeTags = tags;
    this.changeActiveTags.emit(tags);
    this.tagsTooltip =
      this.activeTags && this.activeTags.length
        ? '<ul>' +
          this.activeTags.map((tag) => `<li>${tag}</li>`).join('') +
          '</ul>'
        : '';
  }

  showActionsChange(actionsConfig) {
    this.actionsActiveItem = actionsConfig.activeAction || '';
    this.showActions = actionsConfig.showActions;
    this.actionsTriggerOrigin = actionsConfig.origin;
  }

  toggleSplitPanel(event) {
    if (!this.canSplitConversation) {
      return;
    }
    event.stopPropagation();

    this.showSplitPanel = this.canSplitConversation
      ? !this.showSplitPanel
      : false;
    this.splitConversationPanelStatus.emit(this.showSplitPanel);
  }

  onCancel() {
    this.showSplitPanel = false;
    this.splitConversationPanelStatus.emit(this.showSplitPanel);
  }

  onSplitConversation() {
    this.splitConversationPanelStatus.emit(this.showSplitPanel);
    this.splitConversation.emit();
    this.showSplitPanel = false;
  }
}
