<div
  class="global-filters"
  [class.compact]="stylesMode === GlobalFiltersStyleMode.Compact"
>
  <div class="filters-body">
    <h3
      *ngIf="stylesMode !== GlobalFiltersStyleMode.Compact"
      class="filters-title"
      translate="ADD_FILTERS"
    ></h3>
    <div class="filter-form">
      <ssi-tags-input
        *ngIf="functionalityMode === GlobalFiltersFunctionalityMode.Compact"
        [tags]="keywordTags"
        [condition]="keywordTagsCondition"
        [disabled]="keywordTags.length"
        (onTagsChange)="onKeywordFilterChange($event)"
        [compact]="true"
      ></ssi-tags-input>
      <ul>
        <!-- Listening Period -->
        <li *ngIf="enabledFilters.listeningPeriod">
          <div
            class="filter-header"
            (click)="closeAllExpandedAndToggle('listeningPeriod');"
          >
            <i [class]="'leading-icon ' + filters.listeningPeriod.icon"></i>
            <span>{{filters.listeningPeriod.label}}</span>
            <div class="filter-header-right">
              <div
                class="cancel-filters"
                *ngIf="filters.listeningPeriod.dateFilterSelections?.label !== null"
                (click)="clearFilterSelections('listeningPeriod', $event)"
              >
                <i class="ssi ssi-delete-close"></i>
                {{ stylesMode === GlobalFiltersStyleMode.Compact ? 'Clear' : 'Clear filter'}}
              </div>
              <i
                class="ssi ssi-arrow-down-new"
                [class.flip]="filters.listeningPeriod.expanded"
              ></i>
            </div>
          </div>
          <div
            class="filter-options"
            *ngIf="filters.listeningPeriod.expanded"
          >
            <div class="form-row">
              <div class="form-field">
                <div class="input-wrapper">
                  <div
                    *ngFor="let range of dateRangesIterable"
                    class="inline-select-item"
                    [class.selected]="range.label === filters.listeningPeriod.dateFilterSelections?.label && !showCustomRange"
                    (click)="selectPresetDateRange(range)"
                  >
                    <i
                      *ngIf="range.label === filters.listeningPeriod.dateFilterSelections?.label && !showCustomRange"
                      class="ssi ssi-date-preset"
                    ></i>
                    <span>{{range.label}}</span>
                  </div>
                  <div
                    class="inline-select-item"
                    [class.selected]="showCustomRange"
                    (click)="showCustomRange = !showCustomRange"
                  >
                    <i
                      *ngIf="showCustomRange"
                      class="ssi ssi-date-preset"
                    ></i>
                    <span>Custom</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="form-row column no-margin"
              *ngIf="showCustomRange"
            >
              <div class="form-field half">
                <label
                  [class.compact]="stylesMode === GlobalFiltersStyleMode.Compact"
                  translate="START_DATE"
                ></label>
                <div
                  class="input-wrapper"
                  #startDateContainer
                >
                  <input
                    mwlFlatpickr
                    class="date-picker-input"
                    type="text"
                    name="start"
                    [ngModel]="filters.listeningPeriod.dateFilterSelections.start"
                    (ngModelChange)="onStartDatePicked($event)"
                    [altInput]="true"
                    [convertModelValue]="true"
                    [enableTime]="true"
                    [defaultHour]="00"
                    [defaultMinute]="00"
                    [defaultSeconds]="00"
                    dateFormat="Y-m-d H:i"
                    altFormat="M J Y, H:i"
                    [maxDate]="filters.listeningPeriod.dateFilterSelections.end"
                    [time24hr]="true"
                    [appendTo]="startDateContainer.nativeElement"
                    placeholder="Select..."
                    [required]="true"
                    (flatpickrOpen)="onFlatpickrOpen()"
                  >
                </div>
              </div>
              <div class="form-field half">
                <label
                  [class.compact]="stylesMode === GlobalFiltersStyleMode.Compact"
                  translate="END_DATE"
                ></label>
                <div
                  class="input-wrapper"
                  #endDateContainer
                >
                  <input
                    mwlFlatpickr
                    class="date-picker-input"
                    type="text"
                    name="end"
                    [ngModel]="filters.listeningPeriod.dateFilterSelections.end"
                    (ngModelChange)="onEndDatePicked($event)"
                    [altInput]="true"
                    [convertModelValue]="true"
                    [enableTime]="true"
                    [defaultHour]="23"
                    [defaultMinute]="59"
                    [defaultSeconds]="59"
                    dateFormat="Y-m-d H:i"
                    altFormat="M J Y, H:i"
                    [minDate]="filters.listeningPeriod.dateFilterSelections.start"
                    [maxDate]="today"
                    [time24hr]="true"
                    [appendTo]="endDateContainer.nativeElement"
                    placeholder="Select..."
                    [required]="true"
                    (flatpickrOpen)="onFlatpickrOpen()"
                  >
                </div>
              </div>
            </div>
          </div>
        </li>
        <!-- Channel (Account Type) -->
        <li *ngIf="enabledFilters.channel">
          <div
            class="filter-header"
            [class.filter-active]="filters.channel.filterSelections.length > 0"
            (click)="closeAllExpandedAndToggle('channel');"
          >
            <i [class]="'leading-icon ' + filters.channel.icon"></i>
            <span>{{filters.channel.name}}</span>
            <div class="filter-header-right">
              <div
                class="cancel-filters"
                *ngIf="filters.channel.filterSelections.length > 0"
                (click)="clearFilterSelections('channel', $event)"
              >
                <i class="ssi ssi-delete-close"></i>
                {{ stylesMode === GlobalFiltersStyleMode.Compact ? 'Clear' : generateFilterClearTag('channel') }}
              </div>
              <i
                class="ssi ssi-arrow-down-new"
                [class.flip]="filters.channel.expanded"
              ></i>
            </div>
          </div>
          <div
            class="filter-options"
            *ngIf="filters.channel.expanded"
          >
            <div class="form-row column">
              <div class="form-field">
                <ssi-checkbox-2
                  [reversed]="true"
                  [label]="'Facebook'"
                  [variant]="'square'"
                  [highlightLabel]="false"
                  [(ngModel)]="filters.channel.options.facebook"
                  (ngModelChange)="checkboxChecked($event,'facebook','channel')"
                >
                </ssi-checkbox-2>
              </div>
              <div class="form-field">
                <ssi-checkbox-2
                  [reversed]="true"
                  [label]="'Instagram'"
                  [variant]="'square'"
                  [highlightLabel]="false"
                  [(ngModel)]="filters.channel.options.instagram"
                  (ngModelChange)="checkboxChecked($event,'instagram_graph','channel')"
                >
                </ssi-checkbox-2>
              </div>
              <div class="form-field">
                <ssi-checkbox-2
                  [reversed]="true"
                  [label]="'Linkedin'"
                  [variant]="'square'"
                  [highlightLabel]="false"
                  [(ngModel)]="filters.channel.options.linkedin"
                  (ngModelChange)="checkboxChecked($event,'linkedin','channel')"
                >
                </ssi-checkbox-2>
              </div>
              <div class="form-field">
                <ssi-checkbox-2
                  [reversed]="true"
                  [label]="'Live Chat'"
                  [variant]="'square'"
                  [highlightLabel]="false"
                  [(ngModel)]="filters.channel.options.livechat"
                  (ngModelChange)="checkboxChecked($event,'livechat','channel')"
                >
                </ssi-checkbox-2>
              </div>
              <div class="form-field">
                <ssi-checkbox-2
                  [reversed]="true"
                  [label]="'Nextdoor'"
                  [variant]="'square'"
                  [highlightLabel]="false"
                  [(ngModel)]="filters.channel.options.nextdoor"
                  (ngModelChange)="checkboxChecked($event,'nextdoor','channel')"
                >
                </ssi-checkbox-2>
              </div>
              <div class="form-field">
                <ssi-checkbox-2
                  [reversed]="true"
                  [label]="'Press & Media'"
                  [variant]="'square'"
                  [highlightLabel]="false"
                  [(ngModel)]="filters.channel.options.moreover"
                  (ngModelChange)="checkboxChecked($event,'moreover','channel')"
                >
                </ssi-checkbox-2>
              </div>
              <div class="form-field">
                <ssi-checkbox-2
                  [reversed]="true"
                  [label]="'X'"
                  [variant]="'square'"
                  [highlightLabel]="false"
                  [(ngModel)]="filters.channel.options.twitter"
                  (ngModelChange)="checkboxChecked($event,'twitter','channel')"
                >
                </ssi-checkbox-2>
              </div>
              <div class="form-field">
                <ssi-checkbox-2
                  [reversed]="true"
                  [label]="'Youtube'"
                  [variant]="'square'"
                  [highlightLabel]="false"
                  [(ngModel)]="filters.channel.options.youtube"
                  (ngModelChange)="checkboxChecked($event,'youtube','channel')"
                >
                </ssi-checkbox-2>
              </div>
            </div>
          </div>
        </li>
        <!-- Accounts -->
        <li *ngIf="functionalityMode !== GlobalFiltersFunctionalityMode.Compact && enabledFilters.accounts">
          <div
            class="filter-header"
            (click)="closeAllExpandedAndToggle('accounts');"
          >
            <i [class]="'leading-icon ' + filters.accounts.icon"></i>
            {{filters.accounts.label}}
            <div class="filter-header-right">
              <div
                class="cancel-filters"
                *ngIf="accountFilterTotal > 0"
                (click)="clearFilterSelections('accounts', $event)"
              >
                <i class="ssi ssi-delete-close"></i>
                Clear {{accountFilterTotal}} filter{{accountFilterTotal === 1 ? '' : 's'}}
              </div>
              <i
                class="ssi ssi-arrow-down-new"
                [class.flip]="filters.accounts.expanded"
              ></i>
            </div>
          </div>
          <div
            class="filter-options"
            *ngIf="filters.accounts.expanded"
          >
            <div class="form-row column">
              <div class="form-field">
                <!-- <ssi-checkbox-2 [reversed]="true"
                                [label]="'Exclude All'"
                                [variant]="'square'"
                                [highlightLabel]="false"
                                [(ngModel)]="filters.accounts.options['Exclude All']"
                                (ngModelChange)="checkboxChecked($event, 'Exclude All', 'accounts', 'Exclude All')"
                                >
                </ssi-checkbox-2> -->
                <ssi-checkbox-2
                  [reversed]="true"
                  [label]="'Select All'"
                  [variant]="'square'"
                  [highlightLabel]="false"
                  [(ngModel)]="filters.accounts.options['Select All']"
                  (ngModelChange)="checkboxChecked($event, 'Select All', 'accounts')"
                >
                </ssi-checkbox-2>
              </div>
              <div
                class="form-field"
                *ngFor="let account of accounts; let index = index"
              >
                <ssi-social-network-icon
                  [accountTypeId]="account.account_type_id"
                  variant="fa"
                ></ssi-social-network-icon>
                <ssi-checkbox-2
                  [reversed]="true"
                  [label]="account.name"
                  [variant]="'square'"
                  [disabled]="filters.accounts.options['Exclude All'] === true"
                  [highlightLabel]="false"
                  [(ngModel)]="filters.accounts.options[account.id]"
                  (ngModelChange)="checkboxChecked($event, account.name, 'accounts', account.id)"
                >
                </ssi-checkbox-2>
              </div>
            </div>
          </div>
        </li>
        <!-- Streams -->
        <li *ngIf="functionalityMode !== GlobalFiltersFunctionalityMode.Compact && enabledFilters.savedStreams">
          <div
            class="filter-header"
            (click)="closeAllExpandedAndToggle('savedStreams');"
          >
            <i [class]="'leading-icon ' + filters.savedStreams.icon"></i>
            {{filters.savedStreams.label}}
            <div class="filter-header-right">
              <div
                class="cancel-filters"
                *ngIf="savedStreamFilterTotal > 0"
                (click)="clearFilterSelections('savedStreams', $event)"
              >
                <i class="ssi ssi-delete-close"></i>
                Clear {{savedStreamFilterTotal}} filter{{savedStreamFilterTotal === 1 ? '' : 's'}}
              </div>
              <i
                class="ssi ssi-arrow-down-new"
                [class.flip]="filters.savedStreams.expanded"
              ></i>
            </div>
          </div>
          <div
            class="filter-options"
            *ngIf="filters.savedStreams.expanded"
          >
            <div class="form-row column">
              <div class="form-field">
                <ssi-checkbox-2
                  [reversed]="true"
                  [label]="'Select All'"
                  [variant]="'square'"
                  [highlightLabel]="false"
                  [(ngModel)]="filters.savedStreams.options['Select All']"
                  (ngModelChange)="checkboxChecked($event, 'Select All', 'savedStreams')"
                >
                </ssi-checkbox-2>
              </div>
              <div
                class="form-field"
                *ngFor="let stream of monitoringStreams; let index = index"
              >
                <ssi-checkbox-2
                  [reversed]="true"
                  [label]="stream.name"
                  [variant]="'square'"
                  [disabled]="filters.savedStreams.options['Exclude All'] === true"
                  [highlightLabel]="false"
                  [(ngModel)]="filters.savedStreams.options[stream.name]"
                  (ngModelChange)="checkboxChecked($event, stream.name, 'savedStreams', stream.id)"
                >
                </ssi-checkbox-2>
              </div>
            </div>
          </div>
        </li>
        <!-- Visibility -->
        <li *ngIf="enabledFilters.isPrivate">
          <div
            class="filter-header"
            (click)="closeAllExpandedAndToggle('isPrivate');"
          >
            <i [class]="'leading-icon ' + filters.isPrivate.icon"></i>
            {{filters.isPrivate.label}}
            <div class="filter-header-right">
              <div
                class="cancel-filters"
                *ngIf="filters.isPrivate.filterSelections.length > 0"
                (click)="clearFilterSelections('isPrivate', $event)"
              >
                <i class="ssi ssi-delete-close"></i>
                {{ stylesMode === GlobalFiltersStyleMode.Compact ? 'Clear' : generateFilterClearTag('isPrivate') }}
              </div>
              <i
                class="ssi ssi-arrow-down-new"
                [class.flip]="filters.isPrivate.expanded"
              ></i>
            </div>
          </div>
          <div
            class="filter-options"
            *ngIf="filters.isPrivate.expanded"
          >
            <div class="form-row column">
              <div class="form-field">
                <ssi-checkbox-2
                  [reversed]="true"
                  [label]="'Private results'"
                  [variant]="'square'"
                  [highlightLabel]="false"
                  [(ngModel)]="filters.isPrivate.options.private"
                  (ngModelChange)="checkboxChecked($event,'Private results','isPrivate')"
                >
                </ssi-checkbox-2>
              </div>
              <div class="form-field">
                <ssi-checkbox-2
                  [reversed]="true"
                  [label]="'Public results'"
                  [variant]="'square'"
                  [highlightLabel]="false"
                  [(ngModel)]="filters.isPrivate.options.public"
                  (ngModelChange)="checkboxChecked($event,'Public results','isPrivate')"
                >
                </ssi-checkbox-2>
              </div>
            </div>
          </div>
        </li>
        <!-- Sentiment -->
        <li *ngIf="enabledFilters.sentiment">
          <div
            class="filter-header"
            (click)="closeAllExpandedAndToggle('sentiment');"
          >
            <i [class]="'leading-icon ' + filters.sentiment.icon"></i>
            {{filters.sentiment.label}}
            <div class="filter-header-right">
              <div
                class="cancel-filters"
                *ngIf="filters.sentiment.filterSelections.length > 0"
                (click)="clearFilterSelections('sentiment', $event)"
              >
                <i class="ssi ssi-delete-close"></i>
                {{ stylesMode === GlobalFiltersStyleMode.Compact ? 'Clear' : generateFilterClearTag('sentiment') }}
              </div>
              <i
                class="ssi ssi-arrow-down-new"
                [class.flip]="filters.sentiment.expanded"
              ></i>
            </div>
          </div>
          <div
            class="filter-options"
            *ngIf="filters.sentiment.expanded"
          >
            <div class="form-row column">
              <div
                class="form-field"
                *ngFor="let sentiment of sentimentsIterable; let index = index;"
              >
                <ssi-checkbox-2
                  [reversed]="true"
                  [label]="sentiment.labelNew | translate"
                  [variant]="'square'"
                  [highlightLabel]="false"
                  [(ngModel)]="filters.sentiment.options[sentiment.key2]"
                  (ngModelChange)="checkboxChecked($event, sentiment.key2, 'sentiment', sentiment)"
                ></ssi-checkbox-2>
              </div>
            </div>
          </div>
        </li>
        <!-- Emotion -->
        <li *ngIf="enabledFilters.emotion">
          <div
            class="filter-header"
            (click)="closeAllExpandedAndToggle('emotion');"
          >
            <i [class]="'leading-icon ' + filters.emotion.icon"></i>
            {{filters.emotion.label}}
            <div class="filter-header-right">
              <div
                class="cancel-filters"
                *ngIf="filters.emotion.filterSelections.length > 0"
                (click)="clearFilterSelections('emotion', $event)"
              >
                <i class="ssi ssi-delete-close"></i>
                {{ stylesMode === GlobalFiltersStyleMode.Compact ? 'Clear' : generateFilterClearTag('emotion') }}
              </div>
              <i
                class="ssi ssi-arrow-down-new"
                [class.flip]="filters.emotion.expanded"
              ></i>
            </div>
          </div>
          <div
            class="filter-options"
            *ngIf="filters.emotion.expanded"
          >
            <div class="form-row column">
              <h4>Positive Emotion</h4>
              <div
                class="form-field"
                *ngFor="let emotion of emotionsIterable | filter: {positive: true}; let index = index;"
              >
                <ssi-checkbox-2
                  [reversed]="true"
                  [label]="emotion.label"
                  [variant]="'square'"
                  [highlightLabel]="false"
                  [(ngModel)]="filters.emotion.options[emotion.key]"
                  (ngModelChange)="checkboxChecked($event, emotion.key, 'emotion', emotion.label)"
                ></ssi-checkbox-2>
              </div>
            </div>
            <h4>Negative Emotion</h4>
            <div class="form-row column">
              <div
                class="form-field"
                *ngFor="let emotion of emotionsIterable | filter: {positive: false}; let index = index;"
              >
                <ssi-checkbox-2
                  [reversed]="true"
                  [label]="emotion.label"
                  [variant]="'square'"
                  [highlightLabel]="false"
                  [(ngModel)]="filters.emotion.options[emotion.key]"
                  (ngModelChange)="checkboxChecked($event, emotion.key, 'emotion', emotion.label)"
                ></ssi-checkbox-2>
              </div>
            </div>
          </div>
        </li>
        <!-- Industries -->
        <li *ngIf="functionalityMode !== GlobalFiltersFunctionalityMode.Compact && enabledFilters.industries">
          <div class="filter-header" (click)="closeAllExpandedAndToggle('industries');">
            <i [class]="'leading-icon ' + filters.industries.icon"></i>
            {{filters.industries.label}}
            <div class="filter-header-right">
              <div class="cancel-filters"
                  *ngIf="filters.industries.filterSelections.length > 0"
                  (click)="clearFilterSelections('industries', $event)">
                  <i class="ssi ssi-delete-close"></i>
                  {{ stylesMode === GlobalFiltersStyleMode.Compact ? 'Clear' : generateFilterClearTag('industries') }}
              </div>
              <i class="ssi ssi-arrow-down-new" [class.flip]="filters.industries.expanded"></i>
            </div>
          </div>
          <div class="filter-options" *ngIf="filters.industries.expanded">
            <div class="form-row column">
              <div class="form-field">
                <div class="form-row column">
                  <div
                    class="form-field"
                    *ngFor="let industry of industriesIterable; let index = index;"
                  >
                    <ssi-checkbox-2
                      [reversed]="true"
                      [label]="industry.label"
                      [variant]="'square'"
                      [highlightLabel]="false"
                      [(ngModel)]="filters.industries.options[industry.key]"
                      (ngModelChange)="checkboxChecked($event, industry.key, 'industries', industry.label)"
                    ></ssi-checkbox-2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <!-- Gender -->
        <li *ngIf="enabledFilters.gender">
          <div
            class="filter-header"
            (click)="closeAllExpandedAndToggle('gender');"
          >
            <i [class]="'leading-icon ' + filters.gender.icon"></i>
            {{filters.gender.label}}
            <div class="filter-header-right">
              <div
                class="cancel-filters"
                *ngIf="filters.gender.filterSelections.length > 0"
                (click)="clearFilterSelections('gender', $event)"
              >
                <i class="ssi ssi-delete-close"></i>
                {{ stylesMode === GlobalFiltersStyleMode.Compact ? 'Clear' : generateFilterClearTag('gender') }}
              </div>
              <i
                class="ssi ssi-arrow-down-new"
                [class.flip]="filters.gender.expanded"
              ></i>
            </div>
          </div>
          <div
            class="filter-options"
            *ngIf="filters.gender.expanded"
          >
            <div class="form-row column">
              <div class="form-field">
                <ssi-checkbox-2
                  [reversed]="true"
                  [label]="'Male'"
                  [variant]="'square'"
                  [highlightLabel]="false"
                  [(ngModel)]="filters.gender.options.male"
                  (ngModelChange)="checkboxChecked($event,'male','gender', 'Male')"
                >
                </ssi-checkbox-2>
              </div>
              <div class="form-field">
                <ssi-checkbox-2
                  [reversed]="true"
                  [label]="'Female'"
                  [variant]="'square'"
                  [highlightLabel]="false"
                  [(ngModel)]="filters.gender.options.female"
                  (ngModelChange)="checkboxChecked($event,'female','gender', 'Female')"
                >
                </ssi-checkbox-2>
              </div>
            </div>
          </div>
        </li>
        <!-- Interests -->
        <li *ngIf="functionalityMode !== GlobalFiltersFunctionalityMode.Compact && enabledFilters.interests">
          <div class="filter-header" (click)="closeAllExpandedAndToggle('interests');">
            <i [class]="'leading-icon ' + filters.interests.icon"></i>
            {{filters.interests.label}}
            <div class="filter-header-right">
              <div class="cancel-filters"
                  *ngIf="filters.interests.filterSelections.length > 0"
                  (click)="clearFilterSelections('interests', $event)">
                  <i class="ssi ssi-delete-close"></i>
                  {{ stylesMode === GlobalFiltersStyleMode.Compact ? 'Clear' : generateFilterClearTag('interests') }}
              </div>
              <i class="ssi ssi-arrow-down-new" [class.flip]="filters.interests.expanded"></i>
            </div>
          </div>
          <div class="filter-options" *ngIf="filters.interests.expanded">
            <div class="form-row column">
              <div class="form-field">
                <div class="form-row column">
                  <div
                    class="form-field"
                    *ngFor="let interest of interestsIterable; let index = index;"
                  >
                    <ssi-checkbox-2
                      [reversed]="true"
                      [label]="interest.label"
                      [variant]="'square'"
                      [highlightLabel]="false"
                      [(ngModel)]="filters.interests.options[interest.key]"
                      (ngModelChange)="checkboxChecked($event, interest.key, 'interests', interest.label)"
                    ></ssi-checkbox-2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <!-- Age -->
        <li *ngIf="enabledFilters.age">
          <div
            class="filter-header"
            (click)="closeAllExpandedAndToggle('age');"
          >
            <i [class]="'leading-icon ' + filters.age.icon"></i>
            {{filters.age.label}}
            <div class="filter-header-right">
              <div
                class="cancel-filters"
                *ngIf="filters.age.filterSelections.length > 0"
                (click)="clearFilterSelections('age', $event)"
              >
                <i class="ssi ssi-delete-close"></i>
                {{ stylesMode === GlobalFiltersStyleMode.Compact ? 'Clear' : generateFilterClearTag('age') }}
              </div>
              <i
                class="ssi ssi-arrow-down-new"
                [class.flip]="filters.age.expanded"
              ></i>
            </div>
          </div>
          <div
            class="filter-options"
            *ngIf="filters.age.expanded"
          >
            <div class="form-row">
              <div class="form-field">

                <div class="input-wrapper">
                  <ssi-dropdown-select-2
                    name="ageStart"
                    class="tag-selector"
                    headButtonStyleVariant="analytics"
                    [multiple]="true"
                    [filterable]="false"
                    [toggleAllEnabled]="false"
                    [filterPlaceholder]="'Select age range...'"
                    [headButtonIcon]="'ssi ssi-arrow-down-new'"
                    [options]="ageOptionsIterable"
                    [(ngModel)]="filters.age.filterSelections"
                    (ngModelChange)="filterChanged.emit(filters)"
                  >
                    <ng-template
                      ssiTemplateSelector="headButtonLabel"
                      let-expanded="expanded"
                    >
                      <div
                        *ngIf="!filters.age.filterSelections.length"
                        class="select-tags-placeholder"
                      >
                        <span>Select Age Range</span>
                      </div>
                      <div
                        *ngIf="filters.age.filterSelections.length"
                        class="select-tag-labels"
                      >
                        <ng-container *ngFor="let age of filters.age.filterSelections">
                          <span (click)="$event.stopPropagation(); removeAge(age)">
                            {{age.label}} <i class="ssi ssi-times"></i>
                          </span>
                        </ng-container>
                      </div>
                    </ng-template>
                  </ssi-dropdown-select-2>
                </div>

              </div>
            </div>
          </div>
        </li>
        <!-- Location -->
        <li *ngIf="enabledFilters.location">
          <div
            class="filter-header"
            (click)="closeAllExpandedAndToggle('location');"
          >
            <i [class]="'location leading-icon ' + filters.location.icon"></i>
            {{filters.location.label}}
            <i
              class="ssi ssi-information"
              [ssiTooltip]
              tooltipTitle="Location Filters"
              [tooltipAppendToBody]="true"
              tooltipPlacement="top"
              [tooltipTemplate]="locationTooltipTemplate"
              tooltipTrailingIconClass="ssi ssi-information"
              [tooltipWindowClass]="'tooltip-chunky-big'"
            ></i>
            <div class="filter-header-right">
              <div
                class="cancel-filters"
                *ngIf="filters.location.filterSelections.length > 0"
                (click)="clearFilterSelections('location', $event)"
              >
                <i class="ssi ssi-delete-close"></i>
                {{ stylesMode === GlobalFiltersStyleMode.Compact ? 'Clear' : generateFilterClearTag('location') }}
              </div>
              <i
                class="ssi ssi-arrow-down-new"
                [class.flip]="filters.location.expanded"
              ></i>
            </div>
          </div>
          <div
            class="filter-options"
            *ngIf="filters.location.expanded"
          >
            <!-- Country -->
            <div class="form-row">
              <div class="form-field">
                <div class="input-wrapper">
                  <label>Country</label>
                  <ssi-dropdown-select-2
                    name="location"
                    class="tag-selector"
                    headButtonStyleVariant="analytics"
                    [filterable]="true"
                    [toggleAllEnabled]="false"
                    [filterPlaceholder]="'Search countries...'"
                    [headButtonIcon]="filters.location.options[locationTiers.Country] ? 'ssi ssi-correctedit' : 'ssi ssi-addsmall'"
                    [options]="locations.countries"
                    [(ngModel)]="filters.location.options[locationTiers.Country]"
                    (ngModelChange)="onLocationChange($event)"
                  >
                    <ng-template
                      ssiTemplateSelector="headButtonLabel"
                      let-expanded="expanded"
                    >
                      <div
                        class="select-placeholder"
                        *ngIf="!filters.location.options[locationTiers.Country]"
                      >
                        <span>Select country...</span>
                      </div>
                      <div
                        class="select-label"
                        *ngIf="filters.location.options[locationTiers.Country]"
                      >
                        <span>{{filters.location.options[locationTiers.Country].label}}</span>
                      </div>
                    </ng-template>
                  </ssi-dropdown-select-2>
                </div>
              </div>
            </div>

            <!-- State -->
            <div
              class="form-row"
              *ngIf="locations.states && locations.states.length > 0 || filters.location.options[locationTiers.State]"
            >
              <div class="form-field">
                <div class="input-wrapper">
                  <label>State / Province</label>
                  <ssi-dropdown-select-2
                    name="location"
                    class="tag-selector"
                    headButtonStyleVariant="analytics"
                    [filterable]="true"
                    [toggleAllEnabled]="false"
                    [filterPlaceholder]="'Search states...'"
                    [headButtonIcon]="filters.location.options[locationTiers.State] ? 'ssi ssi-correctedit' : 'ssi ssi-addsmall'"
                    [options]="locations.states"
                    [(ngModel)]="filters.location.options[locationTiers.State]"
                    (ngModelChange)="onLocationChange($event)"
                  >
                    <ng-template
                      ssiTemplateSelector="headButtonLabel"
                      let-expanded="expanded"
                    >
                      <div
                        class="select-placeholder"
                        *ngIf="!filters.location.options[locationTiers.State]"
                      >
                        <span>Select state...</span>
                      </div>
                      <div
                        class="select-label"
                        *ngIf="filters.location.options[locationTiers.State]"
                      >
                        <span>{{filters.location.options[locationTiers.State].label}}</span>
                      </div>
                    </ng-template>
                  </ssi-dropdown-select-2>
                </div>
              </div>
            </div>

            <!-- City -->
            <div
              class="form-row"
              *ngIf="locations.cities && locations.cities.length > 0 || filters.location.options[locationTiers.City]"
            >
              <div class="form-field">
                <div class="input-wrapper">
                  <label>City</label>
                  <ssi-dropdown-select-2
                    name="location"
                    class="tag-selector"
                    headButtonStyleVariant="analytics"
                    [filterable]="true"
                    [toggleAllEnabled]="false"
                    [filterPlaceholder]="'Search cities...'"
                    [headButtonIcon]="filters.location.options[locationTiers.City] ? 'ssi ssi-correctedit' : 'ssi ssi-addsmall'"
                    [options]="locations.cities"
                    [(ngModel)]="filters.location.options[locationTiers.City]"
                    (ngModelChange)="onLocationChange($event)"
                  >
                    <ng-template
                      ssiTemplateSelector="headButtonLabel"
                      let-expanded="expanded"
                    >
                      <div
                        class="select-placeholder"
                        *ngIf="!filters.location.options[locationTiers.City]"
                      >
                        <span>Select city...</span>
                      </div>
                      <div
                        class="select-label"
                        *ngIf="filters.location.options[locationTiers.City]"
                      >
                        <span>{{filters.location.options[locationTiers.City].label}}</span>
                      </div>
                    </ng-template>
                  </ssi-dropdown-select-2>
                </div>
              </div>
            </div>

            <!-- Locality -->
            <div
              class="form-row"
              *ngIf="locations.localities && locations.localities.length > 0 || filters.location.options[locationTiers.Locality]"
            >
              <div class="form-field">
                <div class="input-wrapper">
                  <label>Locality</label>
                  <ssi-dropdown-select-2
                    name="location"
                    class="tag-selector"
                    headButtonStyleVariant="analytics"
                    [filterable]="true"
                    [toggleAllEnabled]="false"
                    [filterPlaceholder]="'Search localities...'"
                    [headButtonIcon]="filters.location.options[locationTiers.Locality] ? 'ssi ssi-correctedit' : 'ssi ssi-addsmall'"
                    [options]="locations.localities"
                    [(ngModel)]="filters.location.options[locationTiers.Locality]"
                    (ngModelChange)="onLocationChange($event)"
                  >
                    <ng-template
                      ssiTemplateSelector="headButtonLabel"
                      let-expanded="expanded"
                    >
                      <div
                        class="select-placeholder"
                        *ngIf="!filters.location.options[locationTiers.Locality]"
                      >
                        <span>Select locality...</span>
                      </div>
                      <div
                        class="select-label"
                        *ngIf="filters.location.options[locationTiers.Locality]"
                      >
                        <span>{{filters.location.options[locationTiers.Locality].label}}</span>
                      </div>
                    </ng-template>
                  </ssi-dropdown-select-2>
                </div>
              </div>
            </div>

          </div>
        </li>
        <!-- Author -->
        <li *ngIf="enabledFilters.author">
          <div class="filter-header" (click)="closeAllExpandedAndToggle('author');">
            <i [class]="'leading-icon ' + filters.author.icon"></i>
            {{filters.author.label}}
            <div class="filter-header-right">
              <div class="cancel-filters"
                  *ngIf="filters.author.filterSelections.length > 0"
                  (click)="clearFilterSelections('author', $event)">
                  <i class="ssi ssi-delete-close"></i>
                  Clear {{filters.author.filterSelections.length}} filter{{filters.author.filterSelections.length === 1 ? '' : 's'}}
              </div>
              <i [class]="filters.author.expanded ? 'ssi ssi-arrow-up' : 'ssi ssi-arrow-down-new'"></i>
            </div>
          </div>
          <div class="filter-options" *ngIf="filters.author.expanded">
            <div class="form-row column">
              <div class="form-field column author-input">
                <label>Search for authors</label>
                <input class="orlo-text-input" [(ngModel)]="authorString" (keydown.enter)="addAuthor()" placeholder="Type a search..."/>
                <ul class="author-filters" *ngIf="filters.author.filterSelections.length">
                  <li *ngFor="let author of filters.author.filterSelections"
                      class="author-filter">
                    <span>{{author.value}}</span>
                    <button class="author-filter-action"
                            (click)="removeAuthor(author.value)">
                      <i class="ssi ssi-delete-close"></i>
                    </button>
                  </li>
                </ul>
               </div>
            </div>
          </div>
        </li>
        <!-- Language -->
        <li *ngIf="enabledFilters.language">
          <div
            class="filter-header"
            (click)="closeAllExpandedAndToggle('language');"
          >
            <i [class]="'leading-icon ' + filters.language.icon"></i>
            {{filters.language.label}}
            <div class="filter-header-right">
              <div
                class="cancel-filters"
                *ngIf="filters.language.filterSelections.length > 0"
                (click)="clearFilterSelections('language', $event)"
              >
                <i class="ssi ssi-delete-close"></i>
                {{ stylesMode === GlobalFiltersStyleMode.Compact ? 'Clear' : generateFilterClearTag('language') }}
              </div>
              <i
                class="ssi ssi-arrow-down-new"
                [class.flip]="filters.language.expanded"
              ></i>
            </div>
          </div>
          <div
            class="filter-options"
            *ngIf="filters.language.expanded"
          >
            <div class="form-row">
              <div class="form-field">
                <div class="input-wrapper">
                  <ssi-dropdown-select-2
                    name="tagsToInclude"
                    class="tag-selector"
                    headButtonStyleVariant="analytics"
                    [multiple]="true"
                    [filterable]="true"
                    [toggleAllEnabled]="false"
                    [filterPlaceholder]="'Select languages...'"
                    [headButtonIcon]="'ssi ssi-addsmall'"
                    [toggleAllLabel]="'Select all languages'"
                    [options]="languages"
                    [(ngModel)]="filters.language.filterSelections"
                    (ngModelChange)="filterChanged.emit(filters)"
                  >
                    <ng-template
                      ssiTemplateSelector="headButtonLabel"
                      let-expanded="expanded"
                    >
                      <div
                        class="select-tags-placeholder"
                        *ngIf="!filters.language.filterSelections.length"
                      >
                        <span>Select languages</span>
                      </div>
                      <div
                        class="select-tag-labels"
                        *ngIf="filters.language.filterSelections.length"
                      >
                        <ng-container *ngFor="let language of filters.language.filterSelections">
                          <span (click)="$event.stopPropagation(); removeLanguage(language)">
                            {{language.label}} <i class="ssi ssi-times"></i>
                          </span>
                        </ng-container>
                      </div>
                    </ng-template>
                  </ssi-dropdown-select-2>
                </div>
              </div>
            </div>
          </div>
        </li>
        <!-- Tags -->
        <li *ngIf="enabledFilters.tags">
          <div
            class="filter-header"
            (click)="closeAllExpandedAndToggle('tags');"
          >
            <i [class]="'leading-icon ' + filters.tags.icon"></i>
            {{filters.tags.label}}
            <div class="filter-header-right">
              <div
                class="cancel-filters"
                *ngIf="filters.tags.filterSelections.length > 0"
                (click)="clearFilterSelections('tags', $event)"
              >
                <i class="ssi ssi-delete-close"></i>
                {{ stylesMode === GlobalFiltersStyleMode.Compact ? 'Clear' : generateFilterClearTag('tags') }}
              </div>
              <i
                class="ssi ssi-arrow-down-new"
                [class.flip]="filters.tags.expanded"
              ></i>
            </div>
          </div>
          <div
            class="filter-options"
            *ngIf="filters.tags.expanded"
          >
            <div class="form-row">
              <div class="form-field">
                <div class="input-wrapper">
                  <ssi-dropdown-select-2
                    name="tagsToInclude"
                    class="tag-selector"
                    headButtonStyleVariant="analytics"
                    [multiple]="true"
                    [filterable]="true"
                    [toggleAllEnabled]="false"
                    [filterPlaceholder]="'Select tags...'"
                    [headButtonIcon]="'ssi ssi-addsmall'"
                    [toggleAllLabel]="'Select all tags'"
                    [options]="tags"
                    [(ngModel)]="filters.tags.filterSelections"
                    (ngModelChange)="filterChanged.emit(filters)"
                  >
                    <ng-template
                      ssiTemplateSelector="headButtonLabel"
                      let-expanded="expanded"
                    >
                      <div
                        class="select-tags-placeholder"
                        *ngIf="!filters.tags.filterSelections.length"
                      >
                        <span>Select tags</span>
                      </div>
                      <div
                        class="select-tag-labels"
                        *ngIf="filters.tags.filterSelections.length"
                      >
                        <ng-container *ngFor="let tag of filters.tags.filterSelections">
                          <span (click)="$event.stopPropagation(); removeTag(tag)">
                            {{tag.label}} <i class="ssi ssi-times"></i>
                          </span>
                        </ng-container>
                      </div>
                    </ng-template>
                  </ssi-dropdown-select-2>
                </div>
              </div>
            </div>
          </div>
        </li>
        <!-- Assigned -->
        <li *ngIf="enabledFilters.assignedTo">
          <div
            class="filter-header"
            (click)="closeAllExpandedAndToggle('assignedTo');"
          >
            <i [class]="'leading-icon ' + filters.assignedTo.icon"></i>
            {{filters.assignedTo.label}}
            <div class="filter-header-right">
              <div
                class="cancel-filters"
                *ngIf="filters.assignedTo.filterSelections.length > 0"
                (click)="clearFilterSelections('assignedTo', $event)"
              >
                <i class="ssi ssi-delete-close"></i>
                {{ stylesMode === GlobalFiltersStyleMode.Compact ? 'Clear' : generateFilterClearTag('assignedTo') }}
              </div>
              <i [class]="filters.assignedTo.expanded ? 'ssi ssi-arrow-up' : 'ssi ssi-arrow-down-new'"></i>
            </div>
          </div>
          <div
            class="filter-options"
            *ngIf="filters.assignedTo.expanded"
          >
            <div class="form-row column">
              <div class="scroll-wrapper">
                <div
                  class="form-field"
                  *ngFor="let colleague of colleagues"
                >
                  <ssi-checkbox-2
                    [reversed]="true"
                    [label]="colleague.fullName"
                    [variant]="'square'"
                    [highlightLabel]="false"
                    [(ngModel)]="filters.assignedTo.options[colleague.id]"
                    (ngModelChange)="checkboxChecked($event, colleague.id, 'assignedTo')"
                  >
                  </ssi-checkbox-2>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div class="filters-footer">
    <button *ngIf="checkIfFiltersAreSelected() && functionalityMode !== GlobalFiltersFunctionalityMode.Compact"
            class="g-btn g-btn-secondary bg-red clear-btn"
            (click)="clearAllFilterSelections(); onClearFiltersClick.emit()">{{'CLEAR_ALL_FILTERS' | translate}}</button>
    <button class="g-btn g-btn-secondary bg-white cancel-btn"
            translate="CANCEL"
            (click)="onFiltersClick.emit(); closeAllFilters()"></button>
    <button class="g-btn apply-btn"
            [class.active]="checkIfFiltersAreSelected()"
            translate="APPLY_FILTERS"
            [disabled]="!checkIfFiltersAreSelected()"
            (click)="applyFilters()"></button>
  </div>
</div>

<ng-template #locationTooltipTemplate>
  <div class="tooltip-body">
    <div class="tooltip-body-title">
      Location Filters
    </div>
    <div class="tooltip-body-contents d-flex-column">
      <h4>With location filters there are a few things to note:</h4>
      <ul>
        <li>You can only select and search one location at a time</li>
        <li>If you're looking for a particular city, ensure you have the correct starting Country/State</li>
        <li>If you cannot see the location you want to search for, this means there are no post results from this area
        </li>
      </ul>
    </div>
  </div>
  <div
    data-popper-arrow
    class="tooltip-arrow"
  ></div>
</ng-template>