<div class="cl-dropdown-select-container">
  <div class="head">
    <h2>Dropdown Select</h2>
  </div>
  <div class="body">
    <ssi-cl-dropdown-select-a [accounts]="accounts"></ssi-cl-dropdown-select-a>
    <ssi-cl-dropdown-select-b [accounts]="accounts"></ssi-cl-dropdown-select-b>
    <ssi-cl-dropdown-select-c [accounts]="accounts"></ssi-cl-dropdown-select-c>
    <ssi-cl-dropdown-select-d [accounts]="accounts"></ssi-cl-dropdown-select-d>
    <ssi-cl-dropdown-select-e></ssi-cl-dropdown-select-e>
    <ssi-cl-dropdown-select-f [accounts]="accounts"></ssi-cl-dropdown-select-f>
    <ssi-cl-dropdown-select-g></ssi-cl-dropdown-select-g>
  </div>
</div>