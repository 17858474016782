<div class="account-report-container">
  <ssi-report-header
    report="Account Report"
    [name]=""
    [dateRangesTemplate]="dateRangesRef"
    (dateSelected)="reportDateChanged($event)"
  ></ssi-report-header>
  
  <ssi-report-sidebar
    color="#D41D68"
    [navList]="navItems"
    [actionList]="reportActions"
    [active]="activeNavItemTag"
    (navClicked)="scrollTo($event)"
  ></ssi-report-sidebar>

  <div
    class="report-body-loading"
    *ngIf="loading"
  >
    <div class="loading-container">
      <img src="https://www.orlo.app/app/web/production/f44ceeab6551db9be89255d952d86c0d.gif">
      <h3>We're building your report!</h3>
      <p>This may take a few moments
      </p>
    </div>
  </div>

  <div
    class="report-body"
    *ngIf="!loading"
  >

    <section id="account-list" class="section-container">
      <ul class="account-list">
        <li *ngFor="let account of accounts">
          <ssi-account-pill
            [icon]="account.socialNetwork.icon.web"
            [label]="account.displayName"
          ></ssi-account-pill>
        </li>
      </ul>

      <div class="section-delimiter"></div>
    </section>

    <section
      id="account-table"
      class="section-container section-container-account-table"
    >
      <h2 class="section-title">Account Comparison</h2>

      <ssi-treetable
        [data]="report.totals"
        [rowStyle]="{'height': '68px'}"
        [virtualScroll]="false"
        [resizeMode]="'fit'"
        [reorderable]="true"
        [saveSettings]="true"
        [settingsStorageKey]="'treetable-account-report-account-comparison'"
        [globalFilterInputRef]=""
        id="treetable-account-report-account-comparison"
      >
        <ssi-column
          header="Account"
          field="account.name"
          [templateRefs]="{bodyCell: accountBodyCellTemplate}"
        ></ssi-column>
        <ssi-column
          header="Connections"
          field="current.connections"
          [templateRefs]="{bodyCell: statBodyCellTemplate}"
        ></ssi-column>
        <ssi-column
          header="Messages In"
          field="current.messages_in"
          [templateRefs]="{bodyCell: statBodyCellTemplate}"
        ></ssi-column>
        <ssi-column
          header="Messages Out"
          field="current.messages_out"
          [templateRefs]="{bodyCell: statBodyCellTemplate}"
        ></ssi-column>
        <ssi-column
          header="Engagement rate"
          field="current.outbox_engagement_rate"
          [tooltip]="'ENGAGEMENT_RATE_TOOLTIP' | translate"
          [templateRefs]="{bodyCell: statBodyCellTemplate}"
        ></ssi-column>
        <ssi-column
          header="Sentiment"
          field="current.negative_sentiment"
          [templateRefs]="{bodyCell: barBodyCellTemplate}"
        ></ssi-column>
        <ssi-column
          header="Gender"
          field="current.male_connections"
          [templateRefs]="{bodyCell: genderBodyCellTemplate}"
        ></ssi-column>
        <ssi-column
          header="Link clicks"
          field="current.total_clicks_es"
          [templateRefs]="{bodyCell: statBodyCellTemplate}"
        ></ssi-column>
        <ssi-column
          header="Reach"
          field="current.total_reach"
          [templateRefs]="{bodyCell: statBodyCellTemplate}"
        ></ssi-column>
        <ssi-column
          header="Impressions"
          field="current.total_impressions"
          [templateRefs]="{bodyCell: statBodyCellTemplate}"
        ></ssi-column>
      </ssi-treetable>
    
      <ng-template
        #accountBodyCellTemplate
        ssiTemplateSelector="bodyCell"
        let-rowIndex="rowIndex"
        let-rowData="rowData"
        let-fieldValue="fieldValue"
      >
        <div class="body-cell body-cell-primary">
          <ssi-social-network-icon
            [accountTypeId]="rowData.account.account_type_id"
            [size]="20"
          ></ssi-social-network-icon>
          &nbsp;
          <p class="body-cell-primary-title" title="{{fieldValue}}">{{fieldValue}}</p>
        </div>
      </ng-template>
    
      <ng-template
        #statBodyCellTemplate
        ssiTemplateSelector="bodyCell"
        let-rowIndex="rowIndex"
        let-rowData="rowData"
        let-column="column"
        let-columnIndex="columnIndex"
        let-columns="columns"
        let-columnsMap="columnsMap"
        let-fieldValue="fieldValue"
      >
        <div
          class="body-cell stat"
          [class.row-even]="rowIndex % 2 !== 0"
        >
          <p *ngIf="column.field === 'current.outbox_engagement_rate' && !rowData.account.socialNetwork.statistics.engagementRate.account">N/A</p>
          <p *ngIf="column.field === 'current.total_clicks_es' && !rowData.account.socialNetwork.statistics.clicks.account">N/A</p>
          <p *ngIf="column.field === 'current.total_reach' && !rowData.account.socialNetwork.statistics.reach.account">N/A</p>
          <p *ngIf="column.field === 'current.total_impressions' && !rowData.account.socialNetwork.statistics.impressions.account">N/A</p>
          
          <ng-container
            *ngIf="
              !['current.outbox_engagement_rate', 'current.total_clicks_es', 'current.total_reach', 'current.total_impressions'].includes(column.field) ||
              (column.field === 'current.outbox_engagement_rate' && rowData.account.socialNetwork.statistics.engagementRate.account) ||
              (column.field === 'current.total_clicks_es' && rowData.account.socialNetwork.statistics.clicks.account) ||
              (column.field === 'current.total_reach' && rowData.account.socialNetwork.statistics.reach.account) ||
              (column.field === 'current.total_impressions' && rowData.account.socialNetwork.statistics.impressions.account)
            "
          >
            <p>{{fieldValue || 0}}</p>
            <ssi-stat-change
              *ngIf="rowData.previous[splitColumnFieldValue(column.field)] && rowData.previous[splitColumnFieldValue(column.field)] != fieldValue"
              [current]="fieldValue"
              [previous]="rowData.previous[splitColumnFieldValue(column.field)]"
              [suffix]="''"
            ></ssi-stat-change>
          </ng-container>
        </div>
      </ng-template>

      <ng-template
        #barBodyCellTemplate
        ssiTemplateSelector="bodyCell"
        let-rowIndex="rowIndex"
        let-rowData="rowData"
        let-column="column"
        let-columnIndex="columnIndex"
        let-columns="columns"
        let-columnsMap="columnsMap"
        let-fieldValue="fieldValue"
      >
        <div
          class="body-cell body-cell-sentiment stat"
          [ssiTooltip]="'Negative: ' + (+fieldValue  + +rowData.current.semi_negative_sentiment) + ' Neutral: ' + rowData.current.neutral_sentiment + ' Positive: ' + (+rowData.current.positive_sentiment + +rowData.current.semi_positive_sentiment)"
          tooltipWindowClass="tooltip-chunky-big"
          [tooltipDisabled]="!rowData.account.socialNetwork.statistics.messagesIn.account"
          [class.row-even]="rowIndex % 2 !== 0"
        >
          <p *ngIf="!rowData.account.socialNetwork.statistics.messagesIn.account">N/A</p>
          <div
            *ngIf="rowData.account.socialNetwork.statistics.messagesIn.account && (fieldValue + rowData.current.semi_negative_sentiment + rowData.current.neutral_sentiment + rowData.current.semi_positive_sentiment + rowData.current.positive_sentiment) > 0"
            class="report-sentiment-bar"
          >
            <div
              class="report-sentiment-bar-negative"
              [style.width.%]="((+fieldValue + rowData.current.semi_negative_sentiment) / (rowData.current.messages_in)) * 10000"
            ></div>
            <div
              class="report-sentiment-bar-netural"
              [style.width.%]="(rowData.current.neutral_sentiment / (rowData.current.messages_in)) * 10000"
            ></div>
            <div
              class="report-sentiment-bar-positive"
              [style.width.%]="((rowData.current.positive_sentiment + rowData.current.semi_positive_sentiment) / (rowData.current.messages_in)) * 10000"
            ></div>
          </div>
        </div>
      </ng-template>

      <ng-template
        #genderBodyCellTemplate
        ssiTemplateSelector="bodyCell"
        let-rowIndex="rowIndex"
        let-rowData="rowData"
        let-column="column"
        let-columnIndex="columnIndex"
        let-columns="columns"
        let-columnsMap="columnsMap"
        let-fieldValue="fieldValue"
      >
        <div
          class="body-cell stat body-cell-sentiment stat"
          [class.row-even]="rowIndex % 2 !== 0"
        >
        <div
          *ngIf="(fieldValue + rowData.current.female_connections) > 0"
          [ssiTooltip]="'Male: ' + fieldValue + ' Female: ' + rowData.current.female_connections"
          tooltipWindowClass="tooltip-chunky-big"
          class="report-sentiment-bar"
        >
          <div
            class="report-sentiment-bar-male"
            [style.width.%]="(fieldValue / (rowData.current.female_connections + fieldValue)) * 10000"
          ></div>
          <div
            class="report-sentiment-bar-female"
            [style.width.%]="(rowData.current.female_connections / (rowData.current.female_connections + fieldValue)) * 10000"
          ></div>
        </div>
          <!-- <p>Male: {{fieldValue}}</p>
          <p>Female: {{rowData.current.female_connections}}</p> -->
        </div>
      </ng-template>

    </section>
  </div>

  <ng-template #dateRangesRef>
    <div class="selected-date-ranges">
      <span class="current">{{dateRanges.current.start | date: 'dd MMM yy'}} - {{dateRanges.current.end | date: 'dd MMM yy'}}</span>
      <ng-container *ngIf="dateRanges.previous.start && dateRanges.previous.end">
        <span class="previous">vs</span>
        <span class="previous">{{dateRanges.previous.start | date: 'dd MMM yy'}} - {{dateRanges.previous.end | date: 'dd MMM yy'}}</span>
      </ng-container>
    </div>
  </ng-template>
</div>