import { Record, utils } from 'js-data';
import { Model } from '../../model';
import {
  Activity,
  ActivityAuthor,
  ActivityMedia,
  getActivityMediaByType,
  getLargerActivityAuthorAvatar
} from './activityModel';
import { services } from '../../common';
import { appInjector } from '../../../../../apps/angular/app-injector';
import {
  TeamsService,
  Team,
  ColleaguesService,
  Colleague
} from '../../../../../apps/angular/common/services/api';
import { User, UserModel } from '../../user/services/userModel';
import { api } from '../../core/services/api';

export enum ConversationVisibility {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export enum ConversationStatus {
  Unread = 'UNREAD',
  Unactioned = 'UNACTIONED',
  Actioned = 'ACTIONED',
  Resolved = 'RESOLVED',
  OnHold = 'ONHOLD'
}

export enum ConversationAssignToTarget {
  User = 'USER',
  Team = 'TEAM'
}

export enum ConversationSentiment {
  Positive = 'positive_sentiment',
  SemiPositive = 'semi_positive_sentiment',
  Neutral = 'neutral_sentiment',
  SemiNegative = 'semi_negative_sentiment',
  Negative = 'negative_sentiment'
}

export class Conversation extends Record {
  account_id: string;
  account: Account;
  author: ActivityAuthor;
  assigned_to_group: string;
  assigned_to_user: string;
  count_actioned: number;
  count_replies: number;
  count_unactioned: number;
  count_unread: number;
  id: string;
  latest_message: string;
  oldest_message: string;
  latest_inbound_message: string;
  media: ActivityMedia[];
  mediaByType = getActivityMediaByType(this.media);
  message_snippet: string;
  status: ConversationStatus;
  thread_id: string;
  time_end: string;
  time_start: string;
  visibility: ConversationVisibility;
  assignment: {
    id: string;
    type: ConversationAssignToTarget;
  } | null;
  start_sentiment: ConversationSentiment;
  end_sentiment: ConversationSentiment;
  replies_per_message: 1;
  resolved_by: string | null;
  time_to_resolve: number | null;
  survey_scheduled: boolean | null;
  on_hold_by: number | null;
  on_hold_label: string | null;
  priority?: number;

  get assignedToUser(): Colleague {
    if (
      this.assignment &&
      this.assignment.type === ConversationAssignToTarget.User
    ) {
      const colleaguesService = appInjector().get(ColleaguesService);
      return colleaguesService.store.find(this.assignment.id);
    }
    return null;
  }

  get assignedToTeam(): Team {
    if (
      this.assignment &&
      this.assignment.type === ConversationAssignToTarget.Team
    ) {
      const teamsService = appInjector().get(TeamsService);
      return teamsService.store.find(this.assignment.id);
    }
    return null;
  }

  get resolvedByUser(): Colleague {
    if (!this.resolved_by) {
      return undefined;
    }
    const colleaguesService = appInjector().get(ColleaguesService);
    return colleaguesService.store.find(this.resolved_by);
  }

  get onHoldByUser(): Colleague {
    if (!this.on_hold_by) {
      return undefined;
    }
    const colleaguesService = appInjector().get(ColleaguesService);
    return colleaguesService.store.find(this.on_hold_by);
  }

  get resolved(): boolean {
    return this.status === ConversationStatus.Resolved;
  }

  get onHold(): boolean {
    return this.status === ConversationStatus.OnHold;
  }

  setTags(tags) {
    return api
      .post('activity/tag_v2', {
        id: this.thread_id,
        tag: tags,
        _method: 'PUT'
      })
      .then(() => this);
  }

  assignTo(userOrTeam: User | Colleague | Team | null) {
    const params: any = { conversation_id: this.id };

    const assignToUser =
      userOrTeam instanceof Colleague ||
      services.models.get<UserModel>('user').is(userOrTeam);
    const assignToTeam = userOrTeam instanceof Team;

    if (assignToUser) {
      params.user_id = userOrTeam.id;
    } else if (assignToTeam) {
      params.group_id = userOrTeam.id;
    }

    if (!userOrTeam) {
      return api
        .post('conversation/conversationAssign', params, {
          params: {
            _method: 'DELETE'
          }
        })
        .then(() => {
          this.assignment = null;
          return this;
        });
    }

    return api
      .post('conversation/conversationAssign', params, {
        params: {
          _method: 'PUT'
        }
      })
      .then(() => {
        if (assignToUser) {
          this.assignment = {
            id: userOrTeam.id,
            type: ConversationAssignToTarget.User
          };
        } else {
          this.assignment = {
            id: userOrTeam.id,
            type: ConversationAssignToTarget.Team
          };
        }
        return this;
      });
  }

  resolve(opts: any) {
    opts = opts || {};

    return api
      .post(
        'conversation/conversationResolve',
        {
          conversation_id: this.id,
          with_survey: opts.with_survey
        },
        {
          params: {
            _method: 'PUT'
          }
        }
      )
      .then(({ data }) => {
        this.survey_scheduled = data.surveyScheduled;
      })
      .then(() => {
        return services.models.get<UserModel>('user').getAuthUser();
      })
      .then((authUser) => {
        this.resolved_by = authUser.id;
        this.time_to_resolve =
          (Date.now() - new Date(this.oldest_message).getTime()) / 1000;
        this.status = ConversationStatus.Resolved;
      });
  }

  changeOnHoldStatus(option: any): Promise<any> {
    option = option || {};

    if (option.key === 'OFF') {
      // push back to queue...
      return api
        .delete('conversation/conversationOnHold', {
          params: {
            conversation_id: this.id
          }
        })
        .then((response) => {
          this.on_hold_by = null;
          this.on_hold_label = null;
          // temporay - until backend sets the right status
          this.status = ConversationStatus.Unactioned;
        });
    } else {
      return api
        .post(
          'conversation/conversationOnHold',
          {
            conversation_id: this.id,
            label: option.key // 'INTERNAL' | 'EXTERNAL'
          },
          {
            params: {
              _method: 'PUT'
            }
          }
        )
        .then((response) => {
          return services.models.get<UserModel>('user').getAuthUser();
        })
        .then((authUser) => {
          this.on_hold_by = authUser.id;
          this.on_hold_label = option.key;
          this.status = ConversationStatus.OnHold;
        });
    }
  }

  split(
    activity: Activity
  ): Promise<{ existing: Conversation; created: Conversation }> {
    return api
      .post<{
        data: { current: Partial<Conversation>; new: Partial<Conversation> };
      }>('conversation/conversationSplit', {
        conversation_id: this.id,
        datetime: activity.interaction.created_at
      })
      .then(({ data: conversations }) => {
        const conversationModel = services.models.get<ConversationModel>(
          'conversation'
        );
        return {
          created: conversationModel.inject(conversations.new),
          existing: conversationModel.inject(conversations.current)
        };
      });
  }

  merge() {
    return api
      .post('conversation/conversationMerge', {
        conversation_id: this.id
      })
      .then(() => {
        services.models.get<ConversationModel>('conversation').eject(this.id);
      });
  }
}

export class ConversationModel extends Model<Conversation> {
  constructor() {
    super('conversation', {
      endpoint: 'conversation/conversationIndex',
      recordClass: Conversation,
      relations: {
        belongsTo: {
          account: {
            localKey: 'account_id',
            localField: 'account'
          }
          // colleague: {
          //   localKey: 'resolved_by',
          //   localField: 'resolvedByUser'
          // }
        }
      },
      beforeAdd(conversations: any[]) {
        return conversations.map((conversation) => {
          conversation.author.avatar = getLargerActivityAuthorAvatar(
            conversation.account_id,
            conversation.author
          );
          return conversation;
        });
      }
    });
  }

  findOneById(
    id: string,
    { bypassCache = false }: { bypassCache?: boolean } = {}
  ): Promise<Conversation> {
    if (this.get(id) && !bypassCache) {
      return utils.Promise.resolve(this.get(id));
    }
    return api
      .get<{ data: Partial<Conversation> }>('conversation/conversationIndex', {
        params: {
          conversation_id: id
        }
      })
      .then(({ data: conversation }) => {
        return this.inject(conversation);
      });
  }
}

export function conversationModelFactory(dataStore?) {
  return services.models.get('conversation') || new ConversationModel();
}
