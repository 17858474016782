<!--Reach-->
<span
      *ngIf="post.account.account_type_id !== accountTypeId.YouTube"
      class="outbox-post-stat"
      [ssiTooltip]="reachTooltip | translate"
      [tooltipAppendToBody]="true">
  <i class="ssi ssi-megaphone"></i>
  <span class="outbox-post-stat-count"
        *ngIf="showReach">
    <ssi-big-number [number]="post.reach"></ssi-big-number>
  </span>
  <span class="outbox-post-stat-count"
        *ngIf="!showReach">-</span>
</span>

<!--Impressions-->
<span *ngIf="post.type !== OutboxMessageType.InstagramStory && post.type !== OutboxMessageType.InstagramReel && post.account.account_type_id !== accountTypeId.YouTube"
      class="outbox-post-stat"
      ssiTooltip="Impressions"
      [tooltipAppendToBody]="true">
  <i class="ssi ssi-impressions"></i>
  <span class="outbox-post-stat-count"
        *ngIf="!!post.impressions">
    <ssi-big-number [number]="post.impressions"></ssi-big-number>
  </span>
  <span class="outbox-post-stat-count"
        [hidden]="!!post.impressions">-</span>
</span>

<!--Engagement-->
<span *ngIf="post.type !== OutboxMessageType.InstagramStory && post.type !== OutboxMessageType.InstagramReel && post.account.account_type_id !== accountTypeId.YouTube"
      class="outbox-post-stat"
      ssiTooltip="Engagement"
      [tooltipAppendToBody]="true">
  <i class="ssi ssi-engagement-analytics"></i>
  <span class="outbox-post-stat-count">
    <ssi-big-number [number]="post.engagement_rate"></ssi-big-number>
  </span>
</span>

<!--Shares or retweets-->
<span *ngIf="post.type !== OutboxMessageType.InstagramStory && post.account.account_type_id !== accountTypeId.YouTube"
      class="outbox-post-stat"
      [ssiTooltip]="post.account.socialNetwork.statistics.shares.outbox ? 'Number of shares/reposts' : 'THE_SOCIAL_NETWORK_DOES_NOT_PROVIDE_US_WITH_THIS_STATISTIC' | translate"
      [tooltipAppendToBody]="true">
  <i class="fa"
     [ngClass]="{'fa-retweet': post.account.account_type_name == 'Twitter', 'fa-share-square-o': post.account.account_type_name != 'Twitter'}"></i>
  <span class="outbox-post-stat-count"
        *ngIf="post.account.socialNetwork.statistics.shares.outbox">
    <ssi-big-number [number]="post.share_count"></ssi-big-number>
  </span>
  <span class="outbox-post-stat-count"
        [hidden]="post.account.socialNetwork.statistics.shares.outbox">-</span>
</span>

<!--likes-->
<span *ngIf="post.type !== OutboxMessageType.InstagramStory"
      class="outbox-post-stat"
      [ssiTooltip]="(post.account.socialNetwork.statistics.likes.outbox ? 'LIKES' : 'THE_SOCIAL_NETWORK_DOES_NOT_PROVIDE_US_WITH_THIS_STATISTIC') | translate"
      [tooltipAppendToBody]="true">
  <i [ngClass]="post.account.socialNetwork.socialInteractions.like.icon.web"></i>
  <span class="outbox-post-stat-count"
        *ngIf="post.account.socialNetwork.statistics.likes.outbox">
    <ssi-big-number [number]="post.like_count"></ssi-big-number>
  </span>
  <span class="outbox-post-stat-count"
        [hidden]="post.account.socialNetwork.statistics.likes.outbox">-</span>
</span>

<!--comments counts-->
<span *ngIf="post.type !== OutboxMessageType.InstagramStory"
      class="outbox-post-stat"
      [ssiTooltip]="(post.account.socialNetwork.statistics.comments.outbox ? 'COMMENTS' : 'THE_SOCIAL_NETWORK_DOES_NOT_PROVIDE_US_WITH_THIS_STATISTIC') | translate"
      [tooltipAppendToBody]="true">
  <i *ngIf="post.account.account_type_id !== accountTypeId.YouTube" class="fa fa-comments"></i>
  <span class="outbox-post-stat-count"
        *ngIf="post.account.socialNetwork.statistics.comments.outbox">
    <ssi-big-number [number]="post.comment_count"></ssi-big-number>
    <span *ngIf="post.account.account_type_id === accountTypeId.YouTube">comments</span>
  </span>
  <span class="outbox-post-stat-count"
        [hidden]="post.account.socialNetwork.statistics.comments.outbox">-</span>
</span>

<!--Clicks-->
<span *ngIf="clicks && post.type !== OutboxMessageType.InstagramStory && post.account.account_type_id !== accountTypeId.YouTube"
      class="outbox-post-stat"
      ssiTooltip="{{ 'NUMBER_OF_CLICKS' | translate }}"
      [tooltipAppendToBody]="true">
  <i class="fa fa-mouse-pointer"></i>
  <span class="outbox-post-stat-count">
    <ssi-big-number [number]="clicks"></ssi-big-number>
  </span>
</span>

<!--View count-->
<span *ngIf="!!post.view_count"
      class="outbox-post-stat"
      [ssiTooltip]="post.type === OutboxMessageType.InstagramReel ? 'Instagram Reel plays' : 'Views'"
      [tooltipAppendToBody]="true">
  <i *ngIf="post.account.account_type_id !== accountTypeId.YouTube" class="ssi ssi-microcopy-binoculars"></i>
  <span class="outbox-post-stat-count"
        *ngIf="!!post.view_count">
    <ssi-big-number [number]="post.view_count"></ssi-big-number>
    <span *ngIf="post.account.account_type_id === accountTypeId.YouTube">views</span>
  </span>
  <span class="outbox-post-stat-count"
        [hidden]="!!post.view_count">-</span>
</span>

<!--Reaction count-->
<span
  *ngIf="!!post.reaction_count && post.account.account_type_id !== accountTypeId.YouTube"
  class="outbox-post-stat"
  ssiTooltip="Reaction Count"
  [tooltipAppendToBody]="true"
>
  <i class="ssi ssi-reactions"></i>
  <span class="outbox-post-stat-count">
    <ssi-big-number [number]="post.reaction_count"></ssi-big-number>
  </span>
  <span class="outbox-post-stat-count" [hidden]="!!post.reaction_count">-</span>
</span>

<!-- Notes and campaign indicator -->
<a href="javascript:;"
   class="outbox-post-stat"
   (click)="onShowNotes.emit(); $event.stopPropagation()"
   [ssiTooltip]="'THIS_POST_HAS__COUNT__NOTES_ATTACHED' | translate:{count: post.notes?.length || post.note_count}"
   tooltipPlacement="top"
   [tooltipAppendToBody]="true">
  <i class="ssi ssi-feather-note"></i> <span class="outbox-post-stat-count">{{ post.notes?.length || post.note_count }}</span>
</a>
<span *ngIf="post.campaign_id"
      class="outbox-post-stat"
      [ssiTooltip]="'PART_OF_THE__CAMPAIGNNAME__CAMPAIGN' | translate:{campaignName: post.campaign?.name}"
      tooltipPlacement="top"
      [tooltipAppendToBody]="true">
  <i class="ssi ssi-campaign"></i>
</span>

<!--Instagram Story stats -->
<ng-container
      *ngIf="post.account.account_type_id === accountTypeId.Instagram && post.type === OutboxMessageType.InstagramStory"
>
      <span
            class="outbox-post-stat"
            ssiTooltip="Tap Back"
            [tooltipAppendToBody]="true"
      >
            <i class="ssi ssi-tap-back"></i>
            <span class="outbox-post-stat-count">
                  <ssi-big-number [number]="post.story_metrics.taps_back"></ssi-big-number>
            </span>
      </span>
      <span
            class="outbox-post-stat"
            ssiTooltip="Tap Forward"
            [tooltipAppendToBody]="true"
      >
            <i class="ssi ssi-tap-forward"></i>
            <span class="outbox-post-stat-count">
                  <ssi-big-number [number]="post.story_metrics.taps_forward"></ssi-big-number>
            </span>
      </span>
      <span
            class="outbox-post-stat"
            ssiTooltip="Exit Story"
            [tooltipAppendToBody]="true"
      >
            <i class="ssi ssi-exit"></i>
            <span class="outbox-post-stat-count">
                  <ssi-big-number [number]="post.story_metrics.exits"></ssi-big-number>
            </span>
      </span>
</ng-container>
