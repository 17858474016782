<div class="cl-accordion">
	<div
		class="cl-accordion-item"
		[ngClass]="{'accordion-item-invalid': !isValid}"
		[style.height.px]="isOpen ? labelHeight + bodyHeight : labelHeight"
	>
		<h2 class="accordion-header" [id]="'Heading' + accordionId">
			<button
				class="accordion-button"
				[ngClass]="{'accordion-button-open': isOpen}"
				type="button"
				[style.height.px]="labelHeight"
				[attr.aria-expanded]="isOpen"
				[attr.aria-controls]="'Collapse' + accordionId"
				(click)="toggleOpenClose(!isOpen)"
			>
				<p
					*ngIf="(label | typeof) === 'string'"
					[innerHTML]="label"
					class="accordion-button-label"
				></p>
				<ng-container *ngIf="(label | typeof) !== 'string'">
					<ng-container *ngTemplateOutlet="label"></ng-container>
				</ng-container>
				<i class="ssi ssi-arrow-down-new accordion-button-icon"></i>
			</button>
		</h2>
		<div
			#accordionBody
			[id]="'Collapse' + accordionId"
			class="accordion-collapse"
			[attr.aria-labelledby]="'Heading' + accordionId"
		>
			<div class="accordion-body">
				<ng-content></ng-content>
			</div>
		</div>
	</div>
</div>