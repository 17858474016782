import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';

// Cant dynamically load css files with webpack 4 for some reason
import './emoji-picker.component.scss';
import { default as emojiList } from 'emoji-toolkit/emoji.json';
import * as EmojiToolkit from 'emoji-toolkit';
import 'emoji-assets/sprites/joypixels-sprite-24.css';

export interface JoyPixelsEmoji {
  char: string;
  ascii: any[];
  category: string;
  code_points: {
    base: string;
    diversity_parent: string;
    fully_qualified: string;
    gender_parent: any;
  };
  display: number;
  diversity: string[];
  diversity_base: number;
  diversity_children: [];
  gender: string[];
  gender_children: string[];
  humanform: number;
  keywords: string[];
  name: string;
  order: number;
  shortname: string;
  shortname_alternates: string[];
  unicode_version: number;
}

@Component({
  selector: 'ssi-emoji-picker',
  templateUrl: './emoji-picker.component.html'
})
export class EmojiPickerComponent implements OnInit {
  @Output() emojiSelected = new EventEmitter();

  emojiList: JoyPixelsEmoji[];
  categories: { key: string; label: string; icon: string }[] = [
    {
      key: 'people',
      label: 'People',
      icon: 'ssi ssi-emoji'
    },
    {
      key: 'nature',
      label: 'Nature',
      icon: 'ssi ssi-nature'
    },
    {
      key: 'food',
      label: 'Food',
      icon: 'ssi ssi-food'
    },
    {
      key: 'activity',
      label: 'Activity',
      icon: 'ssi ssi-sport'
    },
    {
      key: 'travel',
      label: 'Travel',
      icon: 'ssi ssi-transport'
    },
    {
      key: 'objects',
      label: 'Objects',
      icon: 'ssi ssi-bulb'
    },
    {
      key: 'symbols',
      label: 'Symbols',
      icon: 'ssi ssi-emoji-heart'
    },
    {
      key: 'flags',
      label: 'Flags',
      icon: 'ssi ssi-emoji-flag'
    }
  ];

  currentCategory = this.categories[0];

  emojiByCategory: { [category: string]: any[] };

  filteredEmojis: JoyPixelsEmoji[];
  filteredEmojiRows: Array<JoyPixelsEmoji[]> = [];
  viewPortEmojiRows: Array<JoyPixelsEmoji[]> = [];

  readonly emojiContainerHeight = 28;

  private _searchText = '';

  public get searchText(): string {
    return this._searchText;
  }

  public set searchText(value: string) {
    this._searchText = value;
    this.updateFilteredEmojis();
  }

  constructor(protected changeDetectorRef: ChangeDetectorRef) {}

  async ngOnInit() {
    this.emojiList = Object.values(emojiList);
    this.currentCategory = this.categories[0];

    const textContainer = document.querySelector(
      '.publisher-textarea-container'
    );
    if (textContainer) {
      textContainer.classList.remove('zero-zindex');
      textContainer.classList.add('override-zindex');
    }
    this.emojiByCategory = {};

    this.emojiList
      .filter(
        (emoji: JoyPixelsEmoji) =>
          !['modifier', 'regional'].includes(emoji.category)
      )
      .sort((a: JoyPixelsEmoji, b: JoyPixelsEmoji) => +a.order - +b.order)
      .forEach((emoji: JoyPixelsEmoji) => {
        emoji['char'] = EmojiToolkit.shortnameToUnicode(emoji.shortname);
        this.emojiByCategory[emoji.category] =
          this.emojiByCategory[emoji.category] || [];
        this.emojiByCategory[emoji.category].push(emoji);
      });

    this.updateFilteredEmojis();
  }

  trackByShortname(index, item: JoyPixelsEmoji) {
    return item.shortname;
  }

  updateFilteredEmojis() {
    // so that virtual scroll can re-init
    this.filteredEmojis = undefined;
    this.changeDetectorRef.detectChanges();

    if (this.searchText) {
      this.currentCategory = null;
      this.filteredEmojis = this.emojiList.filter((emoji: JoyPixelsEmoji) => {
        return emoji.shortname.includes(this.searchText.toLowerCase());
      });
    } else {
      this.filteredEmojis = this.emojiByCategory[
        this.currentCategory ? this.currentCategory.key : 'people'
      ];
    }

    // split filtered emojis into chunks of 8 so virtual scroll can function properly
    const chunkSize = 8;
    this.filteredEmojiRows = [];
    for (let i = 0; i < this.filteredEmojis.length; i += chunkSize) {
      const chunk = this.filteredEmojis.slice(i, i + chunkSize);
      this.filteredEmojiRows.push(chunk);
    }
  }

  changeCategory(category) {
    this.currentCategory = category;
    this.searchText = null;
    this.updateFilteredEmojis();
  }

  selectEmoji(emoji: JoyPixelsEmoji) {
    this.emojiSelected.emit({ emoji });
    const textContainer = document.querySelector(
      '.publisher-textarea-container'
    );
    if (textContainer) {
      textContainer.classList.remove('override-zindex');
      textContainer.classList.add('zero-zindex');
    }
  }

  viewPortItemsUpdated(items: Array<JoyPixelsEmoji[]>): void {
    this.viewPortEmojiRows = items;
  }

  getItemHeight = (item: JoyPixelsEmoji): number => {
    return this.emojiContainerHeight;
  };
}
