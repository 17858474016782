import angular from 'angular';
import trackJs from 'exports-loader?trackJs!trackjs';
import { random } from 'lodash-es';
import { fromEvent } from 'rxjs/observable/fromEvent';
import { share } from 'rxjs/operators/share';
import {
  SENTIMENT_CONFIG,
  STATIC_FILES_ENDPOINT,
  LANGUAGES,
  COUNTRIES,
  TIMEZONE_OFFSET,
  userAgent,
  MAXIMUM_WORKFLOW_ACCOUNTS,
  favicon
} from '../../angular/common/constants';

const trackJsMock = {
  configure: angular.noop,
  track: angular.noop
};

const lowerCaseCountries = {};
Object.entries(COUNTRIES).forEach(([key, value]) => {
  lowerCaseCountries[key.toLowerCase()] = value;
});

const IS_TEST_ENV = process.env.SSI_ENV === 'test';

export default angular
  .module('common.constants', [])
  .constant('staticFilesEndpoint', STATIC_FILES_ENDPOINT)
  .constant('trackJs', IS_TEST_ENV ? trackJsMock : trackJs)
  .constant('maximumWorkflowAccounts', MAXIMUM_WORKFLOW_ACCOUNTS);
