import deepFreeze from 'deep-freeze-strict';
import { UAParser } from 'ua-parser-js';
import Favico from 'favico.js';
import { OutboxMessageType } from '@ui-resources-angular';
import lessVariables from '../../../angularjs/common/less.variables';

export const STATIC_FILES_ENDPOINT = 'https://website-app.s3.amazonaws.com';

export const DEFAULT_TRANSLATE_LANGUAGE = 'en';

export const USER_LANGUAGE_STORAGE_KEY = 'user-language';

export const PHOTO_EDITOR_SDK_LICENSE =
  '{"owner":"SocialSignIn","version":"2.1","enterprise_license":false,"available_actions":["magic","filter","transform","sticker","text","adjustments","brush","focus","frames","camera"],"features":["adjustment","filter","focus","transform","brush","camera","library","export"],"platform":"HTML5","app_identifiers":[],"api_token":"vuE1aI-9-bNBj87iczXs8w","domains":["https://api.photoeditorsdk.com"],"issued_at":1543482004,"expires_at":null,"signature":"a1DcMfltp+NnJM3ai0varj7cBWKPQuUavs1BwL2eo4lE+alhuQ5cWr9xoRvj+Cci24b7EUcgnH69T+qHx6towlSCqCjg+rsQNs7b4dhVg/GMmDO4ij0k6dLJAgb9Pze4p1+2GRGHA0ZJULCcJ0PFaKGJ4AatNiiIYkHb0E43hConfW7MCFPdqj7PTvxnq3udd8A9hkR1ySH+k+7edPPG0pYTIhK8EVCHkPG5NftONIENTHXQyc6vBCvTiUbpXrvL6ajo9EAs3MAfyjA8+pEcp4Q/MZ2lIEGFJAq54zDw2aYBG0bshLzBAgzTdaD1WgFvzPPgLIl59urTyW4fNrn4HXNQMObsP72CbZwXLqNE5phiiztKK4uP1MJCX7g3LBJjY+D2qwGpaoQaKItZqqDkL1mbfAM2e0RTXM28RBOxb9sX5856c4DrdPHf1vavHz0tT5//Ts/mDw6hIx7CXS1CSDIyRr7mHSCxv2T68EZPPJq39O4L3K0fSbsxv63Hb8oWV7Xqfm0yHxlNKjKM97cQYvgVOHKaY/r+g39XOSbujGcKhXtL9HIVYRpZHJdQgDoFLT/OxzQSVobB2BT4/5ouEI+e3M/sTuA3k+wi4u4FQYEQaO7p5/kSRQQEk4B5b0lvLQdcfHFryMOG2/mngh1Od0DMCUJUa49QUgSo8MJpsHc="}';

export const TIMEZONE_OFFSET = new Date().getTimezoneOffset() * 60 * 1000;

export const googleMapsAPIKey = 'AIzaSyDNNMX6c2EM-D4V-0UAz_VY7cm_IHKIBmc';

export const userAgent = new UAParser();

export const GOOGLE_MAPS = {
  mapMarkerImage:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQA' +
    'AAAcCAMAAABWBG9SAAAAM1BMVEUAAADBJy3BJy3BJy3BJy3BJy3BJy3BJy3BJy3BJy3BJy3BJy' +
    '3BJy3BJy3BJy3BJy3BJy2D+tUeAAAAEHRSTlMADx8vP09fb3+Pn6+/z9/v+t8hjgAAAJ1JREFUeN' +
    'pdkFsSxCAIBI0aH5FE73/aCJnaKbZ/wKm2BMNHrENEeg7k6Avc6ZfdixSfgdPCjpsyrc6ob1g7t' +
    'C0WXyqiUZKpu7m1HpbB2INpkQCynuoXdhe2P/PEqKI1Ihx62FtVG/J7qWj/6ELTunKEdHHRtgj' +
    'GhEoaJwbcI3oRXItApMqf8WpkmL0IxIleFSZUsw8LRM9DkaQhZwAvr1IRWvcT1NkAAAAASUVORK5CYII=',
  markerClusterImages:
    'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
};

export const MAXIMUM_WORKFLOW_ACCOUNTS = 2000;

export const favicon: { badge(count: number): void } = new Favico({
  animation: 'none'
});

export const WEEK_STARTS_ON = 1; // Monday is the first day of the week

export const OUTBOX_MESSAGE_TYPES = deepFreeze([
  {
    value: OutboxMessageType.StatusUpdate,
    label: 'STATUS_UPDATE'
  },
  {
    value: OutboxMessageType.Picture,
    label: 'PICTURE'
  },
  {
    value: OutboxMessageType.Album,
    label: 'ALBUM'
  },
  {
    value: OutboxMessageType.Reply,
    label: 'REPLY'
  },
  {
    value: OutboxMessageType.ReplyPicture,
    label: 'REPLY_WITH_PICTURE'
  },
  {
    value: OutboxMessageType.ReplyAlbum,
    label: 'REPLY_WITH_ALBUM'
  },
  {
    value: OutboxMessageType.ReplyVideo,
    label: 'REPLY_WITH_VIDEO'
  },
  {
    value: OutboxMessageType.Video,
    label: 'VIDEO'
  },
  {
    value: OutboxMessageType.PrivateMessage,
    label: 'PRIVATE_MESSAGE'
  },
  {
    value: OutboxMessageType.PrivatePictureMessage,
    label: 'PRIVATE_PICTURE_MESSAGE'
  },
  {
    value: OutboxMessageType.MultiImage,
    label: 'MULTI_IMAGE'
  },
  {
    value: OutboxMessageType.Share,
    label: 'SHARE'
  },
  {
    value: OutboxMessageType.InstagramStory,
    label: 'INSTAGRAM_STORY'
  },
  {
    value: OutboxMessageType.InstagramReel,
    label: 'INSTAGRAM_REEL'
  }
]);

export const OUTBOX_POST_TYPES = [
  {
    value: OutboxMessageType.StatusUpdate,
    label: 'STATUS_UPDATE'
  },
  {
    value: OutboxMessageType.Picture,
    label: 'PICTURE'
  },
  {
    value: OutboxMessageType.Album,
    label: 'ALBUM'
  },
  {
    value: OutboxMessageType.MultiImage,
    label: 'MULTI_IMAGE'
  },
  {
    value: OutboxMessageType.Video,
    label: 'VIDEO'
  },
  {
    value: OutboxMessageType.Share,
    label: 'SHARE'
  },
  {
    value: OutboxMessageType.InstagramStory,
    label: 'INSTAGRAM_STORY'
  },
  {
    value: OutboxMessageType.InstagramReel,
    label: 'INSTAGRAM_REEL'
  }
];

export const INBOX_REPLY_PRIVATE_TYPES = [
  {
    value: OutboxMessageType.PrivateMessage,
    label: 'PRIVATE_MESSAGE'
  },
  {
    value: OutboxMessageType.PrivatePictureMessage,
    label: 'PRIVATE_PICTURE_MESSAGE'
  }
];
export const INBOX_REPLY_PUBLIC_TYPES = [
  {
    value: OutboxMessageType.Reply,
    label: 'REPLY'
  },
  {
    value: OutboxMessageType.ReplyPicture,
    label: 'REPLY_WITH_PICTURE'
  },
  {
    value: OutboxMessageType.ReplyAlbum,
    label: 'REPLY_WITH_ALBUM'
  },
  {
    value: OutboxMessageType.ReplyVideo,
    label: 'REPLY_WITH_VIDEO'
  }
];

export const INBOX_REPLY_ALL_TYPES = [
  ...INBOX_REPLY_PRIVATE_TYPES,
  ...INBOX_REPLY_PUBLIC_TYPES
];

export const MONITORING_SOURCES = Object.freeze([
  {
    key: 'twitter',
    icon: 'ssi ssi-x-logo',
    label: 'X',
    description:
      'X_IS_AN_INFORMATION_NETWORK_MADE_UP_OF_140CHARACTER_MESSAGES_CALLED_POSTS',
    selectable: true,
    category: 'Social'
  },
  {
    key: 'blog',
    icon: 'fa fa-rss',
    label: 'Blogs',
    description: 'BLOG_DATA_FROM_A_WIDE_VARIETY_OF_SITES_LARGE_AND_SMALL',
    selectable: false,
    category: 'Online'
  },
  {
    key: 'youtube',
    icon: 'fa fa-youtube',
    label: 'YouTube',
    description: 'VIDEO_DATA_FROM_YOUTUBE',
    selectable: false,
    category: 'Social'
  },
  {
    key: 'news',
    icon: 'fa fa-newspaper-o',
    label: 'News',
    description: 'ALL_NEWS_SITES',
    selectable: false,
    category: 'Online'
  },
  {
    key: 'messageboard',
    icon: 'fa fa-comments',
    label: 'Message boards',
    description: 'ALL_FORUMS_AND_MESSAGE_BOARDS',
    selectable: false,
    category: 'Online'
  },
  {
    key: 'Google',
    icon: 'fa fa-google',
    label: 'Google web alerts',
    description: 'GOOGLE_WEB_ALERTS',
    selectable: false,
    category: 'Online'
  },
  {
    key: 'moreover_news',
    icon: 'fa fa-newspaper-o',
    label: 'News',
    description: 'MONITORING_SOURCE_MOREOVER_NEWS_DESCRIPTION',
    selectable: false,
    category: 'Online'
  },
  {
    key: 'moreover_blog',
    icon: 'fa fa-rss',
    label: 'Blog',
    description: 'MONITORING_SOURCE_MOREOVER_BLOG_DESCRIPTION',
    selectable: false,
    category: 'Online'
  },
  {
    key: 'moreover_print',
    icon: 'fa fa-print',
    label: 'Print',
    description: 'MONITORING_SOURCE_MOREOVER_PRINT_DESCRIPTION',
    selectable: false,
    category: 'Print'
  },
  {
    key: 'moreover_broadcast',
    icon: 'fa fa-bullhorn',
    label: 'Broadcast',
    description: 'MONITORING_SOURCE_MOREOVER_BROADCAST_DESCRIPTION',
    selectable: false,
    category: 'Online'
  },
  {
    key: 'moreover_microblog',
    icon: 'fa fa-sticky-note-o',
    label: 'Microblog',
    description: 'MONITORING_SOURCE_MOREOVER_MICROBLOG_DESCRIPTION',
    selectable: false,
    category: 'Online'
  },
  {
    key: 'moreover_social_video',
    icon: 'fa fa-video-camera',
    label: 'Social Video',
    description: 'MONITORING_SOURCE_MOREOVER_SOCIAL_VIDEO_DESCRIPTION',
    selectable: false,
    category: 'Social'
  },
  {
    key: 'moreover_social_photo',
    icon: 'fa fa-picture-o',
    label: 'Social Photo',
    description: 'MONITORING_SOURCE_MOREOVER_SOCIAL_PHOTO_DESCRIPTION',
    selectable: false,
    category: 'Social'
  },
  {
    key: 'moreover_social_network',
    icon: 'fa fa-facebook',
    label: 'Social Network',
    description: 'MONITORING_SOURCE_MOREOVER_SOCIAL_NETWORK_DESCRIPTION',
    selectable: false,
    category: 'Social'
  },
  {
    key: 'moreover_podcast',
    icon: 'fa fa-microphone',
    label: 'Podcast',
    description: 'MONITORING_SOURCE_MOREOVER_PODCAST_DESCRIPTION',
    selectable: false,
    category: 'Online'
  },
  {
    key: 'moreover_review',
    icon: 'fa fa-pencil',
    label: 'Review',
    description: 'MONITORING_SOURCE_MOREOVER_REVIEW_DESCRIPTION',
    selectable: false,
    category: 'Online'
  },
  {
    key: 'moreover_wiki',
    icon: 'fa fa-wikipedia-w',
    label: 'Wiki',
    description: 'MONITORING_SOURCE_MOREOVER_WIKI_DESCRIPTION',
    selectable: false,
    category: 'Online'
  },
  {
    key: 'moreover_comment',
    icon: 'fa fa-comment',
    label: 'Comment',
    description: 'MONITORING_SOURCE_MOREOVER_COMMENT_DESCRIPTION',
    selectable: false,
    category: 'Online'
  },
  {
    key: 'moreover_qa',
    icon: 'fa fa-question',
    label: 'Q&A',
    description: 'MONITORING_SOURCE_MOREOVER_QA_DESCRIPTION',
    selectable: false,
    category: 'Online'
  },
  {
    key: 'moreover_forum',
    icon: 'fa fa-reddit',
    label: 'Forum',
    description: 'MONITORING_SOURCE_MOREOVER_FORUM_DESCRIPTION',
    selectable: false,
    category: 'Online'
  },
  {
    key: 'moreover_classified',
    icon: 'fa fa-lock',
    label: 'Classified',
    description: 'MONITORING_SOURCE_MOREOVER_CLASSIFIED_DESCRIPTION',
    selectable: false,
    category: 'Online'
  }
]);

export const SENTIMENT_CONFIG = deepFreeze({
  positive: {
    color: lessVariables['sentiment-positive-color'],
    translateKey: 'POSITIVE',
    colorClass: 'positive-bg',
    iconClass: 'ssi ssi-fw ssi-v-positive',
    colorClassNew: 'positive-new',
    iconClassNew: 'ssi ssi-positive-new',
    inbox: {
      value: 0.75
    },
    engagementReport: {
      key: 'positive_sentiment'
    }
  },
  semiPositive: {
    color: lessVariables['sentiment-semi-positive-color'],
    translateKey: 'SEMI_POSITIVE',
    colorClass: 'semi-positive-bg',
    iconClass: 'ssi ssi-fw ssi-s-positive',
    colorClassNew: 'semi-positive-new',
    iconClassNew: 'ssi ssi-semi-positive-new',
    inbox: {
      value: 0.25
    },
    engagementReport: {
      key: 'semi_positive_sentiment'
    }
  },
  neutral: {
    color: lessVariables['sentiment-neutral-color'],
    translateKey: 'NEUTRAL',
    colorClass: 'neutral-bg',
    iconClass: 'ssi ssi-fw ssi-new-neutral',
    colorClassNew: 'neutral-new',
    iconClassNew: 'ssi ssi-neutral-new',
    inbox: {
      value: 0
    },
    engagementReport: {
      key: 'neutral_sentiment'
    }
  },
  semiNegative: {
    color: lessVariables['sentiment-semi-negative-color'],
    translateKey: 'SEMI_NEGATIVE',
    colorClass: 'semi-negative-bg',
    iconClass: 'ssi ssi-fw ssi-s-negative',
    colorClassNew: 'semi-negative-new',
    iconClassNew: 'ssi ssi-semi-negative-new',
    inbox: {
      value: -0.25
    },
    engagementReport: {
      key: 'semi_negative_sentiment'
    }
  },
  negative: {
    color: lessVariables['sentiment-negative-color'],
    translateKey: 'NEGATIVE',
    colorClass: 'negative-bg',
    iconClass: 'ssi ssi-fw ssi-v-negative',
    colorClassNew: 'negative-new',
    iconClassNew: 'ssi ssi-negative-new',
    inbox: {
      value: -0.75
    },
    engagementReport: {
      key: 'negative_sentiment'
    }
  }
});

export const PUBLISH_ALLOWED_CHANNELS = [
  'Facebook',
  'Twitter',
  'LinkedIn',
  'Instagram Business',
  'YouTube',
  'Nextdoor Agency',
  'Nextdoor Agency US'
];

export const LANGUAGES = Object.freeze({
  fr: 'French',
  en: 'English',
  ar: 'Arabic',
  ja: 'Japanese',
  es: 'Spanish',
  el: 'Greek',
  de: 'German',
  it: 'Italian',
  id: 'Indonesian',
  pt: 'Portuguese',
  ko: 'Korean',
  tr: 'Turkish',
  ru: 'Russian',
  nl: 'Dutch',
  fil: 'Filipino',
  msa: 'Malay',
  zh: 'Chinese',
  'zh-tw': 'Traditional Chinese',
  'zh-cn': 'Simplified Chinese',
  hi: 'Hindi',
  no: 'Norwegian',
  sv: 'Swedish',
  fi: 'Finnish',
  da: 'Danish',
  pl: 'Polish',
  hu: 'Hungarian',
  fa: 'Persian',
  uk: 'Ukrainian',
  cs: 'Czech',
  ro: 'Romanian',
  vi: 'Vietnamese',
  bn: 'Bengali',
  he: 'Hebrew',
  ur: 'Urdu',
  th: 'Thai',
  'en-gb': 'English UK',
  tl: 'Tagalog',
  cy: 'Welsh'
});

// source: https://en.wikipedia.org/wiki/ISO_3166-1
export const COUNTRIES = Object.freeze({
  AF: 'Afghanistan',
  AX: 'Åland Islands',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua and Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia (Plurinational State of)',
  BQ: 'Bonaire, Sint Eustatius and Saba',
  BA: 'Bosnia and Herzegovina',
  BW: 'Botswana',
  BV: 'Bouvet Island',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory',
  BN: 'Brunei Darussalam',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  CV: 'Cabo Verde',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  KY: 'Cayman Islands',
  CF: 'Central African Republic',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Christmas Island',
  CC: 'Cocos (Keeling) Islands',
  CO: 'Colombia',
  KM: 'Comoros',
  CG: 'Congo',
  CD: 'Congo (Democratic Republic of the)',
  CK: 'Cook Islands',
  CR: 'Costa Rica',
  CI: "Côte d'Ivoire",
  HR: 'Croatia',
  CU: 'Cuba',
  CW: 'Curaçao',
  CY: 'Cyprus',
  CZ: 'Czechia',
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  ET: 'Ethiopia',
  FK: 'Falkland Islands (Malvinas)',
  FO: 'Faroe Islands',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  GF: 'French Guiana',
  PF: 'French Polynesia',
  TF: 'French Southern Territories',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard Island and McDonald Islands',
  VA: 'Holy See',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran (Islamic Republic of)',
  IQ: 'Iraq',
  IE: 'Ireland',
  IM: 'Isle of Man',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KP: "Korea (Democratic People's Republic of)",
  KR: 'Korea (Republic of)',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: "Lao People's Democratic Republic",
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macao',
  MK: 'Macedonia (the former Yugoslav Republic of)',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesia (Federated States of)',
  MD: 'Moldova (Republic of)',
  MC: 'Monaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  MP: 'Northern Mariana Islands',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestine, State of',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  RE: 'Réunion',
  RO: 'Romania',
  RU: 'Russian Federation',
  RW: 'Rwanda',
  BL: 'Saint Barthélemy',
  SH: 'Saint Helena, Ascension and Tristan da Cunha',
  KN: 'Saint Kitts and Nevis',
  LC: 'Saint Lucia',
  MF: 'Saint Martin (French part)',
  PM: 'Saint Pierre and Miquelon',
  VC: 'Saint Vincent and the Grenadines',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome and Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SX: 'Sint Maarten (Dutch part)',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  GS: 'South Georgia and the South Sandwich Islands',
  SS: 'South Sudan',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan',
  SR: 'Suriname',
  SJ: 'Svalbard and Jan Mayen',
  SZ: 'Swaziland',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syrian Arab Republic',
  TW: 'Taiwan, Province of China[a]',
  TJ: 'Tajikistan',
  TZ: 'Tanzania, United Republic of',
  TH: 'Thailand',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad and Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks and Caicos Islands',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  GB: 'United Kingdom of Great Britain and Northern Ireland',
  US: 'United States of America',
  UM: 'United States Minor Outlying Islands',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela (Bolivarian Republic of)',
  VN: 'Viet Nam',
  VG: 'Virgin Islands (British)',
  VI: 'Virgin Islands (U.S.)',
  WF: 'Wallis and Futuna',
  EH: 'Western Sahara',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe'
});
