import './menu.component.scss';

import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  OnInit,
  HostListener,
  ChangeDetectorRef,
  TemplateRef,
  ContentChild,
  ViewChild,
  AfterContentInit,
  QueryList,
  ContentChildren
} from '@angular/core';
import { animate, style, trigger, transition } from '@angular/animations';

import { mapToIterable } from '../../utils';
import { TemplateSelectorDirective } from '../../directives/template-selector/template-selector.directive';

export class MenuTemplates {
  static readonly menuHead = 'menuHead';
  static readonly menuBody = 'menuBody';
}

export class Alignments {
  static readonly bottom = 'bottom';
  static readonly top = 'top';
  static readonly right = 'right';
  static readonly left = 'left';
}

@Component({
  selector: 'ssi-menu',
  templateUrl: './menu.component.html',
  animations: [
    trigger('fadeIn', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('120ms ease-out', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class MenuComponent implements OnInit, AfterContentInit {
  @Input() templateRefs: any = {};
  @Input() headAlignment = '';
  @Input() bodyAlignment = '';
  @Input() headless = false;

  @Output() toggled = new EventEmitter<boolean>();

  @ContentChildren(TemplateSelectorDirective)
  templateList: QueryList<TemplateSelectorDirective>;

  @ViewChild('headRef') headRef: ElementRef;
  @ViewChild('bodyRef') bodyRef: ElementRef;

  context = {};
  visible = false;
  bodyStyle: any = {};

  MenuTemplates = MenuTemplates;
  Alignments = Alignments;

  constructor(
    protected elementRef: ElementRef,
    protected changeDetectorRef: ChangeDetectorRef
  ) {}

  // @HostListener('document:click', ['$event'])
  // onClick(event: MouseEvent) {
  //   if (!this.elementRef.nativeElement.contains(event.target)) {
  //     this.close();
  //   }
  // }

  ngOnInit() {}

  ngAfterContentInit() {
    this._collectTemplateRefs();
  }

  open(event: MouseEvent, context = {}): void {
    event.stopPropagation();

    if (this.visible) {
      return;
    }

    const headElem = this.headRef.nativeElement;
    const headElemStyle = getComputedStyle(headElem);

    if (this.headAlignment.toLowerCase().indexOf(Alignments.top) > -1) {
      this.bodyAlignment.toLowerCase().indexOf(Alignments.top) > -1
        ? (this.bodyStyle['top'] = '0px')
        : (this.bodyStyle['bottom'] = headElemStyle.height);
    } else {
      if (this.bodyAlignment.toLowerCase().indexOf(Alignments.bottom) > -1) {
        this.bodyStyle['bottom'] = '0px';
      }
    }

    if (this.headAlignment.toLowerCase().indexOf(Alignments.right) > -1) {
      this.bodyAlignment.toLowerCase().indexOf(Alignments.left) > -1
        ? (this.bodyStyle['left'] = headElemStyle.width)
        : (this.bodyStyle['right'] = '0px');
    } else {
      if (this.bodyAlignment.toLowerCase().indexOf(Alignments.right) > -1) {
        this.bodyStyle['right'] = headElemStyle.width;
      }
    }

    this.context = { $implicit: context };

    this.visible = true;
    this.toggled.emit(true);
  }

  close(): void {
    if (!this.visible) {
      return;
    }

    this.visible = false;
    this.toggled.emit(false);
  }

  toggle(event: MouseEvent): void {
    this.visible ? this.close() : this.open(event);
  }

  private _collectTemplateRefs(): void {
    this.templateList.toArray().forEach((t: TemplateSelectorDirective) => {
      if (!MenuTemplates[t.selector]) {
        console.warn(
          `Unknown template type: ${
            t.selector
          }. Possible value/s: ${mapToIterable(MenuTemplates).join(', ')}.`
        );
        return;
      }

      this.templateRefs[t.selector] = t.templateRef;
    });
  }
}
