<div class="confirmation">
  <div class="header">
    <i [class]="icon"></i>
    <h2 *ngIf="title">{{title}}</h2>
    <p *ngIf="info"
       class="info">{{info}}</p>
    <div *ngIf="infoHTML"
       class="info"
       [innerHTML]="infoHTML"></div>
  </div>
  <div class="footer">
    <div *ngIf="!negativeConfirmation">
      <orlo-button *ngIf="cancelButton"
                   [state]="'secondary'"
                   [text]="cancelButton"
                   (click)="activeModal.close(false)"
                   [bgColor]="'#F88C68'"></orlo-button>
      <orlo-button *ngIf="confirmButton"
                   [state]="'primary'"
                   [text]="confirmButton"
                   (click)="activeModal.close(true)"></orlo-button>
    </div>
    <div *ngIf="negativeConfirmation">
      <orlo-button [state]="'primary'"
                   [text]="confirmButton"
                   (click)="activeModal.close(true)"></orlo-button>
      <button class="orlo-button text"
              (click)="activeModal.close(false)">{{cancelButton}}</button>
    </div>

    <div class="addl-footer-content-container"
         *ngIf="footerTemplate">
      <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
    </div>
  </div>
</div>